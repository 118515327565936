import "./CreateDepartment.scss";
import FileUploadIcon from "@mui/icons-material/FileUploadOutlined";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { useState } from "react";
import Button from "../../../Components/Button/Button";
import { MenuItem, Select } from "@mui/material";
import newRequest from "../../../Utils/newRequest";
import { message } from "antd";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import Loader from "../../../Components/Loader/Loader";
const CreateDepartment = () => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState({
    title: "",
    training_type_id: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await newRequest.post(
        "/type_levels",
        {
          ...department,
          icon: image,
        },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      setDepartment({
        title: "",
        training_type_id: "",
      });
      setImage(null);
      message.success("تم اضافة القسم بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="create">
      <div className="container">
        <h4 className="title">اضافه قسم رئيسي / مستوي</h4>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="form-control">
              <span className="image-container">
                {image && (
                  <>
                    <img
                      src={
                        image instanceof File
                          ? URL.createObjectURL(image)
                          : image
                      }
                      alt=""
                    />
                    <CloseIcon
                      className="icon"
                      onClick={() => setImage(null)}
                    />
                  </>
                )}
                {image?.name}
              </span>
              <label htmlFor="file" className="file-label">
                <FileUploadIcon className="icon" />
                تحميل الصورة
              </label>
              <input
                type="file"
                id="file"
                // required
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>

            <div className="control-group">
              <div className="form-control">
                <label htmlFor="name">اسم القسم</label>
                <input
                  placeholder="أدخل اسم القسم"
                  type="text"
                  id="name"
                  required
                  name="title"
                  onChange={(e) =>
                    setDepartment((prev) => {
                      return { ...prev, [e.target.name]: e.target.value };
                    })
                  }
                />
              </div>

              <div className="form-control">
                <label htmlFor="name">تحديد القسم / مستوي</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  // multiple={true}
                  className="select"
                  value={department.training_type_id}
                  name="training_type_id"
                  onChange={(e) =>
                    setDepartment((prev) => {
                      return { ...prev, [e.target.name]: e.target.value };
                    })
                  }
                >
                  <MenuItem value={1}>general training</MenuItem>
                  <MenuItem value={2}>special training</MenuItem>
                </Select>
              </div>
            </div>
            <div className="form-actions">
              <Button text="اضافة" size="large" variant="fill" />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateDepartment;

// const handleSubmit = async (e) => {
//   e.preventDefault();
//   setLoading(true);

//   const formData = new FormData();
//   formData.append("icon", image);

//   const requestData = {
//     ...department,
//     formData,
//   };

//   const requestHeaders = {
//     "Content-Type": "multipart/form-data",
//   };

//   try {
//     await newRequest.post("/type_levels", requestData, {
//       headers: requestHeaders,
//     });

//     setDepartment({
//       title: "",
//       training_type_id: "",
//     });

//     message.success("تم اضافة القسم بنجاح");
//   } catch (error) {
//     showErrorMessage(error);
//   } finally {
//     setLoading(false);
//   }
// };
