import "./StudentLevels.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import newRequest from "../../../../Utils/newRequest";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";

const StudentLevels = (props) => {
  const user = props?.user;
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    getLevels();
  }, []);

  const getLevels = async () => {
    try {
      const res = await newRequest.get(`/levels/${user?.id}`);
      setLevels(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        مستويات المميز بيها الطالب
      </AccordionSummary>
      <div className="levels container">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>المستوي</th>
              {/* <th>الاقسام الفرعية</th>
              <th>إجمالي الاسئلة</th>
              <th>الاجابات الخاطئة</th>
              <th>الاجابات الصحيحة</th> */}
            </tr>
          </thead>
          <tbody>
            {levels?.map((row, index) => (
              <tr key={index + 1}>
                <td>{index + 1}</td>
                <td>{row?.title}</td>
                {/* <td>مسائل منفصلة</td>
                <td>20</td>
                <td>5</td>
                <td>15</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Accordion>
  );
};

export default StudentLevels;
