import "./CreateEditPackage.scss";
import { useState } from "react";
import Button from "../../../Components/Button/Button";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../Components/Loader/Loader";
import newRequest from "../../../Utils/newRequest";
import { message } from "antd";
import { showErrorMessage } from "../../../Utils/showErrorMessage";

const CreateEditPackage = () => {
  const location = useLocation();
  const editPackage = location.state;
  const isTrainer = location.pathname.includes("/trainer");
  const isCreate = location.pathname.includes("/create");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [newPackage, setNewPackage] = useState({
    title: editPackage?.title || "",
    price: editPackage?.price || "",
    duration: editPackage?.duration || "",
    type: isTrainer ? "trainer" : "student",
    duration_type: editPackage?.duration_type || "day",
  });

  const handleChange = (e) => {
    setNewPackage((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (isCreate) {
      try {
        await newRequest.post("/packages", {
          ...newPackage,
        });
        setNewPackage({
          title: "",
          price: "",
          duration: "",
          type: isTrainer ? "trainer" : "student",
        });
        setLoading(false);
        message.success("تم اضافة الباقة بنجاح");
      } catch (error) {
        setLoading(false);
        showErrorMessage(error);
      }
    } else {
      try {
        await newRequest.put(`/packages/${editPackage?.id}`, {
          ...newPackage,
        });
        setNewPackage({
          title: newPackage?.title || "",
          price: newPackage?.price || "",
          duration: newPackage?.duration || "",
        });
        message.success("تم تعديل الباقة بنجاح");
        navigate(`/packages/${isTrainer ? "trainer" : "student"}`);
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="create-edit-package">
      <div className="container">
        <h4 className="title">
          {isTrainer
            ? `${isCreate ? "اضافة" : "تعديل"} باقة المدرب`
            : `${isCreate ? "اضافة" : "تعديل"} باقة طالب`}
        </h4>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">اسم الباقة</label>
                <TextField
                  placeholder="ادخل الاسم"
                  fullWidth
                  className="form-input"
                  required
                  name="title"
                  value={newPackage?.title}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">سعر الباقة</label>
                <TextField
                  placeholder="السعر"
                  fullWidth
                  className="form-input"
                  name="price"
                  inputProps={{ pattern: "[0-9]*" }}
                  value={newPackage?.price}
                  required
                  onChange={handleChange}
                />
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="aria-label">نوع المده</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  className="select"
                  name="duration_type"
                  value={newPackage?.duration_type}
                  onChange={handleChange}
                >
                  <MenuItem value="day">يوم</MenuItem>
                  <MenuItem value="month">شهر</MenuItem>
                  <MenuItem value="year">سنة</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">المده</label>
                <TextField
                  placeholder="ادخل المده"
                  inputProps={{ pattern: "[0-9]*" }}
                  fullWidth
                  className="form-input"
                  required
                  name="duration"
                  value={newPackage?.duration}
                  onChange={handleChange}
                />
              </FormControl>
            </div>

            <div className="form-actions">
              <Button
                text={isCreate ? "اضافة" : "تعديل"}
                size="large"
                variant="fill"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateEditPackage;
