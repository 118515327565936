import "./SliderImages.scss";
import { Link } from "react-router-dom";
import ArrowIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
const SliderImages = () => {
  return (
    <div className="terms-and-conditions">
      <div className="container">
        <h4 className="title">صور السلايدر</h4>
        <div className="actions-container">
          <Link className="action-link" to={"/slider-images/students"}>
            صور السلايدر ( الطالب )
            <ArrowIcon className="icon" />
          </Link>
          <Link className="action-link" to={"/slider-images/trainers"}>
            صور السلايدر ( المدرب )
            <ArrowIcon className="icon" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SliderImages;
