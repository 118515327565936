import "./ViewMessage.scss";
import { useLocation } from "react-router-dom";
import { TextField } from "@mui/material";

const ViewMessage = () => {
  const location = useLocation();
  const message = location.state;
  const isTrainer = location?.pathname?.includes("/trainer");

  return (
    <div className="create">
      <div className="container">
        <h4 className="title">رسالة الدعم للطلاب</h4>
        <form action="">
          <div className="control-group">
            <div className="form-control">
              <label className="form-label" htmlFor="name">
                رقم الدعم
              </label>
              <TextField
                required
                value={message?.id}
                fullWidth
                id="fullWidth"
                className="form-input"
                pattern="[0-9]*"
              />
            </div>

            <div className="form-control">
              <label className="form-label" htmlFor="name">
                اسم الطالب
              </label>
              <TextField
                required
                fullWidth
                value={message?.user?.name}
                id="fullWidth"
                className="form-input"
              />
            </div>
          </div>

          <div className="control-group">
            <div className="form-control">
              <label className="form-label" htmlFor="name">
                رقم الموبايل
              </label>
              <TextField
                required
                value={message?.user?.phone}
                fullWidth
                id="fullWidth"
                className="form-input"
                pattern="[0-9]*"
              />
            </div>

            <div className="form-control">
              <label className="form-label" htmlFor="name">
                حاله الدعم
              </label>
              <TextField
                required
                value={message?.support_status}
                fullWidth
                id="fullWidth"
                className="form-input"
              />
            </div>
          </div>

          <div className="control-group">
            <div className="form-control">
              <label htmlFor="name">السؤال</label>
              <textarea
                value={message?.support_question?.question}
                name=""
                id=""
              ></textarea>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewMessage;
