import "./CreateEditSubDepartment.scss";
import { useEffect, useState } from "react";
import Button from "../../../Components/Button/Button";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../../Components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";

const CreateEditSubDepartment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const subDepartment = location.state;
  const isCreate = location.pathname.includes("/create");
  const [mainCategories, setMainCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newSubDepartment, setNewSubDepartment] = useState({
    title: "",
    type_level_id: "",
    training_type_id: "1",
  });
  const [updatedSubDepartment, setUpdatedSubDepartment] = useState({
    title: subDepartment?.title,
    type_level_id: subDepartment?.type_level_id,
    training_type_id: subDepartment?.type_level?.training_type_id,
  });

  useEffect(() => {
    const getMainCategories = async () => {
      try {
        const res = await newRequest.get(
          `/type_levels?training_type_id=${
            isCreate
              ? newSubDepartment.training_type_id
              : updatedSubDepartment.training_type_id
          }`
        );
        setMainCategories(res?.data?.data);
      } catch (error) {
        showErrorMessage(error);
      }
    };
    getMainCategories();
  }, [
    newSubDepartment.training_type_id,
    updatedSubDepartment.training_type_id,
  ]);

  const handleChange = (e) => {
    if (isCreate) {
      setNewSubDepartment((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else {
      setUpdatedSubDepartment((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (isCreate) {
      try {
        await newRequest.post("/level_categories", {
          ...newSubDepartment,
        });
        message.success("تم اضافة القسم  الفرعي بنجاح");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setNewSubDepartment({
          title: "",
          type_level_id: "",
        });
        setLoading(false);
      }
    } else {
      try {
        await newRequest.put(`/level_categories/${subDepartment.id}`, {
          ...updatedSubDepartment,
        });
        message.success("تم تعديل القسم الفرعي بنجاح");
        navigate("/sub-departments");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="create">
      <div className="container">
        <h4 className="title">
          {isCreate ? "اضافه قسم فرعي" : "تعديل قسم فرعي"}
        </h4>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="form-control"></div>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">اسم القسم</label>
                <TextField
                  required
                  fullWidth
                  id="fullWidth"
                  className="form-input"
                  name="title"
                  value={newSubDepartment?.title || updatedSubDepartment?.title}
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="name">تحديد القسم </label>

                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  name="training_type_id"
                  onChange={handleChange}
                  value={
                    isCreate
                      ? newSubDepartment?.training_type_id
                      : updatedSubDepartment?.training_type_id
                  }
                  className="select"
                >
                  <MenuItem value={1}>عام</MenuItem>
                  <MenuItem value={2}>تفصيلي</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="name">
                  تحديد القسم الرئيسي / المستوي المضاف الية
                </label>
                <Select
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  className="select"
                  name="type_level_id"
                  value={
                    newSubDepartment?.type_level_id ||
                    updatedSubDepartment?.type_level_id
                  }
                  onChange={handleChange}
                >
                  {mainCategories?.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="form-actions">
              <Button
                text={isCreate ? "اضافة" : "تعديل"}
                size="large"
                variant="fill"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateEditSubDepartment;
