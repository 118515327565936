import "./Chats.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ViewIcon from "./../../../../Assets/details-icon.svg";
import moment from "moment";
import { Switch } from "@mui/material";
import { useEffect, useState } from "react";
import ChatModal from "../../../../Components/Modals/ChatModal/ChatModal";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import newRequest from "../../../../Utils/newRequest";
import Loader from "../../../../Components/Loader/Loader";

const Chats = (props) => {
  const id = props?.id;
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trainerChat, setTrainerChat] = useState([]);
  const [singleChat, setSingleChat] = useState({});
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  useEffect(() => {
    if (accordionExpanded) {
      getTrainerChat();
    }
  }, [accordionExpanded]);

  const getTrainerChat = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(`/chat?trainer_id=${id}`);
      setTrainerChat(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccordionChange = (event, isExpanded) => {
    setAccordionExpanded(isExpanded);
  };

  return (
    <>
      <Accordion onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          قائمة الدردشات مع الطلاب
        </AccordionSummary>
        {loading ? (
          <Loader />
        ) : (
          <div className="chats container">
            {trainerChat?.map((item, index) => (
              <div className="chat" key={index}>
                <div className="chat-header">
                  <div className="text-container">
                    <img src={item?.student?.logo} alt="" />
                    <div className="wrapper">
                      <span>{item?.student?.name}</span>
                      <span>
                        {moment(item?.created_at).format(
                          "YYYY/MM/DD - hh:mm a"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="actions-container">
                    <div
                      className="view-btn"
                      onClick={() => {
                        setOpen(true);
                        setSingleChat(item);
                      }}
                    >
                      <img src={ViewIcon} alt="" />
                    </div>
                    <div className="switch-btn">
                      <Switch {...label} />
                    </div>
                  </div>
                </div>
                <div className="chat-body">{item?.last_message}</div>
              </div>
            ))}
          </div>
        )}
      </Accordion>
      <ChatModal open={open} onClose={() => setOpen(false)} data={singleChat} />
    </>
  );
};

export default Chats;
