import "./AnswerMessage.scss";
import Button from "../../../Components/Button/Button";
import { TextField } from "@mui/material";
import newRequest from "../../../Utils/newRequest";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { message } from "antd";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import Loader from "../../../Components/Loader/Loader";

const AnswerMessage = () => {
  const location = useLocation();
  const note = location.state;
  const [loading, setLoading] = useState(false);
  const [newNote, setNewNote] = useState(note);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await newRequest.put(`/support_chats/${note.id}`, {
        note: newNote.note,
      });
      setNewNote(res?.data?.data);
      message.success("تم اضافة الملحوظة بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="create">
      <div className="container">
        <h4 className="title">اضافة ملاحظة</h4>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              <div className="form-control">
                <label className="form-label" htmlFor="name">
                  الملاحظة
                </label>
                <TextField
                  fullWidth
                  name="note"
                  id="fullWidth"
                  className="form-input"
                  multiline
                  value={newNote?.note}
                  onChange={(e) =>
                    setNewNote((prev) => {
                      return { ...prev, [e.target.name]: e.target.value };
                    })
                  }
                />
              </div>
            </div>

            <div className="form-actions">
              <Button text="اضافة" size="large" variant="fill" />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AnswerMessage;
