import "./Questions.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FilterIcon from "./../../Assets/filter-icon.svg";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "./../../Assets/edit-icon.svg";
import DeleteIcon from "./../../Assets/delete-icon.svg";
import CloseIcon from "./../../Assets/close-icon.svg";
import AttentionIcon from "./../../Assets/attention-icon.svg";
import AddIcon from "@mui/icons-material/AddOutlined";
import Button from "../../Components/Button/Button";
import newRequest from "../../Utils/newRequest";
import { QuestionsColumns } from "../../SourceData";
import { DataGrid } from "@mui/x-data-grid";
import { Modal, useMediaQuery } from "@mui/material";
import { showErrorMessage } from "../../Utils/showErrorMessage";
import { message } from "antd";
import * as XLSX from "xlsx";
import Loader from "../../Components/Loader/Loader";

const INITIAL_PAGINATION = { page: 0, pageSize: 10 };
const INITIAL_FILTERS = {
  training_type: "",
  type_level: "",
  level_category: "",
};

const Questions = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [openMessage, setOpenMessage] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [rowId, setRowId] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [fetch, setFetch] = useState(false);
  const [trainingType, setTrainingType] = useState(false);
  const [typeLevel, setTypeLevel] = useState(false);
  const [levelCategory, setLevelCategory] = useState(false);
  const [typeLevels, setTypeLevels] = useState([]);
  const [levelCategories, setLevelCategories] = useState([]);
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: false,
    quickFilterValues: [],
  });
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({
    training_type: "", // نوع السؤال
    type_level: "", // القسم او المستوي
    level_category: "", // الفرعي
  });

  useEffect(() => {
    const getTypeLevels = async () => {
      const res = await newRequest.get(
        `type_levels?training_type_id= ${filters?.training_type || 1}`
      );
      setTypeLevels(res?.data?.data);
    };
    getTypeLevels();
  }, [filters.training_type]);

  useEffect(() => {
    const getLevelsCategories = async () => {
      const res = await newRequest.get(
        `level_categories?type_level_id=${filters?.type_level}`
      );
      setLevelCategories(res?.data?.data);
    };

    getLevelsCategories();
  }, [filters.type_level]);

  useEffect(() => {
    getQuestions();
  }, [paginationModel.page, paginationModel.pageSize, fetch]);

  const getQuestions = async () => {
    setLoading(true);
    try {
      let queryParams = `page=${paginationModel.page + 1}&rows=${
        paginationModel.pageSize
      }`;

      if (trainingType && filters.training_type) {
        queryParams += `&training_type_id=${filters.training_type}`;
      }
      if (typeLevel && filters.type_level) {
        queryParams += `&type_level_id=${filters.type_level}`;
      }
      if (levelCategory && filters.level_category) {
        queryParams += `&level_category_id=${filters.level_category}`;
      }

      const res = await newRequest.get(`/questions?${queryParams}`);
      setQuestions(res?.data?.data?.data);
      setTotalRows(res?.data?.data?.meta?.total);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setFetch(false);
      setLoading(false);
    }
  };

  // Handlers Functions

  const handleMultiDelete = async () => {
    if (selectedRowsIds.length === 0) {
      return message.warning("من فضلك قم بتحديد الصفوف التي تريد حذفها أولاً");
    }
    setLoading(true);
    try {
      await newRequest.post("/questions_multi_delete", {
        ids: selectedRowsIds,
      });

      await getQuestions();
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
      setSelectedRowsIds([]);
    }
  };

  const handleDelete = async () => {
    setOpenMessage(false);
    setLoading(true);
    try {
      await newRequest.delete(`/questions/${rowId}`);
      await getQuestions();
      setRowId("");
      message.success("تم حذف السؤال بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleActive = async (id) => {
    setLoading(true);
    try {
      await newRequest.put(`/questions/${id}/activateToggle`);
      await getQuestions();
      message.success("تم التعديل بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setOpenMessage(false);
    }
  };

  const handleExport = () => {
    const Questions = questions?.map((question, index) => ({
      التسلسل: index + 1,
      "الرقم الاول": question?.numbers[0],
      "الرقم الثاني": question?.numbers[1],
      "الرقم الثالث": question?.numbers[2],
      "الرقم الرابع": question?.numbers[3],
      "الرقم الخامس": question?.numbers[4],
      "الرقم السادس": question?.numbers[5],
      "الرقم السابع": question?.numbers[6],
      "الرقم الثامن": question?.numbers[7],
      "الرقم التاسع": question?.numbers[8],
      "الرقم العاشر": question?.numbers[9],
      "القسم الرئيسي/المستوي": question?.type_level?.title,
      "القسم الفرعي": question?.level_category?.title,
      "عدد الارقام": question?.numbers_count,
      الاجابة: question.answer,
      "الاختيار الاول": question?.choices[0],
      "الاختيار الثاني": question?.choices[1],
    }));

    const worksheet = XLSX.utils.json_to_sheet(Questions);
    const wscols = [
      { wch: 15, halign: "right" },
      { wch: 25, halign: "right" },
      { wch: 25, halign: "right" },
      { wch: 25, halign: "right" },
      { wch: 25, halign: "right" },
      { wch: 25, halign: "right" },
      { wch: 25, halign: "right" },
      { wch: 25, halign: "right" },
      { wch: 25, halign: "right" },
      { wch: 25, halign: "right" },
      { wch: 25, halign: "right" },
      { wch: 25, halign: "right" },
      { wch: 25, halign: "right" },
      { wch: 25, halign: "right" },
      { wch: 25, halign: "right" },
      { wch: 25, halign: "right" },
      { wch: 25, halign: "right" },
    ];

    worksheet["!cols"] = wscols;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Questions");
    XLSX.writeFile(workbook, "Questions.xlsx");
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    setFilterModel((prevModel) => ({
      ...prevModel,
      quickFilterValues: [event.target.value],
    }));
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 240,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="cell-action" style={{ display: "flex" }}>
            {/* Edit Action */}
            <div
              className="cell-btn"
              onClick={() => {
                navigate("/questions/edit", { state: params?.row });
              }}
            >
              <img src={EditIcon} alt="" />
            </div>
            {/* Delete Action */}
            <div
              className="cell-btn"
              onClick={() => {
                setOpenMessage(true);
                setRowId(params.row.id);
              }}
            >
              <img src={DeleteIcon} alt="" />
            </div>

            <div
              className="cell-btn "
              onClick={() => handleActive(params.row.id)}
            >
              <Button
                text={params.row.active === true ? "ايقاف" : "تفعيل"}
                size={"small"}
                variant={params.row.active === true ? "fill" : "outlined"}
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="question">
        <div className="container">
          <div className="spcial-header">
            <h4 className="title">عرض الاسئلة</h4>
            <div className="search">
              <div className="search-container">
                <SearchIcon className="icon" />
                <input
                  type="text"
                  placeholder="البحث"
                  onChange={handleSearch}
                />
              </div>

              <div
                className="filter-container"
                onClick={() => setOpenFilter(true)}
              >
                <img src={FilterIcon} alt="" />
              </div>
            </div>

            <div className="actions-container">
              <Button
                text={"اضف ملف اكسيل"}
                size={isMobile ? "full" : "large"}
                variant={"fill"}
                icon={<AddIcon />}
                path={"/questions/add-excel"}
                isMobile=""
              />

              <Button
                text={"اضافة سؤال"}
                size={isMobile ? "full" : "medium"}
                variant={"outlined"}
                icon={<AddIcon />}
                path={"/questions/create"}
              />

              <Button
                text={"حذف متعدد"}
                size={isMobile ? "full" : "small"}
                variant={"outlined"}
                onClick={handleMultiDelete}
              />
              <div onClick={handleExport} style={{ width: isMobile && "100%" }}>
                <Button
                  text={"تنزيل اكسيل"}
                  size={isMobile ? "full" : "small"}
                  variant={"outlined"}
                />
              </div>
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="data-grid">
              <DataGrid
                rows={questions.map((row, index) => {
                  return { ...row, index: index + 1 };
                })}
                columns={QuestionsColumns.concat(actionColumn)}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[10, 25, 50]}
                rowCount={totalRows}
                paginationMode="server"
                autoHeight
                disableVirtualization
                // For Multi Delete ↓
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelectedRowsIds(newRowSelectionModel);
                }}
                rowSelectionModel={selectedRowsIds}
                filterModel={filterModel}
                onFilterModelChange={(newModel) => setFilterModel(newModel)}
              />
            </div>
          )}
        </div>
      </div>

      {/* Message Modal */}
      <Modal
        open={openMessage}
        onClose={() => {
          setOpenMessage(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div
            className="header"
            onClick={() => {
              setOpenMessage(false);
              setRowId("");
            }}
          >
            <img src={CloseIcon} alt="" />
          </div>
          <div className="body">
            <img src={AttentionIcon} alt="" />
            <h2>انتبة</h2>
            <h5>هل انت متاكد من حذف هذا السؤال من</h5>
            <h6>الاسئلة</h6>
          </div>
          <div className="footer">
            <button className="action-btn" onClick={handleDelete}>
              حذف
            </button>
          </div>
        </div>
      </Modal>

      {/* Filter Modal */}
      <Modal
        open={openFilter}
        onClose={() => {
          setOpenFilter(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="filter-modal-questions"
      >
        <div className="container">
          <div className="header">
            <img src={FilterIcon} alt="" />
            <h3>البحث في الاسئلة</h3>
          </div>

          <div className="body">
            <div className="item">
              <input
                type="checkbox"
                checked={trainingType}
                onChange={() => setTrainingType(!trainingType)}
              />
              <select
                value={filters.training_type}
                onChange={(e) =>
                  handleFilterChange("training_type", e.target.value)
                }
                className={trainingType ? "checked" : ""}
              >
                <option value="" disabled>
                  نوع السؤال
                </option>
                <option value="1">عام</option>
                <option value="2">تفصيلي</option>
              </select>
            </div>

            <div className="item">
              <input
                type="checkbox"
                checked={typeLevel}
                onChange={() => setTypeLevel(!typeLevel)}
              />
              <select
                value={filters.type_level}
                onChange={(e) =>
                  handleFilterChange("type_level", e.target.value)
                }
                className={typeLevel ? "checked" : ""}
              >
                <option value="" disabled>
                  القسم الرئيسي/المستوي
                </option>
                {typeLevels.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>

            <div className="item">
              <input
                type="checkbox"
                checked={levelCategory}
                onChange={() => setLevelCategory(!levelCategory)}
              />
              <select
                value={filters.level_category}
                onChange={(e) =>
                  handleFilterChange("level_category", e.target.value)
                }
                className={levelCategory ? "checked" : ""}
              >
                <option value="" disabled>
                  القسم الفرعي
                </option>
                {levelCategories.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="footer">
            <Button
              text="بحث"
              variant="fill"
              size="medium"
              onClick={() => {
                setFetch(true);
                setOpenFilter(false);
              }}
            />
            <Button
              text="الغاء"
              variant="outlined"
              size="medium"
              onClick={() => {
                setOpenFilter(false);
                setPaginationModel(INITIAL_PAGINATION);
                setFilters(INITIAL_FILTERS);
                setTrainingType(false);
                setTypeLevel(false);
                setLevelCategory(false);
                setFetch(true);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Questions;
