import "./Certificates.scss";
import AddIcon from "@mui/icons-material/AddOutlined";
import Certificate from "./../../../../Assets/Certificate.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormControl, MenuItem, Select, useMediaQuery } from "@mui/material";
import Button from "../../../../Components/Button/Button";
import { useEffect, useState } from "react";
import newRequest from "../../../../Utils/newRequest";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";

const Certificates = (props) => {
  const [editModal, setEditModal] = useState(false);
  const isMobile = useMediaQuery("(max-width:767px)");
  const [student, setStudent] = useState({});
  const user = props?.user;

  useEffect(() => {
    getTrainer();
  }, []);

  const getTrainer = async () => {
    try {
      const res = await newRequest.get(`/students/${user?.id}`);
      setStudent(res?.data?.data);
      console.log(res.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        الشهادات الحاصل عليها الطالب
      </AccordionSummary>
      <div className="certificates container">
        <div className="header">
          {/* <FormControl sx={{ m: 1 }} style={{ width: isMobile ? "100%" : "" }}>
            <Select
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              value={10}
            >
              <MenuItem value={10}>الابطال المتفوقين</MenuItem>
            </Select>
          </FormControl> */}
        </div>

        {/* <table>
          <tbody>
            <tr>
              <th>الاسم</th>
              <td>اياد عادل</td>
            </tr>
            <tr>
              <th>أسم البطولة</th>
              <td>01028252809</td>
            </tr>
            <tr>
              <th>المستوي</th>
              <td>اياد عادل</td>
            </tr>
            <tr>
              <th>الفئة العمرية</th>
              <td>اياد عادل</td>
            </tr>
            <tr>
              <th>عدد المشتركين</th>
              <td>اياد عادل</td>
            </tr>
            <tr>
              <th>المركز</th>
              <td>اياد عادل</td>
            </tr>
            <tr>
              <th>عدد النقاط الحاصل عليها</th>
              <td>اياد عادل</td>
            </tr>
          </tbody>
        </table> */}

        <div className="certificates-container">
          <h3>الشهادات الحاصل عليها</h3>
          <div className="img-container">
            {student?.certificates?.map((certificate) => (
              <img
                src={certificate.certificate_ar}
                alt=""
                key={certificate.id}
              />
            ))}
          </div>
        </div>
      </div>
    </Accordion>
  );
};

export default Certificates;
