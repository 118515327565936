import React from "react";
import { Flex, Spin } from "antd";
const Loader = () => (
  <Flex
    align="center"
    gap="middle"
    style={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spin size="large" />
  </Flex>
);
export default Loader;
