import "./CreateEditSlider.scss";
import { useLocation, useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUploadOutlined";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { useState } from "react";
import Button from "../../../../Components/Button/Button";
import newRequest from "../../../../Utils/newRequest";
import { message } from "antd";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import Loader from "../../../../Components/Loader/Loader";

const CreateEditSlider = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const editSlider = location.state;
  const isCreate = location.pathname.includes("/create");
  const isTrainer = location.pathname.includes("/trainers");
  const [loading, setLoading] = useState(false);
  const [newSlider, setNewSlider] = useState(null || editSlider?.image);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const formData = new FormData();
    formData.append("image", newSlider);
    formData.append("type", isTrainer ? "trainer" : "student");

    if (isCreate) {
      try {
        await newRequest.post("/sliders", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        message.success("تم اضافة الصورة بنجاح");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
        setNewSlider(null);
      }
    } else {
      try {
        await newRequest.post(
          `/sliders/${editSlider?.id}`,
          { image: newSlider, _method: "PUT" },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        message.success("تم تعديل الصورة بنجاح");
        navigate(`/slider-images/${isTrainer ? "trainers" : "students"}`);
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="create-profile-page">
      <div className="container">
        <div className="header">
          <h3 className="title">اضافة صورة</h3>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="file-group">
              <div className="form-control">
                <span className="image-container">
                  {newSlider && (
                    <>
                      <img
                        src={
                          newSlider instanceof File
                            ? URL.createObjectURL(newSlider)
                            : newSlider
                        }
                        alt=""
                      />
                      <CloseIcon
                        className="icon"
                        onClick={() => setNewSlider(null)}
                      />
                    </>
                  )}
                  {/* {newSlider?.name} */}
                </span>
                <label htmlFor="file" className="file-label">
                  <FileUploadIcon className="icon" />
                  تحميل الصورة
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setNewSlider(e.target.files[0])}
                />
              </div>
            </div>

            <div className="action-control">
              <Button
                text={isCreate ? "اضافة" : "تعديل"}
                variant={"fill"}
                size={"small"}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateEditSlider;
