import "./CreateEditCountry.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControl, TextField } from "@mui/material";
import { useState } from "react";
import Button from "../../../../Components/Button/Button";
import newRequest from "../../../../Utils/newRequest";
import { message } from "antd";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import Loader from "../../../../Components/Loader/Loader";

const CreateEditCountry = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const country = location.state;
  const [loading, setLoading] = useState(false);
  const isCreate = location.pathname.includes("create");
  const [newCountry, setNewCountry] = useState({
    name: {
      ar: "",
      en: "",
    },
  });
  const [editCountry, setEditCountry] = useState({
    name: {
      ar: country?.name || "",
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (isCreate) {
      try {
        await newRequest.post(`/countries`, { ...newCountry });
        message.success(
          isCreate ? "تم اضافة الدولة بنجاح" : "تم تعديل الدولة بنجاح"
        );
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setNewCountry({});
        setLoading(false);
      }
    } else {
      try {
        await newRequest.put(`/countries/${country?.id}`, { ...editCountry });
        message.success(
          isCreate ? "تم اضافة الدولة بنجاح" : "تم تعديل الدولة بنجاح"
        );
        navigate(`/countries`);
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    const [property, language] = name.split(".");
    if (isCreate) {
      setNewCountry((prevState) => ({
        ...prevState,
        [property]: {
          ...prevState[property],
          [language]: value,
        },
      }));
    } else {
      setEditCountry((prevState) => ({
        ...prevState,
        [property]: {
          ...prevState[property],
          [language]: value,
        },
      }));
    }
  };

  return (
    <div className="create-profile-page">
      <div className="container">
        <div className="header">
          <h3 className="title">
            {isCreate ? "اضافة ( دولة )" : "تعديل ( دولة )"}
          </h3>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الاسم بالعربية</label>
                <TextField
                  fullWidth
                  className="form-input"
                  required
                  name="name.ar"
                  onChange={handleChange}
                  value={
                    isCreate ? newCountry?.name?.ar : editCountry?.name?.ar
                  }
                />
              </FormControl>
              {isCreate && (
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">الاسم بالانجليزية</label>
                  <TextField
                    fullWidth
                    className="form-input"
                    required
                    name="name.en"
                    onChange={handleChange}
                  />
                </FormControl>
              )}
            </div>

            {/* <div className="control-group">
            <FormControl sx={{ m: 1, flex: 1 }} className="select">
              <label className="form-label">المنطقة</label>
              <Select
                placeholder="ادخل العنوان"
                fullWidth
                className="form-input"
                required
                // onChange={(e) => setAnswer(e.target.value)}
              />
            </FormControl>
            <FormControl
              sx={{ m: 1, flex: 1 }}
              className="select"
            ></FormControl>
          </div>

          <div className="control-group">
            <FormControl sx={{ m: 1, flex: 1 }} className="select">
              <label className="form-label">Country</label>
              <Select
                fullWidth
                className="form-input"
                required
                // onChange={(e) => setAnswer(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ m: 1, flex: 1 }} className="select">
              <label className="form-label">City</label>
              <Select
                fullWidth
                className="form-input"
                required
                // onChange={(e) => setAnswer(e.target.value)}
              />
            </FormControl>
          </div>

          <div className="control-group">
            <FormControl sx={{ m: 1, flex: 1 }} className="select">
              <label className="form-label">Regoin</label>
              <Select
                fullWidth
                className="form-input"
                required
                // onChange={(e) => setAnswer(e.target.value)}
              />
            </FormControl>
            <FormControl
              sx={{ m: 1, flex: 1 }}
              className="select"
            ></FormControl>
          </div> */}

            <div className="action-control">
              <Button
                text={isCreate ? "اضافة" : "تعديل"}
                variant={"fill"}
                size={"small"}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateEditCountry;
