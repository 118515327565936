import Button from "./Components/Button/Button";
import { saveAs } from "file-saver";
import moment from "moment";
import HomeIcon from "@mui/icons-material/Home";
import SubAdminIcon from "@mui/icons-material/SupervisorAccount";
import ProfileIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import PackagesIcon from "@mui/icons-material/LibraryBooks";
import DepartmentsIcon from "@mui/icons-material/Category";
import QuestionsIcon from "@mui/icons-material/QuestionMark";
import StudentsIcon from "@mui/icons-material/School";
import TrainersIcon from "@mui/icons-material/SupervisedUserCircle";
import ChampionshipsIcon from "@mui/icons-material/EmojiEvents";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SupportIcon from "@mui/icons-material/SupportAgent";
import ContactIcon from "@mui/icons-material/ContactSupport";
import { getRemainingDays } from "./Utils/getRemainingDays";
import { getAge } from "./Utils/getAge";
import CertificateIcon from "./Assets/certificate-icon.png";
import PersonImg from "./Assets/person-icon.png";
import Download from "./Assets/download-icon.png";
import { handleDownloadPdf } from "./Utils/handleDownloadPdf";

export const StudentsColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "name",
    headerName: "الاسم",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "phone",
    headerName: "رقم الموبايل",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "birth_date",
    headerName: "العمر",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (birth_date) => {
      return getAge(birth_date);
    },
  },
  {
    field: "country",
    headerName: "الدولة",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (country) => country?.name,
  },
  {
    field: "city",
    headerName: "المحافظة",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (city) => city?.name,
  },
  {
    field: "region",
    headerName: "المنطقة",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (region) => region?.name,
  },
  {
    field: "subscription",
    headerName: "اسم باقة",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (subscription) => subscription?.package?.title || "لا يوجد",
  },
  {
    field: "subscriptionEndDate",
    headerName: "تاريخ انتهاء الاشتراك",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (paramas) =>
      moment(paramas?.row?.subscription?.end_date).format("YYYY-MM-DD"),
  },
  {
    field: "remainingDays",
    headerName: "عدد الايام المتبقية علي اشتراكة",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => {
      const startDate = params?.row?.subscription?.start_date;
      const endDate = params?.row?.subscription?.end_date;
      const daysDifference = getRemainingDays(startDate, endDate);
      return daysDifference;
    },
  },
  {
    field: "created_at",
    headerName: "تاريخ التسجيل",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (created_at) =>
      moment(created_at).format("YYYY/MM/DD - hh:mm A"),
  },
  {
    field: "register_method",
    headerName: "طريقة التسجيل",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "trainings_count",
    headerName: "عدد التدريبات التي قام بها",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "competitions_count",
    headerName: "عدد المسابقات التي دخل فيها",
    minWidth: 240,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "championships_count",
    headerName: "عدد البطولات التي اشترك بها",
    minWidth: 240,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "student_level",
    headerName: "المستوي",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "active",
    headerName: "حالة الحساب",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (paramas) => {
      return paramas?.row?.active === 1 ? "نشط" : "غير نشط";
    },
  },
  {
    field: "student_points",
    headerName: "عدد النقاط",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "trainers_count",
    headerName: "عدد المدربين الذين تم التواصل معهم",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "last_championship_order",
    headerName: "ترتيبة في اخر بطولة",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (params) => params?.last_championship_order || "لا يوجد",
  },
  {
    field: "age_category",
    headerName: "الفئة العمرية",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
];

export const TrainersColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "name",
    headerName: "الاسم",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "phone",
    headerName: "رقم الموبايل الشخصي",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "business_phone",
    headerName: "رقم موبايل العمل",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "review_avg_rate",
    headerName: "التقيم",
    minWidth: 180,

    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "email",
    headerName: "البريد الالكتروني",
    minWidth: 200,

    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "gender",
    headerName: "الجنس",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "country",
    headerName: "الدولة",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (country) => country?.name,
  },
  {
    field: "city",
    headerName: "المحافظة",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (city) => city?.name,
  },
  {
    field: "region",
    headerName: "المنطقة",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (region) => region?.name,
  },
  {
    field: "subscription",
    headerName: "اسم باقة الاشتراك",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (subscription) => subscription?.package?.title,
  },
  {
    field: "subscriptionEndDate",
    headerName: "تاريخ انتهاء الاشتراك",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (paramas) =>
      moment(paramas?.row?.subscription?.end_date).format("YYYY-MM-DD"),
  },
  {
    field: "remainingDays",
    headerName: "عدد الايام المتبقية علي اشتراكة",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => {
      const startDate = params?.row?.subscription?.start_date;
      const endDate = params?.row?.subscription?.end_date;
      const daysDifference = getRemainingDays(startDate, endDate);
      return daysDifference;
    },
  },
  {
    field: "created_at",
    headerName: "تاريخ التسجيل",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (created_at) => moment(created_at).format("YYYY-MM-DD") ?? "",
  },
  {
    field: "register_method",
    headerName: "طريقة التسجيل",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "trainings_count",
    headerName: "التدريبات التي قام بانشاءها للطلاب",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "competitions_count",
    headerName: "المسابقات التي قام بانشاءها للطلاب",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
];

export const TrainerTrainingsColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "training_type",
    headerName: "اسم التدريب",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (training_type) => training_type?.title,
  },
  {
    field: "type_level",
    headerName: "نوع التدريب",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (type_level) => type_level?.title || "لا يوجد",
  },
  {
    field: "created_at",
    headerName: "تاريخ و وقت الاضافة",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (created_at) =>
      moment(created_at).format("YYYY/MM/DD - hh:mm A"),
  },
  {
    field: "questions",
    headerName: "عدد الاسئلة",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (questions) => questions?.length,
  },
  {
    field: "subscribers_count",
    headerName: "عدد الطلاب المتفاعلين مع التدريب",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "correct_answers_count",
    headerName: "النسبة الصحيحه لحل التدريب",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (correct_answers_count) => correct_answers_count || "لا يوجد",
  },
  {
    field: "wrong_answers_count",
    headerName: "النسبة الخاطئة لحل التدريب",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (wrong_answers_count) => wrong_answers_count || "لا يوجد",
  },
];

export const TrainerCompetitionsColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "type_level",
    headerName: "نوع المسابقة",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (type_level) => type_level?.title,
  },
  {
    field: "level_category",
    headerName: "اسم المسابقة",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (level_category) => level_category?.title,
  },

  {
    field: "competition_datetime",
    headerName: "تاريخ ووقت الاضافة",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (competition_datetime) =>
      moment(competition_datetime).format("YYYY/MM/DD - hh:mm A"),
  },
  {
    field: "questions",
    headerName: "عدد الاسئلة",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (questions) => questions?.length,
  },
  {
    field: "subscribers_count",
    headerName: "عدد الطلاب المتفاعلين مع المسابقة",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "correct_answers_count",
    headerName: "النسبة الصحيحه لحل المسابقة",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (correct_answers_count) => correct_answers_count || "لا يوجد",
  },
  {
    field: "wrong_answers_count",
    headerName: "النسبة الخاطئة لحل المسابقة",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (wrong_answers_count) => wrong_answers_count || "لا يوجد",
  },
];

export const QuestionsColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "numbers",
    headerName: "السؤال",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: true,
  },
  {
    field: "training_type",
    headerName: "نوع السؤال",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: true,
    // renderCell: (params) => params.row.training_type?.title || "",
    valueGetter: (training_type) => training_type?.title,
  },
  {
    field: "type_level",
    headerName: "القسم الرئيسي - المستوى",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: true,
    // renderCell: (params) => params.row.type_level?.title || "",
    valueGetter: (type_level) => type_level?.title,
  },
  {
    field: "level_category",
    headerName: "القسم الفرعي",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: true,
    // renderCell: (params) => params.row.level_category?.title || "",
    valueGetter: (level_category) => level_category?.title,
  },
  {
    field: "numbers_count",
    headerName: "عدد الارقام في السؤال",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: true,
  },
  {
    field: "answer",
    headerName: "الاجابه الصحيحة",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: true,
  },
  {
    field: "choices",
    headerName: "الاختيارات",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: true,
  },
];

export const DepartmentsColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "title",
    headerName: "الاقسام الرئيسية/ المستويات",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,

    // valueGetter: (params) => params?.row?.training_type?.title ?? "",
  },
  {
    field: "icon",
    headerName: "صورة القسم الرئيسي / المستوي",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,

    renderCell: (params) => {
      return (
        <div
          className="cell-with-img"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img className="cell-img" src={params?.row?.icon} alt="avatar" />
        </div>
      );
    },
  },
];

export const SubDepartmentsColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "title",
    headerName: "اسم القسم الفرعي",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
  },
  {
    field: "type_level",
    headerName: "قسم رئيسي - مستوى",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
    valueGetter: (type_level) => type_level?.title,
  },
  {
    field: "questions_count",
    headerName: "عدد الاسئلة ",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
  },
];

export const ViewDepartmentColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "title",
    headerName: "الاقسام الفرعيه المندرجه",
    minWidth: 240,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
  },
];

export const ChampionshipsColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "title",
    headerName: "اسم البطولة",
    minWidth: 240,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "created_at",
    headerName: "تاريخ اضافه البطولة",
    minWidth: 120,
    headerAlign: "center",
    align: "center",

    valueGetter: (created_at) => moment(created_at).format("YYYY-MM-DD"),
  },
  {
    field: "start_at",
    headerName: "موعد البطولة",
    minWidth: 220,
    headerAlign: "center",
    align: "center",

    valueGetter: (start_at) => moment(start_at).format("YYYY-MM-DD hh:mm a "),
  },
  {
    field: "type",
    headerName: "نوع البطولة",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    // renderCell: (params) => {
    //   return params?.row?.type === "general" ? "عامة" : "الطلاب المميزين";
    // },
  },
  {
    field: "price",
    headerName: "سعر الدخول",
    minWidth: 80,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "slogan",
    headerName: "جمله تعريفة",
    minWidth: 240,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "subscribers_count",
    headerName: "عدد المشتركين",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "levels_count",
    headerName: "عدد المستويات داخل المستوي",
    minWidth: 240,
    headerAlign: "center",
    align: "center",
  },
];

export const NotificationColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "image",
    headerName: "ًصورة",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => {
      return (
        <div
          className="cell-with-img"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img className="cell-img" src={params?.row?.image} alt="avatar" />
        </div>
      );
    },
  },
  {
    field: "title",
    headerName: "عنوان",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  // {
  //   field: "titleEn",
  //   headerName: "Title",
  //   minWidth: 160,
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1,
  // },
  {
    field: "desc",
    headerName: "وصف",
    minWidth: 240,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  // {
  //   field: "descEn",
  //   headerName: "Description",
  //   minWidth: 240,
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1,
  // },
  {
    field: "type",
    headerName: "المستلم",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
];

export const SupportColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "question",
    headerName: "السؤال",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "created_at",
    headerName: "تاريخ الانشاء",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (created_at) => moment(created_at).format("YYYY-MM-DD") ?? "",
  },
  {
    field: "createdTime",
    headerName: "وقت الانشاء",
    minWidth: 240,
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => {
      return moment(params?.row?.created_at).format("hh:mm a") ?? "";
    },
  },
  {
    field: "chat_count",
    headerName: "عدد الرسائل",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
];

export const SupportMessagesColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "id",
    headerName: "رقم الدعم",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "support_question",
    headerName: "السؤال",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (support_question) => support_question?.question,
  },
  {
    field: "user",
    headerName: "اسم الطالب",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (user) => user?.name,
  },
  {
    field: "userPhone", // Changed field name
    headerName: "رقم الموبايل",
    minWidth: 240,
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (paramas) => paramas?.row?.user?.phone,
  },
  {
    field: "support_status",
    headerName: "حالة الدعم",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
];

export const ContactColumns = [
  {
    field: "index",
    headerName: "#",
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: true,
  },
  {
    field: "full_name",
    headerName: "أسم المستخدم",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "type",
    headerName: "نوع المستخدم",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "phone",
    headerName: "رقم الموبايل",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "email",
    headerName: "البريد الالكتروني",
    minWidth: 240,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
];

export const AdminsColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "name",
    headerName: "الاسم",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "phone",
    headerName: "رقم الموبايل",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "email",
    headerName: "البريد الالكتروني",
    minWidth: 240,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "created_at",
    headerName: "تاريخ التسجيل",
    minWidth: 120,
    headerAlign: "center",
    align: "center",

    valueGetter: (created_at) => moment(created_at).format("YYYY-MM-DD"),
  },
  {
    field: "last_login_at",
    headerName: "اخر تسجيل دخول",
    minWidth: 120,
    headerAlign: "center",
    align: "center",

    valueGetter: (last_login_at) => moment(last_login_at).format("YYYY-MM-DD"),
  },

  // {
  //   field: "",
  //   headerName: "الصلاحيات",
  //   minWidth: 160,
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1,
  //   renderCell: () => {
  //     return (
  //       <div>
  //         <Button text="ألصلاحيات" varint="outlined" size="small" />
  //       </div>
  //     );
  //   },
  // },
];

export const StudentsTrainersPagesColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "image",
    headerName: "ألصورة",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    sortable: false,
    renderCell: (params) => {
      return (
        <div
          className="cell-with-img"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img className="cell-img" src={params?.row?.image} alt="avatar" />
        </div>
      );
    },
  },
  {
    field: "title",
    headerName: "العنوان",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
  },
  // {
  //   field: "name1",
  //   headerName: "Title",
  //   minWidth: 120,
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1,
  //   sortable: false,
  // },
  {
    field: "desc",
    headerName: "الوصف",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
  },
];

export const TraniersStudentsSlidersColumns = [
  {
    field: "name",
    headerName: "ألصورة",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return (
        <div
          className="cell-with-img"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img className="cell-img" src={params?.row?.image} alt="avatar" />
        </div>
      );
    },
  },
];

export const CountriesColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "name",
    headerName: "الدولة",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
  },
];

export const CitiesColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "country",
    headerName: "الدولة",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
    valueGetter: (country) => country?.name || "تم مسح الدولة",
  },
  {
    field: "name",
    headerName: "المحافظة",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
  },
];

export const RegionsColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "country",
    headerName: "الدولة",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
    renderCell: (paramas) =>
      paramas?.row?.city?.country?.name || "تم مسح الدولة",
  },
  {
    field: "city",
    headerName: "المحافظة",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
    valueGetter: (city) => city?.name || "تم مسح المحافظة",
  },
  {
    field: "name",
    headerName: "المنطقة",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
  },
];

export const StudentRangesColumns = [
  {
    field: "level",
    headerName: "المستوي",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "from",
    headerName: "عدد النقاط ( من )",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "to",
    headerName: "عدد النقاط ( الي )",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
  },
  {
    field: "students_count",
    headerName: "عدد الطلاب",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    sortable: false,
  },
];

export const PackagesColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "title",
    headerName: "اسم الباقة",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "duration",
    headerName: "مدة الباقة",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "duration_type",
    headerName: "نوع الاشتراك",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "price",
    headerName: "سعر الباقة",
    minWidth: 240,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "subscriptions_count",
    headerName: "عدد المشتركين",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
];

export const PromoCodeColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
    sortable: true,
  },
  {
    field: "code",
    headerName: "الكود",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "period",
    headerName: "مدة الكود",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (period) => `${period} يوم `,
  },
  {
    field: "discount",
    headerName: "نسبة الخصم",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (discount) => `${discount} % `,
  },
  {
    field: "start_date",
    headerName: "تاريخ الانشاء",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (start_date) => moment(start_date).format("YYYY-MM-DD") ?? "",
  },
  {
    field: "max_use",
    headerName: "الحد الاقصي",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "end_date",
    headerName: "تاريخ الانتهاء",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "use_count",
    headerName: "عدد مستخدمين",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
];

export const ViewChampionshipColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "questions",
  //   headerName: "الأسئلة",
  //   minWidth: 240,
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1,
  //   // valueGetter: (user) => user?.name,
  // },
  {
    field: "user",
    headerName: "اسماء المشتركين",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (user) => user?.name,
  },
  {
    field: "order",
    headerName: "مركز البطولة",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "age_category",
    headerName: "الفئه العمرية",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (age_category) => age_category?.title,
  },
  {
    field: "certificate_ar",
    headerName: "الشهادة ( عربي )",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => {
      const handleDownload = () => {
        if (params?.row?.certificate_ar) {
          saveAs(params.row.certificate_ar, "certificate.jpg");
        }
      };

      return (
        <div
          className="cell-with-img"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={handleDownload}
        >
          <img
            className="cell-img"
            src={CertificateIcon}
            alt="avatar"
            style={{ width: "50px", height: "45px" }}
          />
          {/* <img
            className="cell-img"
            src={Download}
            alt="avatar"
            style={{ width: "36px", height: "36px" }}
          /> */}
        </div>
      );
    },
  },
  {
    field: "certificate_en",
    headerName: "الشهادة ( انجليزي )",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => {
      const handleDownload = () => {
        if (params?.row?.certificate_en) {
          saveAs(params.row.certificate_en, "certificate.jpg");
        }
      };

      return (
        <div
          className="cell-with-img"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={handleDownload}
        >
          <img
            className="cell-img"
            src={CertificateIcon}
            alt="avatar"
            style={{ width: "50px", height: "45px" }}
          />
          {/* <img
            className="cell-img"
            src={Download}
            alt="avatar"
            style={{ width: "36px", height: "36px" }}
          /> */}
        </div>
      );
    },
  },
  // {
  //   field: "certificate_en",
  //   headerName: "الشهادة ( انجليزي )",
  //   minWidth: 120,
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1,
  //   renderCell: (params) => {
  //     return (
  //       <div
  //         className="cell-with-img"
  //         style={{
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //           cursor: "pointer",
  //         }}
  //         onClick={() => handleDownloadPdf(params?.row?.certificate_en)}
  //       >
  //         <img
  //           className="cell-img"
  //           src={CertificateIcon}
  //           alt="avatar"
  //           style={{ width: "50px", height: "45px" }}
  //         />
  //         <img
  //           className="cell-img"
  //           src={Download}
  //           alt="avatar"
  //           style={{ width: "36px", height: "36px" }}
  //         />
  //       </div>
  //     );
  //   },
  // },
];

export const LastChampionshipWinnersColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "user",
    headerName: "اسم الفائز",
    minWidth: 240,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (user) => user?.name,
  },
  {
    field: "phone",
    headerName: "رقم الموبايل",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (paramas) => paramas?.row?.user?.phone,
  },
  {
    field: "order",
    headerName: "مركز البطولة",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "student_points",
    headerName: "عدد النقاط المكتسبة من البطولة",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "remaining_time",
    headerName: "توقيت إنهاء البطولة",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "correct_answers_count",
    headerName: "عدد الإجابات الصحيحة",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "",
    headerName: "عدد أسئلة البطولة",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
];

export const LastLoginColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "name",
    headerName: "الاسم",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "phone",
    headerName: "رقم الموبايل",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "email",
    headerName: "الايميل",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  // {
  //   field: "student_points",
  //   headerName: "اخر عملية",
  //   minWidth: 120,
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1,
  // },
  {
    field: "last_login_at",
    headerName: "وقت والتاريخ اخر تسجيل",
    minWidth: 220,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (last_login_at) =>
      moment(last_login_at).format("YYYY/MM/DD hh-mm a"),
  },
];

export const MostStudentsColumns = [
  {
    field: "index",
    headerName: "#",
    minWidth: 40,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "name",
    headerName: "الاسم",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "phone",
    headerName: "رقم الموبايل",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "student_level",
    headerName: "المستوي علي التطبيق",
    minWidth: 80,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "student_points",
    headerName: "عدد النقاط الكلية",
    minWidth: 180,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  // {
  //   field: "created_at",
  //   headerName: "الوقت المستهلك علي التطبيق",
  //   minWidth: 220,
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1,
  //   valueGetter: (last_login_at) =>
  //     moment(last_login_at).format("YYYY/MM/DD hh-mm a"),
  // },
];

export const NotificationSearchColumns = [
  {
    field: "logo",
    headerName: "الصورة",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (params) => {
      return (
        <div
          className="cell-with-img"
          style={{
            display: "flex",
            alignItems: "center",
            height: "50px",
          }}
        >
          <img
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
            }}
            src={params?.row?.logo || PersonImg}
            alt="avatar"
          />
        </div>
      );
    },
  },
  {
    field: "name",
    headerName: "الاسم",
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "phone",
    headerName: "رقم الموبايل",
    minWidth: 160,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
];

export const SidebarMenuItems = [
  {
    key: "1",
    label: <span className="label">الصفحة الرئيسية</span>,
    icon: <HomeIcon className="sidebar-icon" />,
    path: "/",
    type: "menuItem",
    permission: 89,
  },
  {
    key: "2",
    label: <span className="label">المسؤلين الفرعين</span>,
    icon: <SubAdminIcon className="sidebar-icon" />,
    path: "/admins",
    type: "menuItem",
    children: ["/admins/create", "/admins/edit"],
    permission: 1,
  },
  {
    key: "3",
    label: <span className="label">الصفحة الشخصية</span>,
    icon: <ProfileIcon className="sidebar-icon" />,
    path: "/profile",
    type: "menuItem",
    permission: 0,
  },
  {
    key: "4",
    label: <span className="label">الإعدادات</span>,
    icon: <SettingsIcon className="sidebar-icon" />,
    path: "/terms-and-conditions",
    type: "menu",
    permission: 9,
    children: [
      "/social-media-links",
      "/profile-pages",
      "/slider-images",
      "/download-link",
      "/countries",
      "/cities",
      "/regions",
      "/student-points",
      "/student-ranges",
    ],
    subMenu: [
      {
        key: "5",
        label: <span className="label">الشروط والاحكام</span>,
        path: "/terms-and-conditions",
        children: [
          "/terms-and-conditions/students",
          "/terms-and-conditions/trainers",
        ],
      },
      {
        key: "6",
        label: <span className="label">روابط التواصل الاجتماعي</span>,
        path: "/social-media-links",
        permission: 9,
      },
      {
        key: "7",
        label: <span className="label">الصفحات التعريفية</span>,
        path: "/profile-pages",
        permission: 5,
        children: [
          "/profile-pages/students",
          // "/profile-pages/students/create",
          "/profile-pages/trainers",
          // "/profile-pages/trainers/create",
          "/profile-pages/create",
          "/profile-pages/edit",
        ],
      },
      {
        key: "8",
        label: <span className="label">صور السلايدر</span>,
        path: "/slider-images",
        permission: 61,
        children: [
          "/slider-images/create",
          "/slider-images/edit",
          "/slider-images/students",
          "/slider-images/trainers",
        ],
      },
      {
        key: "9",
        label: <span className="label">لينك تحميل التطبيق</span>,
        path: "/download-link",
        permission: 9,
      },

      {
        key: "10",
        permission: 13,
        label: <span className="label">الدول</span>,
        path: "/countries",
        children: ["/countries/edit", "/countries/create"],
      },
      {
        key: "101",
        permission: 17,
        label: <span className="label">المحافظات</span>,
        path: "/cities",
        children: ["/cities/edit", "/cities/create"],
      },
      {
        key: "102",
        permission: 21,
        label: <span className="label">المناطق</span>,
        path: "/regions",
        children: ["/regions/edit", "/regions/create"],
      },
      {
        key: "11",
        permission: 9,
        label: <span className="label">نقاط الطلاب</span>,
        path: "/student-points",
      },
      {
        key: "12",
        permission: 85,
        label: <span className="label">نطاقات نقاط التطبيق للطلاب</span>,
        path: "/student-ranges",
        children: ["/student-ranges/create", "/student-ranges/edit"],
      },
    ],
  },
  {
    key: "13",
    permission: 25,
    label: <span className="label">الباقات</span>,
    icon: <PackagesIcon className="sidebar-icon" />,
    path: "/packages/trainer",
    type: "menu",
    children: ["/packages/student", "/packages/promo-code"],
    subMenu: [
      {
        key: "70",
        permission: 25,
        label: <span className="label">باقة المدرب</span>,
        path: "/packages/trainer",
        children: ["/packages/trainer/create", "/packages/trainer/edit"],
      },
      {
        key: "71",
        permission: 25,
        label: <span className="label">باقة الطالب</span>,
        path: "/packages/student",
        children: ["/packages/student/create", "/packages/student/edit"],
      },
      {
        key: "72",
        permission: 45,
        label: <span className="label">اكواد الخصم</span>,
        path: "/packages/promo-code",
        children: ["/packages/promo-code/create"],
      },
    ],
  },
  {
    key: "14",
    permission: 33 || 37,
    label: <span className="label">الأقسام</span>,
    icon: <DepartmentsIcon className="sidebar-icon" />,
    path: "/departments",
    type: "menu",
    children: ["/departments", "/sub-departments"],
    subMenu: [
      {
        permission: 33,
        key: "92",
        label: <span className="label">الأقسام الرئيسية</span>,
        path: "/departments",
        type: "menuItem",
        children: [
          "/departments/create",
          "/departments/view",
          "/departments/edit",
        ],
      },
      {
        permission: 37,
        label: <span className="label">الأقسام الفرعية</span>,
        path: "/sub-departments",
        type: "menuItem",
        children: [
          "/sub-departments/create",
          "/sub-departments/view",
          "/sub-departments/edit",
        ],
      },
    ],
  },

  {
    key: "16",
    permission: 41,
    label: <span className="label">الاسئلة</span>,
    icon: <QuestionsIcon className="sidebar-icon" />,
    path: "/questions",
    type: "menuItem",
    children: ["/questions/create", "/questions/add-excel", "/questions/edit"],
  },
  {
    key: "17",
    permission: 69,
    label: <span className="label">الطلاب المسجلين</span>,
    icon: <StudentsIcon className="sidebar-icon" />,
    path: "/students",
    type: "menuItem",
    children: ["/students/create", "/students/view", "/students/edit"],
  },
  {
    key: "18",
    permission: 65,
    label: <span className="label">المدربين المسجلين</span>,
    icon: <TrainersIcon className="sidebar-icon" />,
    path: "/trainers",
    type: "menuItem",
    children: [
      "/trainers/create",
      "/trainers/view",
      "/trainers/edit",
      "/trainers/chats",
    ],
  },
  {
    key: "19",
    permission: 77,
    label: <span className="label">البطولات</span>,
    icon: <ChampionshipsIcon className="sidebar-icon" />,
    path: "/championships",
    type: "menuItem",
    children: [
      "/championships/create",
      "/championships/view",
      "/championships/edit",
      "/championships/create-exam",
    ],
  },
  {
    key: "20",
    permission: 81,
    label: <span className="label">الاشعارات</span>,
    icon: <NotificationsIcon className="sidebar-icon" />,
    path: "/notifications",
    type: "menuItem",
  },
  {
    key: "21",
    permission: 77,
    label: <span className="label">الدعم الفني</span>,
    icon: <SupportIcon className="sidebar-icon" />,
    path: "/support/students",
    type: "menu",
    children: [
      "/support/trainers",
      "/support/students-messages",
      "/support/trainers-messages",
    ],
    subMenu: [
      {
        key: "22",
        label: <span className="label">اسئلة الطلاب</span>,
        path: "/support/students",
        children: ["/support/students/create", "/support/students/edit"],
      },
      {
        key: "23",
        label: <span className="label">اسئلة المدرب</span>,
        path: "/support/trainers",
        children: ["/support/trainers/create", "/support/trainers/edit"],
      },
      {
        key: "24",
        label: <span className="label">رسائل الدعم للطلاب</span>,
        path: "/support/students-messages",
        children: [
          "/support/students-messages/view",
          "/support/students-messages/answer",
        ],
      },
      {
        key: "25",
        label: <span className="label">رسائل الدعم للمدرب</span>,
        path: "/support/trainers-messages",
        children: [
          "/support/trainers-messages/view",
          "/support/trainers-messages/answer",
        ],
      },
    ],
  },
  {
    type: "menuItem",
    key: "26",
    label: <span className="label">تواصل معنا</span>,
    icon: <ContactIcon className="sidebar-icon" />,
    path: "/contact",
    children: ["/contact/view", "/contact/answer"],
  },
];

export const NavbarMenuItems = [
  { label: "الصفحة الرئيسية", path: "/" },
  { label: "المسؤلين الفرعين", path: "/admins" },
  { label: "الصفحة الشخصية", path: "/profile" },
  { label: "الإعدادات", path: "/terms-and-conditions" },
  { label: "الإعدادات", path: "/social-media-links" },
  { label: "الإعدادات", path: "/profile-pages" },
  { label: "الإعدادات", path: "/slider-images" },
  { label: "الإعدادات", path: "/download-link" },
  { label: "الإعدادات", path: "/countries" },
  { label: "الإعدادات", path: "/cities" },
  { label: "الإعدادات", path: "/regions" },
  { label: "الإعدادات", path: "/student-points" },
  { label: "الإعدادات", path: "/student-ranges" },
  { label: "الباقات", path: "/packages/trainer" },
  { label: "الباقات", path: "/packages/student" },
  { label: "الباقات", path: "/packages/promo-code" },
  { label: "الأقسام الرئيسية", path: "/departments" },
  { label: "الأقسام الرئيسية", path: "/departments/create" },
  { label: "الأقسام الرئيسية", path: "/departments/edit" },
  { label: "الأقسام الرئيسية", path: "/departments/view" },
  { label: "الأقسام الفرعية", path: "/sub-departments" },
  { label: "الأقسام الفرعية", path: "/sub-departments/create" },
  { label: "الأقسام الفرعية", path: "/sub-departments/edit" },
  { label: "الاسئلة", path: "/questions" },
  { label: "الاسئلة", path: "/questions/add-excel" },
  { label: "الاسئلة", path: "/questions/create" },
  { label: "الاسئلة", path: "/questions/edit" },
  { label: "الطلاب المسجلين", path: "/students" },
  { label: "الطلاب المسجلين", path: "/students/view" },
  { label: "الطلاب المسجلين", path: "/students/create" },
  { label: "الطلاب المسجلين", path: "/students/edit" },
  { label: "الطلاب الاونلاين", path: "/students/online-students" },
  { label: "اخر تسجيل علي النظام", path: "/last-login" },
  { label: "الطلاب اشتراك تجريبي", path: "/students/free-package" },
  { label: "الطلاب اكتر تدريباً", path: "/students/most-trainings" },
  { label: "الفائزين في اخر بطولة", path: "/championship-winners" },
  { label: "المدربين المسجلين", path: "/trainers" },
  { label: "المدربين المسجلين", path: "/trainers/view" },
  { label: "المدربين المسجلين", path: "/trainers/create" },
  { label: "المدربين المسجلين", path: "/trainers/edit" },
  { label: "المدربين المميزين", path: "/trainers/special-trainers" },
  { label: "المدربين اشتراك تجريبي", path: "/trainers/free-package" },
  { label: "البطولات", path: "/championships" },
  { label: "الاشعارات", path: "/notifications" },
  { label: "الاشعارات", path: "/notifications/create" },
  { label: "الاشعارات", path: "/notifications/view" },
  { label: "الدعم الفني", path: "/support/students" },
  { label: "الدعم الفني", path: "/support/students/create" },
  { label: "الدعم الفني", path: "/support/students/edit" },
  { label: "الدعم الفني", path: "/support/trainers" },
  { label: "الدعم الفني", path: "/support/trainers/create" },
  { label: "الدعم الفني", path: "/support/trainers/edit" },
  { label: "الدعم الفني", path: "/support/students-messages" },
  { label: "الدعم الفني", path: "/support/students-messages/view" },
  { label: "الدعم الفني", path: "/support/students-messages/edit" },
  { label: "الدعم الفني", path: "/support/students-messages/answer" },
  { label: "الدعم الفني", path: "/support/trainers-messages" },
  { label: "الدعم الفني", path: "/support/trainers-messages/view" },
  { label: "الدعم الفني", path: "/support/trainers-messages/edit" },
  { label: "الدعم الفني", path: "/support/trainers-messages/answer" },
  { label: "تواصل معنا", path: "/contact" },
  { label: "تواصل معنا", path: "/contact/answer" },
  { label: "تواصل معنا", path: "/contact/view" },
];

export const searchOptions = [
  {
    key: 1,
    label: "الصفحة الرئيسية",
    path: "/",
  },
  {
    key: 2,
    label: "المسؤلين الفرعين",
    path: "/admins",
  },
  {
    key: 3,
    label: "الصفحة الشخصية",
    path: "/profile",
  },
  {
    key: 4,
    label: "روابط التواصل الاجتماعي",
    path: "/social-media-links",
  },
  {
    key: 5,
    label: "الصفحات التعريفية",
    path: "/profile-pages",
  },
  {
    key: 6,
    label: "صور السلايدر",
    path: "/slider-images",
  },
  {
    key: 7,
    label: "لينك تحميل التطبيق",
    path: "/download-link",
  },
  {
    key: 8,
    label: "الدول",
    path: "/countries",
  },
  {
    key: 9,
    label: "المحافظات",
    path: "/cities",
  },
  {
    key: 10,
    label: "المناطق",
    path: "/regions",
  },
  {
    key: 11,
    label: "نقاط الطلاب",
    path: "/student-points",
  },
  {
    key: 12,
    label: "نطاقات نقاط",
    path: "/student-ranges",
  },
  {
    key: 13,
    label: "الإعدادات",
    path: "/terms-and-conditions",
  },
  {
    key: 14,
    label: "باقة المدرب",
    path: "/packages/trainer",
  },
  {
    key: 15,
    label: "باقة الطالب",
    path: "/packages/student",
  },
  {
    key: 16,
    label: "اكواد الخصم",
    path: "/packages/promo-code",
  },
  {
    key: 17,
    label: "الأقسام الرئيسية",
    path: "/departments",
  },
  {
    key: 18,
    label: "الأقسام الفرعية",
    path: "/sub-departments",
  },
  {
    key: 19,
    label: "الاسئلة",
    path: "/questions",
  },
  {
    key: 20,
    label: "الطلاب المسجلين",
    path: "/students",
  },
  {
    key: 21,
    label: "المدربين المسجلين",
    path: "/trainers",
  },
  {
    key: 22,
    label: "البطولات",
    path: "/championships",
  },
  {
    key: 23,
    label: "الاشعارات",
    path: "/notifications",
  },
  {
    key: 24,
    label: "اسئلة الطلاب",
    path: "/support/students",
  },
  {
    key: 25,
    label: "اسئلة المدرب",
    path: "/support/trainers",
  },
  {
    key: 26,
    label: "رسائل الدعم للطلاب",
    path: "/support/students-messages",
  },
  {
    key: 27,
    label: "رسائل الدعم للمدرب",
    path: "/support/trainers-messages",
  },
  {
    key: 28,
    label: "تواصل معنا",
    path: "/contact",
  },
  {
    key: 29,
    label: "الفائزين في اخر بطولة",
    path: "/championship-winners",
  },
];

export const CountriesCodes = [
  { code: "AD", phone: "376" },
  { code: "AE", phone: "971" },
  { code: "AF", phone: "93" },
  { code: "AG", phone: "1-268" },
  { code: "AI", phone: "1-264" },
  { code: "AL", phone: "355" },
  { code: "AM", phone: "374" },
  { code: "AO", phone: "244" },
  { code: "AQ", phone: "672" },
  { code: "AR", phone: "54" },
  { code: "AS", phone: "1-684" },
  { code: "AT", phone: "43" },
  { code: "AU", phone: "61", suggested: true },
  { code: "AW", phone: "297" },
  { code: "AX", phone: "358" },
  { code: "AZ", phone: "994" },
  { code: "BA", phone: "387" },
  { code: "BB", phone: "1-246" },
  { code: "BD", phone: "880" },
  { code: "BE", phone: "32" },
  { code: "BF", phone: "226" },
  { code: "BG", phone: "359" },
  { code: "BH", phone: "973" },
  { code: "BI", phone: "257" },
  { code: "BJ", phone: "229" },
  { code: "BL", phone: "590" },
  { code: "BM", phone: "1-441" },
  { code: "BN", phone: "673" },
  { code: "BO", phone: "591" },
  { code: "BR", phone: "55" },
  { code: "BS", phone: "1-242" },
  { code: "BT", phone: "975" },
  { code: "BV", phone: "47" },
  { code: "BW", phone: "267" },
  { code: "BY", phone: "375" },
  { code: "BZ", phone: "501" },
  { code: "CA", phone: "1", suggested: true },
  { code: "CC", phone: "61" },
  { code: "CD", phone: "243" },
  { code: "CF", phone: "236" },
  { code: "CG", phone: "242" },
  { code: "CH", phone: "41" },
  { code: "CI", phone: "225" },
  { code: "CK", phone: "682" },
  { code: "CL", phone: "56" },
  { code: "CM", phone: "237" },
  { code: "CN", phone: "86" },
  { code: "CO", phone: "57" },
  { code: "CR", phone: "506" },
  { code: "CU", phone: "53" },
  { code: "CV", phone: "238" },
  { code: "CW", phone: "599" },
  { code: "CX", phone: "61" },
  { code: "CY", phone: "357" },
  { code: "CZ", phone: "420" },
  { code: "DE", phone: "49", suggested: true },
  { code: "DJ", phone: "253" },
  { code: "DK", phone: "45" },
  { code: "DM", phone: "1-767" },
  { code: "DO", phone: "1-809" },
  { code: "DZ", phone: "213" },
  { code: "EC", phone: "593" },
  { code: "EE", phone: "372" },
  { code: "EG", phone: "20" },
  { code: "EH", phone: "212" },
  { code: "ER", phone: "291" },
  { code: "ES", phone: "34" },
  { code: "ET", phone: "251" },
  { code: "FI", phone: "358" },
  { code: "FJ", phone: "679" },
  { code: "FK", phone: "500" },
  { code: "FM", phone: "691" },
  { code: "FO", phone: "298" },
  { code: "FR", phone: "33", suggested: true },
  { code: "GA", phone: "241" },
  { code: "GB", phone: "44" },
  { code: "GD", phone: "1-473" },
  { code: "GE", phone: "995" },
  { code: "GF", phone: "594" },
  { code: "GG", phone: "44" },
  { code: "GH", phone: "233" },
  { code: "GI", phone: "350" },
  { code: "GL", phone: "299" },
  { code: "GM", phone: "220" },
  { code: "GN", phone: "224" },
  { code: "GP", phone: "590" },
  { code: "GQ", phone: "240" },
  { code: "GR", phone: "30" },
  { code: "GS", phone: "500" },
  { code: "GT", phone: "502" },
  { code: "GU", phone: "1-671" },
  { code: "GW", phone: "245" },
  { code: "GY", phone: "592" },
  { code: "HK", phone: "852" },
  { code: "HM", phone: "672" },
  { code: "HN", phone: "504" },
  { code: "HR", phone: "385" },
  { code: "HT", phone: "509" },
  { code: "HU", phone: "36" },
  { code: "ID", phone: "62" },
  { code: "IE", phone: "353" },
  { code: "IL", phone: "972" },
  { code: "IM", phone: "44" },
  { code: "IN", phone: "91" },
  { code: "IO", phone: "246" },
  { code: "IQ", phone: "964" },
  { code: "IR", phone: "98" },
  { code: "IS", phone: "354" },
  { code: "IT", phone: "39" },
  { code: "JE", phone: "44" },
  { code: "JM", phone: "1-876" },
  { code: "JO", phone: "962" },
  { code: "JP", phone: "81", suggested: true },
  { code: "KE", phone: "254" },
  { code: "KG", phone: "996" },
  { code: "KH", phone: "855" },
  { code: "KI", phone: "686" },
  { code: "KM", phone: "269" },
  { code: "KN", phone: "1-869" },
  { code: "KP", phone: "850" },
  { code: "KR", phone: "82" },
  { code: "KW", phone: "965" },
  { code: "KY", phone: "1-345" },
  { code: "KZ", phone: "7" },
  { code: "LA", phone: "856" },
  { code: "LB", phone: "961" },
  { code: "LC", phone: "1-758" },
  { code: "LI", phone: "423" },
  { code: "LK", phone: "94" },
  { code: "LR", phone: "231" },
  { code: "LS", phone: "266" },
  { code: "LT", phone: "370" },
  { code: "LU", phone: "352" },
  { code: "LV", phone: "371" },
  { code: "LY", phone: "218" },
  { code: "MA", phone: "212" },
  { code: "MC", phone: "377" },
  { code: "MD", phone: "373" },
  { code: "ME", phone: "382" },
  { code: "MF", phone: "590" },
  { code: "MG", phone: "261" },
  { code: "MH", phone: "692" },
  { code: "MK", phone: "389" },
  { code: "ML", phone: "223" },
  { code: "MM", phone: "95" },
  { code: "MN", phone: "976" },
  { code: "MO", phone: "853" },
  { code: "MP", phone: "1-670" },
  { code: "MQ", phone: "596" },
  { code: "MR", phone: "222" },
  { code: "MS", phone: "1-664" },
  { code: "MT", phone: "356" },
  { code: "MU", phone: "230" },
  { code: "MV", phone: "960" },
  { code: "MW", phone: "265" },
  { code: "MX", phone: "52" },
  { code: "MY", phone: "60" },
  { code: "MZ", phone: "258" },
  { code: "NA", phone: "264" },
  { code: "NC", phone: "687" },
  { code: "NE", phone: "227" },
  { code: "NF", phone: "672" },
  { code: "NG", phone: "234" },
  { code: "NI", phone: "505" },
  { code: "NL", phone: "31" },
  { code: "NO", phone: "47" },
  { code: "NP", phone: "977" },
  { code: "NR", phone: "674" },
  { code: "NU", phone: "683" },
  { code: "NZ", phone: "64" },
  { code: "OM", phone: "968" },
  { code: "PA", phone: "507" },
  { code: "PE", phone: "51" },
  { code: "PF", phone: "689" },
  { code: "PG", phone: "675" },
  { code: "PH", phone: "63" },
  { code: "PK", phone: "92" },
  { code: "PL", phone: "48" },
  { code: "PM", phone: "508" },
  { code: "PN", phone: "870" },
  { code: "PR", phone: "1" },
  { code: "PT", phone: "351" },
  { code: "PW", phone: "680" },
  { code: "PY", phone: "595" },
  { code: "QA", phone: "974" },
  { code: "RE", phone: "262" },
  { code: "RO", phone: "40" },
  { code: "RS", phone: "381" },
  { code: "RU", phone: "7" },
  { code: "RW", phone: "250" },
  { code: "SA", phone: "966" },
  { code: "SB", phone: "677" },
  { code: "SC", phone: "248" },
  { code: "SD", phone: "249" },
  { code: "SE", phone: "46" },
  { code: "SG", phone: "65" },
  { code: "SH", phone: "290" },
  { code: "SI", phone: "386" },
  { code: "SJ", phone: "47" },
  { code: "SK", phone: "421" },
  { code: "SL", phone: "232" },
  { code: "SM", phone: "378" },
  { code: "SN", phone: "221" },
  { code: "SO", phone: "252" },
  { code: "SR", phone: "597" },
  { code: "SS", phone: "211" },
  { code: "ST", phone: "239" },
  { code: "SV", phone: "503" },
  { code: "SX", phone: "1-721" },
  { code: "SY", phone: "963" },
  { code: "SZ", phone: "268" },
  { code: "TC", phone: "1-649" },
  { code: "TD", phone: "235" },
  { code: "TF", phone: "262" },
  { code: "TG", phone: "228" },
  { code: "TH", phone: "66" },
  { code: "TJ", phone: "992" },
  { code: "TK", phone: "690" },
  { code: "TL", phone: "670" },
  { code: "TM", phone: "993" },
  { code: "TN", phone: "216" },
  { code: "TO", phone: "676" },
  { code: "TR", phone: "90" },
  { code: "TT", phone: "1-868" },
  { code: "TV", phone: "688" },
  { code: "TW", phone: "886" },
  { code: "TZ", phone: "255" },
  { code: "UA", phone: "380" },
  { code: "UG", phone: "256" },
  { code: "US", phone: "1", suggested: true },
  { code: "UY", phone: "598" },
  { code: "UZ", phone: "998" },
  { code: "VA", phone: "379" },
  { code: "VC", phone: "1-784" },
  { code: "VE", phone: "58" },
  { code: "VG", phone: "1-284" },
  { code: "VI", phone: "1-340" },
  { code: "VN", phone: "84" },
  { code: "VU", phone: "678" },
  { code: "WF", phone: "681" },
  { code: "WS", phone: "685" },
  { code: "XK", phone: "383" },
  { code: "YE", phone: "967" },
  { code: "YT", phone: "262" },
  { code: "ZA", phone: "27" },
  { code: "ZM", phone: "260" },
  { code: "ZW", phone: "263" },
];

export const AdminPermissions = [
  {
    title: "الصفحة الرئيسية",
    id: 24,
    permissions: [
      {
        id: 87,
        name: "رؤية",
      },
    ],
  },
  {
    title: "المسؤلين الفرعين",
    id: 23,
    permissions: [
      {
        id: 1,
        name: "رؤية",
      },
      {
        id: 2,
        name: "اضافة",
      },
      {
        id: 3,
        name: "تعديل",
      },
      {
        id: 4,
        name: "مسح",
      },
    ],
  },
  {
    title: "الاعدادات",
    id: 1,
    permissions: [
      {
        id: 5,
        name: "رؤية",
      },

      {
        id: 7,
        name: "تعديل",
      },
    ],
  },

  {
    title: "الاعدادات - الشروط والاحكام",
    id: 2,
    permissions: [
      {
        id: 31,
        name: "رؤية",
      },
      {
        id: 32,
        name: "اضافة",
      },
      {
        id: 33,
        name: "تعديل",
      },
      {
        id: 34,
        name: "مسح",
      },
    ],
  },

  {
    title: "الاعدادات - الصفحات التعريفية",
    id: 3,
    permissions: [
      {
        id: 23,
        name: "رؤية",
      },
      {
        id: 24,
        name: "اضافة",
      },
      {
        id: 25,
        name: "تعديل",
      },
      {
        id: 26,
        name: "مسح",
      },
    ],
  },

  {
    title: "الاعدادات - صور السلايدر",
    id: 4,
    permissions: [
      {
        id: 27,
        name: "رؤية",
      },
      {
        id: 28,
        name: "اضافة",
      },
      {
        id: 29,
        name: "تعديل",
      },
      {
        id: 30,
        name: "مسح",
      },
    ],
  },

  {
    title: "الاعدادات - الدول",
    id: 5,
    permissions: [
      {
        id: 7,
        name: "رؤية",
      },
      {
        id: 8,
        name: "اضافة",
      },
      {
        id: 9,
        name: "تعديل",
      },
      {
        id: 10,
        name: "مسح",
      },
    ],
  },

  {
    title: "الاعدادات - المدن",
    id: 6,
    permissions: [
      {
        id: 11,
        name: "رؤية",
      },
      {
        id: 12,
        name: "اضافة",
      },
      {
        id: 13,
        name: "تعديل",
      },
      {
        id: 14,
        name: "مسح",
      },
    ],
  },

  {
    title: "الاعدادات - المناطق",
    id: 7,
    permissions: [
      {
        id: 15,
        name: "رؤية",
      },
      {
        id: 16,
        name: "اضافة",
      },
      {
        id: 17,
        name: "تعديل",
      },
      {
        id: 18,
        name: "مسح",
      },
    ],
  },

  {
    title: "الاعدادات - نطاقات الطلاب",
    id: 9,
    permissions: [
      {
        id: 19,
        name: "رؤية",
      },
      {
        id: 20,
        name: "اضافة",
      },
      {
        id: 21,
        name: "تعديل",
      },
      {
        id: 22,
        name: "مسح",
      },
    ],
  },

  {
    title: "الباقات",
    id: 10,
    permissions: [
      {
        id: 35,
        name: "رؤية",
      },
      {
        id: 36,
        name: "اضافة",
      },
      {
        id: 37,
        name: "تعديل",
      },
      {
        id: 38,
        name: "مسح",
      },
    ],
  },

  {
    title: "الباقات - اكواد الخصم",
    id: 11,
    permissions: [
      {
        id: 39,
        name: "رؤية",
      },
      {
        id: 40,
        name: "اضافة",
      },
      {
        id: 41,
        name: "تعديل",
      },
      {
        id: 42,
        name: "مسح",
      },
    ],
  },

  {
    title: "الاقسام الرئيسية",
    id: 12,
    permissions: [
      {
        id: 43,
        name: "رؤية",
      },
      {
        id: 44,
        name: "اضافة",
      },
      {
        id: 45,
        name: "تعديل",
      },
      {
        id: 46,
        name: "مسح",
      },
    ],
  },

  {
    title: "الاقسام الفرعية",
    id: 13,
    permissions: [
      {
        id: 47,
        name: "رؤية",
      },
      {
        id: 48,
        name: "اضافة",
      },
      {
        id: 49,
        name: "تعديل",
      },
      {
        id: 50,
        name: "مسح",
      },
    ],
  },

  {
    title: "الاسئلة",
    id: 14,
    permissions: [
      {
        id: 51,
        name: "رؤية",
      },
      {
        id: 52,
        name: "اضافة",
      },
      {
        id: 53,
        name: "تعديل",
      },
      {
        id: 54,
        name: "مسح",
      },
    ],
  },

  {
    title: "الطلاب المسجلين",
    id: 15,
    permissions: [
      {
        id: 59,
        name: "رؤية",
      },
      {
        id: 60,
        name: "اضافة",
      },
      {
        id: 61,
        name: "تعديل",
      },
      {
        id: 62,
        name: "مسح",
      },
    ],
  },

  {
    title: "المدربين المسجلين",
    id: 16,
    permissions: [
      {
        id: 55,
        name: "رؤية",
      },
      {
        id: 56,
        name: "اضافة",
      },
      { id: 57, name: "تعديل" },
      {
        id: 58,
        name: "مسح",
      },
    ],
  },

  {
    title: "البطولات",
    id: 17,
    permissions: [
      {
        id: 63,
        name: "رؤية",
        guard_name: "web",
      },
      {
        id: 64,
        name: "اضافة",
      },
      {
        id: 65,
        name: "تعديل",
      },
      {
        id: 66,
        name: "مسح",
      },
    ],
  },

  {
    title: "الاشعارات",
    id: 18,
    permissions: [
      {
        id: 67,
        name: "رؤية",
      },
      {
        id: 68,
        name: "اضافة",
      },
      {
        id: 69,
        name: "تعديل",
      },
      {
        id: 70,
        name: "مسح",
      },
    ],
  },

  {
    title: "الدعم الفني - اسئلة الطلاب و المدربين",
    id: 20,
    permissions: [
      {
        id: 71,
        name: "رؤية",
      },
      {
        id: 72,
        name: "اضافة",
      },
      {
        id: 73,
        name: "تعديل",
      },
      {
        id: 74,
        name: "مسح",
      },
    ],
  },

  {
    title: "الدعم الفني - رسائل الطلاب و المدربين",
    id: 21,
    permissions: [
      {
        id: 75,
        name: "رؤية",
      },
      {
        id: 76,
        name: "اضافة",
      },
      {
        id: 77,
        name: "تعديل",
      },
      {
        id: 78,
        name: "مسح",
      },
    ],
  },

  {
    title: "الدعم الفني - المحادثات",
    id: 21,
    permissions: [
      {
        id: 79,
        name: "رؤية",
      },
      {
        id: 80,
        name: "اضافة",
      },
      {
        id: 81,
        name: "تعديل",
      },
      {
        id: 82,
        name: "مسح",
      },
    ],
  },

  {
    title: "تواصل معنا ",
    id: 22,
    permissions: [
      {
        id: 83,
        name: "رؤية",
      },
      {
        id: 84,
        name: "اضافة",
      },
      {
        id: 85,
        name: "تعديل",
      },
      {
        id: 86,
        name: "مسح",
      },
    ],
  },
];

export const daysArray = [
  { value: "01", label: "1" },
  { value: "02", label: "2" },
  { value: "03", label: "3" },
  { value: "04", label: "4" },
  { value: "05", label: "5" },
  { value: "06", label: "6" },
  { value: "07", label: "7" },
  { value: "08", label: "8" },
  { value: "09", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
];

export const monthsArray = [
  { value: "01", label: "1" },
  { value: "02", label: "2" },
  { value: "03", label: "3" },
  { value: "04", label: "4" },
  { value: "05", label: "5" },
  { value: "06", label: "6" },
  { value: "07", label: "7" },
  { value: "08", label: "8" },
  { value: "09", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

export const yearsArray = [
  { label: "2024" },
  { label: "2025" },
  { label: "2026" },
  { label: "2027" },
  { label: "2028" },
  { label: "2029" },
  { label: "2030" },
  { label: "2031" },
  { label: "2032" },
  { label: "2033" },
  { label: "2034" },
  { label: "2035" },
  { label: "2036" },
  { label: "2037" },
  { label: "2038" },
  { label: "2039" },
  { label: "2040" },
  { label: "2041" },
  { label: "2042" },
  { label: "2043" },
  { label: "2044" },
  { label: "2045" },
  { label: "2046" },
  { label: "2047" },
  { label: "2048" },
  { label: "2049" },
  { label: "2050" },
];
