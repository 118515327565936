import "./CreateEditCity.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Button from "../../../../Components/Button/Button";
import newRequest from "../../../../Utils/newRequest";
import { message } from "antd";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import Loader from "../../../../Components/Loader/Loader";

const CreateEditCity = () => {
  const location = useLocation();
  const city = location.state;
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();
  const isCreate = location.pathname.includes("create");
  const [newCity, setNewCity] = useState({
    name: {
      ar: "",
      en: "",
    },
    country_id: "",
  });
  const [editCity, setEditCity] = useState({
    name: {
      ar: city?.name || "",
    },
    country_id: city?.country?.id,
  });

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get("/countries");
      setCountries(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (isCreate) {
      try {
        await newRequest.post(`/cities`, { ...newCity });
        message.success(
          isCreate ? "تم اضافة المحافظة بنجاح" : "تم تعديل المحافظة بنجاح"
        );
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setNewCity({
          name: {
            ar: "",
            en: "",
          },
          country_id: "",
        });
        setLoading(false);
      }
    } else {
      try {
        await newRequest.put(`/cities/${city?.id}`, { ...editCity });
        message.success(
          isCreate ? "تم اضافة المحافظة بنجاح" : "تم تعديل المحافظة بنجاح"
        );
        navigate(`/cities`);
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    const [property, language] = name.split(".");
    if (isCreate) {
      setNewCity((prevState) => ({
        ...prevState,
        [property]: {
          ...prevState[property],
          [language]: value,
        },
      }));
    } else {
      setEditCity((prevState) => ({
        ...prevState,
        [property]: {
          ...prevState[property],
          [language]: value,
        },
      }));
    }
  };

  const handleSelect = (e) => {
    const { value, name } = e.target;
    if (isCreate) {
      setNewCity((prev) => {
        return { ...prev, [name]: value };
      });
    } else {
      setEditCity((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };

  return (
    <div className="create-profile-page">
      <div className="container">
        <div className="header">
          <h3 className="title">
            {isCreate ? "اضافة ( محافظة )" : "تعديل ( محافظة )"}
          </h3>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الدولة</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  className="select"
                  name="country_id"
                  onChange={handleSelect}
                  value={isCreate ? newCity?.country_id : editCity?.country_id}
                >
                  {countries.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الاسم بالعربية</label>
                <TextField
                  fullWidth
                  className="form-input"
                  required
                  name="name.ar"
                  onChange={handleChange}
                  value={isCreate ? newCity?.name?.ar : editCity?.name?.ar}
                />
              </FormControl>
              {isCreate && (
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">الاسم بالانجليزية</label>
                  <TextField
                    fullWidth
                    className="form-input"
                    required
                    name="name.en"
                    onChange={handleChange}
                  />
                </FormControl>
              )}
            </div>

            {/* <div className="control-group">
            <FormControl sx={{ m: 1, flex: 1 }} className="select">
              <label className="form-label">المنطقة</label>
              <Select
                placeholder="ادخل العنوان"
                fullWidth
                className="form-input"
                required
                // onChange={(e) => setAnswer(e.target.value)}
              />
            </FormControl>
            <FormControl
              sx={{ m: 1, flex: 1 }}
              className="select"
            ></FormControl>
          </div>

          <div className="control-group">
            <FormControl sx={{ m: 1, flex: 1 }} className="select">
              <label className="form-label">Country</label>
              <Select
                fullWidth
                className="form-input"
                required
                // onChange={(e) => setAnswer(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ m: 1, flex: 1 }} className="select">
              <label className="form-label">City</label>
              <Select
                fullWidth
                className="form-input"
                required
                // onChange={(e) => setAnswer(e.target.value)}
              />
            </FormControl>
          </div>

          <div className="control-group">
            <FormControl sx={{ m: 1, flex: 1 }} className="select">
              <label className="form-label">Regoin</label>
              <Select
                fullWidth
                className="form-input"
                required
                // onChange={(e) => setAnswer(e.target.value)}
              />
            </FormControl>
            <FormControl
              sx={{ m: 1, flex: 1 }}
              className="select"
            ></FormControl>
          </div> */}

            <div className="action-control">
              <Button
                text={isCreate ? "اضافة" : "تعديل"}
                variant={"fill"}
                size={"small"}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateEditCity;
