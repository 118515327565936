import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Autocomplete,
  Avatar,
  Box,
  IconButton,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  Search as SearchIcon,
  LockClockOutlined as LastLoginIcon,
  Logout,
} from "@mui/icons-material";
import NotificationIcon from "@mui/icons-material/Notifications";

import "./Navbar.scss";
import Logo from "./../../Assets/Logo.svg";
import { NavbarMenuItems, searchOptions } from "../../SourceData";
import { logout } from "../../Redux/userSlice";
import newRequest from "../../Utils/newRequest";
import RealChatModal from "../Modals/RealChatModal/RealChatModal";

const Navbar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.currentUser);

  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isNotificationsPanelOpen, setIsNotificationsPanelOpen] =
    useState(false);

  const [selectedNotifications, setSelectedNotifications] = useState({});
  const [open, setOpen] = useState(false);

  const unreadCount = notifications?.filter(
    (notification) => notification.read_at === null
  ).length;

  const currentPath = location.pathname;
  const isCurrentPatch = NavbarMenuItems.find(
    (item) => item.path === currentPath
  );

  const notificationsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target)
      ) {
        setIsNotificationsPanelOpen(false);
      }
    };

    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setIsNotificationsPanelOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);
    fetchNotifications();

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const fetchNotifications = async () => {
    try {
      const res = await newRequest.get("/notifications");
      setNotifications(res?.data?.data);
    } catch (error) {
      console.error("Failed to fetch notifications:", error);
    }
  };

  const markAsRead = async (id) => {
    try {
      await newRequest.get(`/notifications/${id}`);
      fetchNotifications();
    } catch (error) {
      console.error("Failed to Mark as Read notifications:", error);
    }
  };

  const deleteNotification = async (id) => {
    try {
      await newRequest.delete(`/notifications/${id}`);
      fetchNotifications();
    } catch (error) {
      console.error("Failed to Delete notifications:", error);
    }
  };

  const handleNotificationClick = (data) => {
    if (data.model === "message") {
      setIsNotificationsPanelOpen(false);
      setOpen(true);
      setSelectedNotifications({ id: data?.support_chat_id });
    } else if (data.model === "contact") {
      setIsNotificationsPanelOpen(false);
      navigate(`/contact`);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
    localStorage.clear();
  };

  const handleOptionChange = (event, newValue) => {
    const selectedOption = searchOptions.find(
      (option) => option.label === newValue
    );
    if (selectedOption?.path) {
      navigate(selectedOption.path);
    }
  };

  const handleMenuClick = (setter) => (event) => {
    setter(event.currentTarget);
  };

  const handleMenuClose = (setter) => () => {
    setter(null);
  };

  const renderUserMenu = () => (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={Boolean(anchorEl)}
      onClose={handleMenuClose(setAnchorEl)}
      onClick={handleMenuClose(setAnchorEl)}
      disableScrollLock
      PaperProps={{
        elevation: 0,
        sx: {
          width: "220px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem
        onClick={() => navigate("/profile")}
        sx={{ gap: "10px", paddingRight: "0px" }}
      >
        <Avatar src={currentUser?.logo} alt="User Avatar" /> الصفحة الشخصية
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <LastLoginIcon fontSize="small" />
        </ListItemIcon>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <span>اخر تسجيل دخول</span>
          <span>{moment(currentUser?.last_login_at).format("YYYY/MM/DD")}</span>
        </div>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        تسجيل الخروج
      </MenuItem>
    </Menu>
  );

  const renderNotification = (notification) => {
    const { id, data, read_at, created_at } = notification;
    return (
      <div
        className={read_at ? "notification " : "notification unread"}
        key={id}
      >
        <div className="notification-header">
          <h4>
            {data?.model === "message"
              ? "رسالة جديدة"
              : data?.model === "contact"
              ? "تواصل معنا"
              : `${data?.model}`}
          </h4>
          <div className="header-wrapper">
            <p onClick={() => deleteNotification(id)}>حذف</p>
            {!read_at && <p onClick={() => markAsRead(id)}>تحديد كمقروءة</p>}
          </div>
        </div>
        <div
          className="notification-body"
          onClick={() => {
            handleNotificationClick(data);
            markAsRead(id);
          }}
        >
          {data?.message}
        </div>
        <div className="notification-footer">
          {moment(created_at).format("YYYY-MM-DD")}
        </div>
        {/* <h5>{noti.data.message}</h5> */}
      </div>
    );
  };

  return (
    <div className="navbar">
      <div className="navbar-container">
        <div className="right">
          <div className="logo-container">
            <img src={Logo} alt="" />
          </div>
          <h3 className="page">{isCurrentPatch?.label}</h3>
        </div>

        <div className="center">
          <Autocomplete
            sx={{ width: "100%" }}
            freeSolo
            disableClearable
            options={searchOptions.map((option) => option.label)}
            onChange={handleOptionChange}
            renderInput={(params) => (
              <TextField
                sx={{ height: "40px" }}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>

        <div className="left">
          <div className="notifications" ref={notificationsRef}>
            <div
              className="notifications-icon"
              onClick={() =>
                setIsNotificationsPanelOpen(!isNotificationsPanelOpen)
              }
            >
              <NotificationIcon className="icon" />
              <span className="notifications-numbers">{unreadCount}</span>
            </div>

            {isNotificationsPanelOpen && (
              <div className="notifications-panel">
                <h1>الاشعارات</h1>
                {notifications.length > 0 ? (
                  notifications.map(renderNotification)
                ) : (
                  <p>لا يوجد اشعارات</p>
                )}
              </div>
            )}
          </div>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleMenuClick(setAnchorEl)}
                size="small"
                sx={{ ml: 2 }}
              >
                <Avatar
                  sx={{ width: 40, height: 40 }}
                  src={currentUser?.logo}
                  alt="User Avatar"
                />
              </IconButton>
            </Tooltip>
          </Box>

          {renderUserMenu()}
        </div>
      </div>

      <RealChatModal
        open={open}
        onClose={() => setOpen(false)}
        data={selectedNotifications}
      />
    </div>
  );
};

export default Navbar;
