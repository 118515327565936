import "./Packages.scss";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PackagesColumns } from "../../SourceData";
import { showErrorMessage } from "../../Utils/showErrorMessage";
import { Modal, useMediaQuery } from "@mui/material";
import { message } from "antd";
import newRequest from "../../Utils/newRequest";
import DeleteIcon from "./../../Assets/delete-icon.svg";
import CloseIcon from "./../../Assets/close-icon.svg";
import AttentionIcon from "./../../Assets/attention-icon.svg";
import EditIcon from "./../../Assets/edit-icon.svg";
import AddIcon from "@mui/icons-material/AddOutlined";
import FilterIcon from "./../../Assets/filter-icon.svg";
import SearchIcon from "@mui/icons-material/Search";
import Button from "../../Components/Button/Button";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import Loader from "../../Components/Loader/Loader";

const Packages = () => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [rowId, setRowId] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: false,
    quickFilterValues: [],
  });
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const isTrainer = location?.pathname?.includes("/trainer");
  const gridToolbarRef = useRef(null);

  useEffect(() => {
    getPackages();
  }, [isTrainer]);

  const getPackages = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(
        `/packages?type=${isTrainer ? "trainer" : "student"}`
      );
      setPackages(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMultiDelete = async () => {
    if (selectedRowsIds.length === 0) {
      return message.warning("من فضك قم بتحديد الصفوف التي تريد حذفها اولاً");
    }
    setLoading(true);
    try {
      for (const rowId of selectedRowsIds) {
        await newRequest.delete(`/packages/${rowId}`);
      }
      await getPackages();
      setSelectedRowsIds([]);
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenMessage(false);
    setLoading(true);
    try {
      await newRequest.delete(`/packages/${rowId}`);
      await getPackages();
      setRowId("");
      message.success("تم حذف الباقة بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleActive = async (id) => {
    setLoading(true);
    try {
      await newRequest.put(`/packages/${id}/activateToggle`);
      await getPackages();
      message.success("تم التعديل بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setOpenMessage(false);
    }
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    setFilterModel((prevModel) => ({
      ...prevModel,
      quickFilterValues: [event.target.value],
    }));
  };

  const handleFilterClickd = () => {
    if (gridToolbarRef.current) {
      gridToolbarRef.current.click();
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton ref={gridToolbarRef} />
      </GridToolbarContainer>
    );
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 280,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="cell-action" style={{ display: "flex" }}>
            {/* Edit Action */}
            <div
              className="cell-btn"
              onClick={() => {
                navigate(
                  isTrainer
                    ? "/packages/trainer/edit"
                    : "/packages/student/edit",
                  { state: params?.row }
                );
              }}
            >
              <img src={EditIcon} alt="" />
            </div>
            {/* Delete Action */}
            <div
              className="cell-btn"
              onClick={() => {
                setOpenMessage(true);
                setRowId(params?.row?.id);
              }}
            >
              <img src={DeleteIcon} alt="" />
            </div>

            <div
              className="cell-btn "
              onClick={() => handleActive(params?.row?.id)}
            >
              <Button
                text={params.row.active === 1 ? "ايقاف" : "تفعيل"}
                size={"small"}
                variant={params.row.active === 1 ? "fill" : "outlined"}
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="packages">
      <div className="container">
        <div className="spcial-header">
          <h4 className="title">
            {isTrainer ? "باقات المدرب" : "باقات الطالب"}
          </h4>
          <div className="search">
            <div className="search-container">
              <SearchIcon className="icon" />
              <input
                type="text"
                placeholder="البحث بالاسم"
                onChange={handleSearch}
              />
            </div>
            <div className="filter-container" onClick={handleFilterClickd}>
              <img src={FilterIcon} alt="" />
            </div>
          </div>
          <div className="actions-container">
            <Button
              text={"اضافة باقة"}
              size={isMobile ? "full" : "fit"}
              variant={"fill"}
              icon={<AddIcon />}
              path={
                isTrainer
                  ? "/packages/trainer/create"
                  : "/packages/student/create"
              }
            />

            <Button
              text={"حذف متعدد"}
              size={isMobile ? "full" : "small"}
              variant={"outlined"}
              onClick={handleMultiDelete}
            />
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="data-grid">
            <DataGrid
              sx={{ minHeight: 600 }}
              columns={PackagesColumns.concat(actionColumn)}
              rows={packages.map((row, index) => {
                return { ...row, index: index + 1 };
              })}
              params={packages}
              disableDensitySelector
              disableRowSelectionOnClick
              disableVirtualization
              slots={{
                toolbar: CustomToolbar,
              }}
              filterModel={filterModel}
              onFilterModelChange={(newModel) => setFilterModel(newModel)}
              initialState={{
                ...packages.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 50]}
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setSelectedRowsIds(newRowSelectionModel);
              }}
              rowSelectionModel={selectedRowsIds}
            />
          </div>
        )}
      </div>

      {/* Message Modal */}
      <Modal
        open={openMessage}
        onClose={() => {
          setOpenMessage(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div
            className="header"
            onClick={() => {
              setOpenMessage(false);
              setRowId("");
            }}
          >
            <img src={CloseIcon} alt="" />
          </div>
          <div className="body">
            <img src={AttentionIcon} alt="" />
            <h2>انتبة</h2>
            <h5>هل انت متاكد من حذف هذه الباقة من</h5>
            <h6>الباقات</h6>
          </div>
          <div className="footer">
            <button className="action-btn" onClick={handleDelete}>
              حذف
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Packages;
