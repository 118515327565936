import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
};

export const dashSlice = createSlice({
  name: "dash",
  initialState,
  reducers: {
    storeData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { storeData } = dashSlice.actions;

export default dashSlice.reducer;
