import "./AddExcel.scss";
import Button from "../../../Components/Button/Button";
import FileUploadIcon from "@mui/icons-material/FileUploadOutlined";
import { useEffect, useState } from "react";
import { readExcelFile } from "../../../Utils/readExcelFile";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import Loader from "../../../Components/Loader/Loader";
import UploadingIcon from "./../../../Assets/Uploading.gif";
import { message } from "antd";

const AddExcel = () => {
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const [correctQuestions, setCorrectQuestions] = useState([]);
  const [incorrectQuestions, setIncorrectQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({
    current: 0,
    total: 0,
  });

  useEffect(() => {
    getMainCategories();
    getSubCategories();
  }, []);

  const getMainCategories = async () => {
    setLoading(true);
    try {
      const subCategoriesResponce = await newRequest.get("/type_levels");
      setMainCategories(subCategoriesResponce?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  const getSubCategories = async () => {
    setLoading(true);
    try {
      const subCategoriesResponce = await newRequest.get("/level_categories");
      setSubCategories(subCategoriesResponce?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setFileSelected(false);
      return;
    }

    setFileSelected(true);
    const data = await readExcelFile(file);
    const transformedObjs = data?.map(transformObject) || [];

    const { result: firstResult, notMatch: firstNotMatch } =
      transformedObjs.reduce(reduceToFirstResult, { result: [], notMatch: [] });

    const { result: secondResult, notMatch: secondNotMatch } =
      firstResult.reduce(reduceToSecondResult, { result: [], notMatch: [] });

    const finalNotMatch = [...firstNotMatch, ...secondNotMatch];

    setExcelData(data);
    setCorrectQuestions(secondResult);
    setIncorrectQuestions(finalNotMatch);
  };

  const transformObject = (obj) => {
    const {
      التسلسل: questionNumber,
      الاجابة: answer,
      "القسم الرئيسي/المستوي": mainCategoryTitle,
      "القسم الفرعي": subCategory,
      "عدد الارقام": numbers_count,
      "الاختيار الاول": choice1,
      "الاختيار الثاني": choice2,
      "الرقم الاول": number1,
      "الرقم الثاني": number2,
      "الرقم الثالث": number3,
      "الرقم الرابع": number4,
      "الرقم الخامس": number5,
      "الرقم السادس": number6,
      "الرقم السابع": number7,
      "الرقم الثامن": number8,
      "الرقم التاسع": number9,
      "الرقم العاشر": number10,
    } = obj;

    return {
      questionNumber,
      mainCategoryTitle,
      subCategory,
      numbers_count,
      answer,
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      number7,
      number8,
      number9,
      number10,
      choice1,
      choice2,
    };
  };

  const reduceToFirstResult = (acc, curr) => {
    const match = mainCategories.find(
      (item) => item.title === curr.mainCategoryTitle
    );
    let result = acc.result;
    let notMatch = acc.notMatch;

    if (match) {
      const { training_type_id, id: type_level_id } = match;
      const {
        subCategory,
        numbers_count,
        answer,
        choice1,
        choice2,
        number1,
        number2,
        number3,
        number4,
        number5,
        number6,
        number7,
        number8,
        number9,
        number10,
        questionNumber,
      } = curr;

      result.push({
        training_type_id,
        type_level_id,
        subCategory,
        numbers_count,
        answer,
        choice1,
        choice2,
        number1,
        number2,
        number3,
        number4,
        number5,
        number6,
        number7,
        number8,
        number9,
        number10,
        questionNumber,
      });
    } else {
      notMatch.push({
        questionNumber: curr.questionNumber,
        reason: "لا يوجد قسم رئيسي او مستوي بهذا الاسم",
      });
    }

    return { result, notMatch };
  };

  // const reduceToSecondResult = (acc, curr) => {
  //   const {
  //     type_level_id,
  //     training_type_id,
  //     subCategory,
  //     numbers_count,
  //     answer,
  //     questionNumber,
  //     number1,
  //     number2,
  //     number3,
  //     number4,
  //     number5,
  //     number6,
  //     number7,
  //     number8,
  //     number9,
  //     number10,
  //     choice1,
  //     choice2,
  //   } = curr;

  //   const match = subCategories.find(
  //     (item) =>
  //       item.title === subCategory && item.type_level?.id === type_level_id
  //   );

  //   let result = acc.result;
  //   let notMatch = acc.notMatch;

  //   if (match) {
  //     result.push({
  //       training_type_id,
  //       type_level_id,
  //       level_category_id: match.id,
  //       numbers_count,
  //       answer,
  //       number1,
  //       number2,
  //       number3,
  //       number4,
  //       number5,
  //       number6,
  //       number7,
  //       number8,
  //       number9,
  //       number10,
  //       choice1,
  //       choice2,
  //     });
  //   } else {
  //     notMatch.push({
  //       questionNumber,
  //       reason: "لا يوجد مستوي فرعي بهذا الاسم",
  //     });
  //   }
  //   return { result, notMatch };
  // };

  const reduceToSecondResult = (acc, curr) => {
    const {
      type_level_id,
      training_type_id,
      subCategory,
      numbers_count,
      answer,
      questionNumber,
      number1,
      number2,
      number3,
      number4,
      number5,
      number6,
      number7,
      number8,
      number9,
      number10,
      choice1,
      choice2,
    } = curr;

    const match = subCategories.find(
      (item) =>
        item.title === subCategory && item.type_level?.id === type_level_id
    );

    let result = acc.result;
    let notMatch = acc.notMatch;

    if (match) {
      // Validate number fields
      const numberFields = [
        number1,
        number2,
        number3,
        number4,
        number5,
        number6,
        number7,
        number8,
        number9,
        number10,
      ];
      const hasEmptyRequiredField = numberFields.some((field, index) => {
        const isRequired = index < parseInt(numbers_count, 10);
        return (
          isRequired && (field === undefined || field === "" || field === null)
        );
      });

      if (hasEmptyRequiredField) {
        notMatch.push({
          questionNumber,
          reason: "بعض الحقول الرقمية المطلوبة فارغة",
        });
      } else {
        result.push({
          training_type_id,
          type_level_id,
          level_category_id: match.id,
          numbers_count,
          answer,
          number1,
          number2,
          number3,
          number4,
          number5,
          number6,
          number7,
          number8,
          number9,
          number10,
          choice1,
          choice2,
        });
      }
    } else {
      notMatch.push({
        questionNumber,
        reason: "لا يوجد مستوي فرعي بهذا الاسم",
      });
    }
    return { result, notMatch };
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    setUploadProgress({ current: 0, total: correctQuestions.length });

    try {
      const failedQuestions = [];

      for (let i = 0; i < correctQuestions.length; i++) {
        const question = correctQuestions[i];

        try {
          await newRequest.post("/questions", question);
          setUploadProgress((prev) => ({ ...prev, current: i + 1 }));

          // Add a delay of 1 second between requests
          await delay(1000);
        } catch (error) {
          failedQuestions.push(question);
          console.error(`Error uploading question: ${error}`);

          // If we get a "Too Many Attempts" error, wait for a longer time
          if (error.response && error.response.status === 429) {
            await delay(5000); // Wait for 5 seconds before trying the next question
          }
        }
      }

      setCorrectQuestions([]);
      setIncorrectQuestions([]);
      setIsUploading(false);

      if (failedQuestions.length > 0) {
        message.warning(
          `تم رفع ${uploadProgress.current} من ${uploadProgress.total} سؤال. فشل رفع ${failedQuestions.length} سؤال.`
        );
      } else {
        message.success(`تم رفع جميع الأسئلة بنجاح`);
      }
    } catch (error) {
      setIsUploading(false);
      showErrorMessage(error);
    }
  };

  return (
    <div className="add-excel">
      <div className="container">
        <h4 className="title">اضافه ملف اكسيل بالاسئلة</h4>
        {isUploading ? (
          <div className="uploading">
            <img src={UploadingIcon} alt="" />
            <p>جاري الرفع برجاء الانتظار وعدم مغادرة الصفحة...</p>

            <p>
              تم رفع {uploadProgress.current} من {uploadProgress.total}
            </p>
          </div>
        ) : (
          <>
            {loading ? (
              <Loader />
            ) : (
              <form action="">
                <div className="form-control">
                  <label htmlFor="file">
                    <FileUploadIcon className="icon" /> ارفاق شيت الاكسل
                  </label>
                  <input
                    type="file"
                    id="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileUpload}
                  />
                </div>
                <div onClick={handleSubmit}>
                  {fileSelected && correctQuestions?.length !== 0 && (
                    <Button text="رفع" size="large" variant="fill" />
                  )}
                </div>
              </form>
            )}
            {incorrectQuestions?.length > 0 && (
              <div className="incorrect-questions">
                <h5>ملاحظة</h5>
                {incorrectQuestions?.map((item, index) => (
                  <div className="incorrect-item" key={index}>
                    <p>
                      - لن يتم رفع السؤال رقم{" "}
                      <strong>{item?.questionNumber}</strong> برجاء مراجعة الشيت
                      جيداً , <strong>السبب</strong> {item?.reason}
                    </p>
                  </div>
                ))}
                <h3>او قم بالضغط علي رفع لرفع الاسئلة الصحيحة فقط</h3>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AddExcel;
