import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  regions: [],
};

export const regionSlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    storeRegions: (state, action) => {
      state.regions = action.payload;
    },
  },
});

export const { storeRegions } = regionSlice.actions;

export default regionSlice.reducer;
