import "./MostStudents.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { MostStudentsColumns } from "../../../SourceData";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import FilterIcon from "./../../../Assets/filter-icon.svg";
import CloseIcon from "./../../../Assets/close-icon.svg";
import newRequest from "../../../Utils/newRequest";
import Loader from "../../../Components/Loader/Loader";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import moment from "moment";

const years = [];
const currentYear = new Date().getFullYear();

for (let i = 0; i < 50; i++) {
  years.push({ id: i + 1, year: currentYear + i });
}

const arabicMonths = [
  { id: "January", name: "يناير" },
  { id: "February", name: "فبراير" },
  { id: "March", name: "مارس" },
  { id: "April", name: "أبريل" },
  { id: "May", name: "مايو" },
  { id: "June", name: "يونيو" },
  { id: "July", name: "يوليو" },
  { id: "August", name: "أغسطس" },
  { id: "September", name: "سبتمبر" },
  { id: "October", name: "أكتوبر" },
  { id: "November", name: "نوفمبر" },
  { id: "December", name: "ديسمبر" },
];

const MostStudents = () => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [mostStudents, setMostStudents] = useState([]);
  const [filterdStudents, setFilterdStudents] = useState([]);
  const [search, setSearch] = useState(false);
  const [selectedDate, setSelectedDate] = useState({
    year: "",
    month: "",
  });

  useEffect(() => {
    getLastChampionshipWinners();
  }, []);

  const mostStudentsDate = mostStudents.map((item) => {
    return { ...item, created_at: moment(item.created_at).format("YYYY-MMMM") };
  });

  const filterData = () => {
    const filteredData = mostStudentsDate.filter((item) => {
      const [year, month] = item.created_at.split("-");
      return (
        year === String(selectedDate.year) &&
        month.toLowerCase() === selectedDate.month.id.toLowerCase()
      );
    });
    setFilterdStudents(filteredData);
  };

  const getLastChampionshipWinners = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get("/most_students");
      setMostStudents(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="most-students">
        <div className="container">
          <div className="header">
            <h3>الطلاب الاكثر استخدام للتطبيق</h3>
            <div className="filter-container">
              {search && (
                <>
                  <span
                    className="cancel"
                    onClick={() => {
                      setFilterdStudents([]);
                      setSearch(false);
                    }}
                  >
                    الغاء
                  </span>
                  <span className="date">
                    {selectedDate?.month.name} - {selectedDate?.year}
                  </span>
                </>
              )}
              <img src={FilterIcon} alt="" onClick={() => setOpenModal(true)} />
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <DataGrid
              sx={{ minHeight: 600 }}
              columns={MostStudentsColumns}
              rows={
                filterdStudents.length > 0
                  ? filterdStudents.map((row, index) => {
                      return { ...row, index: index + 1 };
                    })
                  : mostStudents.map((row, index) => {
                      return { ...row, index: index + 1 };
                    })
              }
              disableDensitySelector
              disableVirtualization
              initialState={{
                ...mostStudents.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 50]}
              checkboxSelection
            />
          )}
        </div>
      </div>

      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="filter-modal"
      >
        <div className="container">
          <div className="header">
            <div className="filter-container">
              <img
                src={FilterIcon}
                alt=""
                onClick={() => {
                  setOpenModal(true);
                }}
              />
              <h3>فلترة في الطلاب</h3>
            </div>
            <img
              src={CloseIcon}
              alt=""
              onClick={() => {
                setOpenModal(false);
              }}
            />
          </div>

          <div className="body">
            <FormControl sx={{ m: 1, flex: 1 }} className="select">
              <InputLabel id="demo-simple-select-label">تحديد الشهر</InputLabel>
              <Select
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                required
                className="select"
                name="month"
                onChange={(e) =>
                  setSelectedDate((prev) => {
                    return { ...prev, [e.target.name]: e.target.value };
                  })
                }
              >
                {arabicMonths.map((item) => (
                  <MenuItem value={item} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, flex: 1 }} className="select">
              <InputLabel id="demo-simple-select-label">تحديد السنة</InputLabel>
              <Select
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                required
                className="select"
                name="year"
                onChange={(e) =>
                  setSelectedDate((prev) => {
                    return { ...prev, [e.target.name]: e.target.value };
                  })
                }
              >
                {years.map((item) => (
                  <MenuItem value={item.year} key={item.id}>
                    {item.year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="footer">
            <button
              className="action-btn"
              onClick={() => {
                setSearch(true);
                filterData();
                setOpenModal(false);
              }}
            >
              بحث
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MostStudents;
