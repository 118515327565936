import "./Admins.scss";
import Button from "../../Components/Button/Button";
import EditIcon from "./../../Assets/edit-icon.svg";
import DeleteIcon from "./../../Assets/delete-icon.svg";
import CloseIcon from "./../../Assets/close-icon.svg";
import AttentionIcon from "./../../Assets/attention-icon.svg";
import AddIcon from "@mui/icons-material/AddOutlined";
import SearchIcon from "@mui/icons-material/Search";
import FilterIcon from "./../../Assets/filter-icon.svg";
import {
  AdminsColumns,
  daysArray,
  monthsArray,
  yearsArray,
} from "../../SourceData";
import { DataGrid } from "@mui/x-data-grid";
import { Modal, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import newRequest from "../../Utils/newRequest";
import { showErrorMessage } from "../../Utils/showErrorMessage";
import Loader from "../../Components/Loader/Loader";
import { message } from "antd";
import moment from "moment";

const Admins = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const navigate = useNavigate();
  const [openMessage, setOpenMessage] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: false,
    quickFilterValues: [],
  });
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [filterdAdmins, setFilterdAdmins] = useState([]);
  const [searchFilter, setSearchFilter] = useState(false);
  const [fromDate, setFromDate] = useState({ day: "", month: "", year: "" });
  const [toDate, setToDate] = useState({ day: "", month: "", year: "" });
  const startDate = `${fromDate.year}-${fromDate.month}-${fromDate.day}`;
  const endDate = `${toDate.year}-${toDate.month}-${toDate.day}`;

  useEffect(() => {
    getAdmins();
  }, []);

  const getAdmins = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get("/users?role=sub_admin");
      setAdmins(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMultiDelete = async () => {
    if (selectedRowsIds.length === 0) {
      return message.info("من فضك قم بتحديد الصفوف التي تريد حذفها اولاً");
    }
    setLoading(true);
    setOpenMessage(false);
    try {
      for (const rowId of selectedRowsIds) {
        await newRequest.delete(`/users/${rowId}`);
      }
      await getAdmins();
      setSelectedRowsIds([]);
      message.success("تم حذف المسؤلين بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleActive = async (id) => {
    setLoading(true);
    try {
      await newRequest.put(`/users/${id}/activateToggle`);
      await getAdmins();
      message.success("تم التعديل بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setOpenMessage(false);
    }
  };

  const handleDateChange = (event, dateType) => {
    const { name, value } = event.target;
    if (dateType === "from") {
      setFromDate((prevDate) => ({ ...prevDate, [name]: value }));
    } else {
      setToDate((prevDate) => ({ ...prevDate, [name]: value }));
    }
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setFilterModel((prevModel) => ({
      ...prevModel,
      quickFilterValues: [event.target.value],
    }));
  };

  const filterDataByDateRange = (fromDate, toDate) => {
    const endOfDay = moment(toDate).format("YYYY-MM-DD");
    return admins.filter((item) => {
      const itemDate = moment(item.created_at).format("YYYY-MM-DD");
      return (
        itemDate >= moment(fromDate).format("YYYY-MM-DD") &&
        itemDate <= endOfDay
      );
    });
  };

  const handleFilter = () => {
    if (!startDate || !endDate) {
      message.warning("برجاء قم باختيار التاريخ بشكل صحيح");
      return;
    }
    const result = filterDataByDateRange(startDate, endDate);
    if (result.length > 0) {
      setFilterdAdmins(result);
      setSearchFilter(false);
    } else {
      message.warning("لا يوجد نتائج لهذا التاريخ");
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 280,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="cell-action" style={{ display: "flex" }}>
            <div
              className="cell-btn"
              onClick={() => navigate("/admins/edit", { state: params?.row })}
            >
              <img src={EditIcon} alt="" />
            </div>
            <div
              className="cell-btn"
              onClick={() => {
                setOpenMessage(true);
                setSelectedRowsIds((prevIds) => [...prevIds, params.row.id]);
              }}
            >
              <img src={DeleteIcon} alt="" />
            </div>
            <Button
              text={params?.row?.active === 1 ? "ايقاف" : "تفعيل"}
              size={"small"}
              variant={params?.row?.active === 1 ? "fill" : "outlined"}
              onClick={() => handleActive(params.row.id)}
            />
          </div>
        );
      },
    },
  ];

  const renderDateSelect = (name, value, onChange, options, placeholder) => (
    <select
      name={name}
      className="select-date"
      value={value}
      onChange={onChange}
    >
      <option value="" disabled>
        {placeholder}
      </option>
      {options.map((option) => (
        <option key={option.label} value={option.value || option.label}>
          {option.label}
        </option>
      ))}
    </select>
  );

  return (
    <div className="admins">
      <div className="container">
        {/* Header Section */}
        <div className="spcial-header">
          <h4 className="title">المسؤلين الفرعيين</h4>
          <div className="search">
            <div className="search-container">
              <SearchIcon className="icon" />
              <input
                type="text"
                placeholder="البحث بالاسم"
                onChange={handleSearchChange}
              />
            </div>
            <div className="filter-container">
              <img
                src={FilterIcon}
                alt=""
                onClick={() => setSearchFilter(true)}
              />
              {filterdAdmins.length > 0 && (
                <span className="cancel" onClick={() => setFilterdAdmins([])}>
                  الغاء
                </span>
              )}
            </div>
          </div>
          <div className="actions-container">
            <Button
              text={"اضافة مسؤل"}
              size={isMobile ? "full" : "fit"}
              variant={"fill"}
              icon={<AddIcon />}
              path={"/admins/create"}
              isMobile=""
            />
            <Button
              text={"حذف متعدد"}
              size={isMobile ? "full" : "small"}
              variant={"outlined"}
              onClick={() => setOpenMessage(true)}
            />
          </div>
        </div>

        {/* Data Grid */}
        {loading ? (
          <Loader />
        ) : (
          <div className="data-grid">
            <DataGrid
              sx={{ minHeight: 600 }}
              columns={AdminsColumns.concat(actionColumn)}
              rows={(filterdAdmins.length > 0 ? filterdAdmins : admins).map(
                (row, index) => ({ ...row, index })
              )}
              disableVirtualization
              filterModel={filterModel}
              onFilterModelChange={(newModel) => setFilterModel(newModel)}
              initialState={{
                ...admins.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 50]}
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setSelectedRowsIds(newRowSelectionModel);
              }}
              rowSelectionModel={selectedRowsIds}
            />
          </div>
        )}
      </div>

      {/* Message Modal */}
      <Modal
        open={openMessage}
        onClose={() => {
          setOpenMessage(false);
          setSelectedRowsIds([]);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div
            className="header"
            onClick={() => {
              setOpenMessage(false);
              setSelectedRowsIds([]);
            }}
          >
            <img src={CloseIcon} alt="" />
          </div>
          <div className="body">
            <img src={AttentionIcon} alt="" />
            <h2>انتبة</h2>
            <h5>هل انت متاكد من حذف هذا المسئول من</h5>
            <h6>المسئولين</h6>
          </div>
          <div className="footer">
            <button className="action-btn" onClick={handleMultiDelete}>
              حذف
            </button>
          </div>
        </div>
      </Modal>

      {/* Filter Modal */}
      <Modal
        open={searchFilter}
        onClose={() => {
          setSearchFilter(false);
          setFromDate({ day: "", month: "", year: "" });
          setToDate({ day: "", month: "", year: "" });
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="filter-modal"
      >
        <div className="container">
          <div className="header">
            <img src={FilterIcon} alt="" />
            <h3>البحث في المسئولين الفرعين</h3>
          </div>

          <div className="body">
            <div className="title-container">
              <span>أخر تسجيل دخول</span>
            </div>

            <div className="date-container">
              <div className="from-date">
                {renderDateSelect(
                  "day",
                  fromDate.day,
                  (e) => handleDateChange(e, "from"),
                  daysArray,
                  "أليوم"
                )}
                {renderDateSelect(
                  "month",
                  fromDate.month,
                  (e) => handleDateChange(e, "from"),
                  monthsArray,
                  "الشهر"
                )}
                {renderDateSelect(
                  "year",
                  fromDate.year,
                  (e) => handleDateChange(e, "from"),
                  yearsArray,
                  "السنة"
                )}
              </div>
              <p>الي</p>
              <div className="from-date">
                {renderDateSelect(
                  "day",
                  toDate.day,
                  (e) => handleDateChange(e, "to"),
                  daysArray,
                  "أليوم"
                )}
                {renderDateSelect(
                  "month",
                  toDate.month,
                  (e) => handleDateChange(e, "to"),
                  monthsArray,
                  "الشهر"
                )}
                {renderDateSelect(
                  "year",
                  toDate.year,
                  (e) => handleDateChange(e, "to"),
                  yearsArray,
                  "السنة"
                )}
              </div>
            </div>
          </div>

          <div className="footer">
            <button className="action-btn" onClick={handleFilter}>
              بحث
            </button>
            <button
              className="action-btn"
              onClick={() => {
                setSearchFilter(false);
                setFromDate({ day: "", month: "", year: "" });
                setToDate({ day: "", month: "", year: "" });
              }}
            >
              الغاء
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Admins;
