import "./RealChatModal.scss";
import { Modal } from "@mui/material";
import CloseIcon from "./../../../Assets/close-icon.svg";
import PersonIcon from "./../../../Assets/person-icon.svg";
import Emoji from "./../../../Assets/smile.png";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import Loader from "../../Loader/Loader";
import moment from "moment";
import Pusher from "pusher-js";
import EmojiPicker from "emoji-picker-react";

const Message = ({ message, isOwnMessage }) => (
  <div className={isOwnMessage ? "message own" : "message"}>
    <div className="texts">
      <p>{message.message}</p>
      <span>{moment(message.created_at).format("MM-DD a hh:mm")}</span>
    </div>
  </div>
);

const usePusher = (channelName, eventName, callback) => {
  useEffect(() => {
    const pusher = new Pusher("e18c416ead10ebd4c14b", { cluster: "eu" });
    const channel = pusher.subscribe(channelName);
    channel.bind(eventName, callback);

    return () => {
      channel.unbind(eventName);
      pusher.unsubscribe(channelName);
      pusher.disconnect();
    };
  }, [channelName, eventName, callback]);
};

const RealChatModal = (props) => {
  const user = props?.data;
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const endRef = useRef(null);
  const inputRef = useRef(null);

  const handleNewMessage = useCallback(
    (data) => {
      const newMessage = data.data;
      if (newMessage.support_chat_id === user.id) {
        setMessages((prevMessages) => {
          const isMessageExist = prevMessages.some(
            (msg) => msg.id === newMessage.id
          );
          return isMessageExist ? prevMessages : [...prevMessages, newMessage];
        });
      }
    },
    [user.id]
  );

  usePusher(
    "support-message-channel",
    "support-message-event",
    handleNewMessage
  );

  useEffect(() => {
    endRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    const getChat = async () => {
      setLoading(true);
      try {
        const res = await newRequest.get(
          `/support_messages?support_chat_id=${user.id}`
        );
        setMessages(res?.data?.data);
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    };
    getChat();
  }, [user.id]);

  useEffect(() => {
    if (!props.open) {
      setText("");
    }
  }, [props.open]);

  const handleEmoji = (e) => {
    setText((prev) => prev + e.emoji);
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimmedText = text.trim();
    if (trimmedText.length > 0) {
      try {
        await newRequest.post(`/support_messages`, {
          message: trimmedText,
          support_chat_id: user.id,
        });
        setText("");
        inputRef.current.focus();
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setText("");
      }
    }
  };

  return (
    <Modal
      open={props?.open}
      onClose={props?.onClose}
      aria-labelledby="chat-modal-title"
      aria-describedby="chat-modal-description"
      className="real-chat-modal"
    >
      <Fragment>
        {loading ? (
          <Loader />
        ) : (
          <div className="chat-container">
            <div className="top">
              <div className="user">
                <img src={PersonIcon} alt="" />
                <div className="texts">
                  <span id="chat-modal-title">{user?.user?.name}</span>
                </div>
              </div>
              <img
                src={CloseIcon}
                alt="Close"
                onClick={props?.onClose}
                aria-label="Close chat"
              />
            </div>
            <div className="center" id="chat-modal-description">
              {messages?.map((message) => (
                <Message
                  key={message?.id}
                  message={message}
                  isOwnMessage={message.sender_type !== "admin"}
                />
              ))}
              <div ref={endRef}></div>
            </div>

            <form onSubmit={handleSubmit} className="bottom">
              <button type="submit" className="sendButton">
                ارسال
              </button>
              <div className="emoji">
                <button
                  type="button"
                  onClick={() => setOpen((prev) => !prev)}
                  aria-label="Open emoji picker"
                >
                  <img src={Emoji} alt="Emoji" />
                </button>
                <div className="picker">
                  <EmojiPicker open={open} onEmojiClick={handleEmoji} />
                </div>
              </div>
              <input
                type="text"
                placeholder="اكتب رسالة..."
                onChange={(e) => setText(e.target.value)}
                value={text}
                ref={inputRef}
              />
            </form>
          </div>
        )}
      </Fragment>
    </Modal>
  );
};

export default RealChatModal;

// import "./RealChatModal.scss";
// import { Modal } from "@mui/material";
// import CloseIcon from "./../../../Assets/close-icon.svg";
// import PersonIcon from "./../../../Assets/person-icon.svg";
// import Emoji from "./../../../Assets/smile.png";
// import { Fragment, useEffect, useRef, useState } from "react";
// import newRequest from "../../../Utils/newRequest";
// import { showErrorMessage } from "../../../Utils/showErrorMessage";
// import Loader from "../../Loader/Loader";
// import moment from "moment";
// import Pusher from "pusher-js";
// import EmojiPicker from "emoji-picker-react";

// const RealChatModal = (props) => {
//   const user = props?.data;
//   const [loading, setLoading] = useState(false);
//   const [initialMessages, setInitialMessages] = useState([]);
//   const [incomingMessages, setIncomingMessages] = useState([]);
//   const [open, setOpen] = useState(false);
//   const [text, setText] = useState("");
//   const endRef = useRef(null);
//   const buttonRef = useRef(null);
//   const [pusher, setPusher] = useState(null);

//   useEffect(() => {
//     const pusher = new Pusher("e18c416ead10ebd4c14b", {
//       cluster: "eu",
//     });

//     const channel = pusher.subscribe("support-message-channel");
//     channel.bind("support-message-event", (data) => {
//       const newMessage = data.data;

//       setIncomingMessages((prevMessages) => {
//         // Check if newMessage.support_chat_id matches user.id
//         if (newMessage.support_chat_id !== user.id) {
//           return prevMessages;
//         }

//         // Check if newMessage.id already exists in prevMessages
//         const isMessageExist = prevMessages.some(
//           (msg) => msg.id === newMessage.id
//         );
//         if (isMessageExist) {
//           return prevMessages;
//         }

//         // If both conditions are met, add the new message
//         return [...prevMessages, newMessage];
//       });
//     });

//     setPusher(pusher);

//     return () => {
//       pusher.unsubscribe("support-message-channel");
//       pusher.disconnect();
//     };
//   }, [user.id]);

//   useEffect(() => {
//     endRef.current?.scrollIntoView({ behavior: "smooth" });
//   }, [initialMessages, incomingMessages]);

//   useEffect(() => {
//     const getChat = async () => {
//       setLoading(true);
//       try {
//         const res = await newRequest.get(
//           `/support_messages?support_chat_id=${user.id}`
//         );
//         setInitialMessages(res?.data?.data);
//       } catch (error) {
//         showErrorMessage(error);
//       } finally {
//         setLoading(false);
//       }
//     };
//     getChat();
//   }, [user.id]);

//   const handleEmoji = (e) => {
//     setText((prev) => prev + e.emoji);
//     setOpen(false);
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === "Enter" && !e.shiftKey) {
//       e.preventDefault();
//       if (text.trim().length > 0) {
//         buttonRef.current.click();
//       }
//     }
//   };

//   const handleButtonClick = async () => {
//     const trimmedText = text.trim();
//     if (trimmedText.length > 0) {
//       try {
//         await newRequest.post(`/support_messages`, {
//           message: text,
//           support_chat_id: user.id,
//         });
//       } catch (error) {
//         showErrorMessage(error);
//       } finally {
//         setText("");
//       }
//     }
//   };

//   return (
//     <Modal
//       open={props?.open}
//       onClose={props?.onClose}
//       aria-labelledby="modal-modal-title"
//       aria-describedby="modal-modal-description"
//       className="real-chat-modal"
//     >
//       <Fragment>
//         {loading ? (
//           <Loader />
//         ) : (
//           <div className="container">
//             <div className="top">
//               <div className="user">
//                 <img src={PersonIcon} alt="" />
//                 <div className="texts">
//                   <span>{user?.user?.name}</span>
//                 </div>
//               </div>
//               <img src={CloseIcon} alt="" onClick={props?.onClose} />
//             </div>
//             <div className="center">
//               {initialMessages?.map((message) => (
//                 <div
//                   className={
//                     message.sender_type === "admin" ? "message " : "message own"
//                   }
//                   key={message?.id}
//                 >
//                   <div className="texts">
//                     <p>{message.message}</p>
//                     <span>
//                       {moment(message?.created_at).format("MM-DD a hh:mm")}
//                     </span>
//                   </div>
//                 </div>
//               ))}
//               {incomingMessages?.map((message) => (
//                 <div
//                   className={
//                     message.sender_type === "admin" ? "message " : "message own"
//                   }
//                   key={message?.id}
//                 >
//                   <div className="texts">
//                     <p>{message.message}</p>
//                     <span>
//                       {moment(message?.created_at).format("MM-DD a hh:mm")}
//                     </span>
//                   </div>
//                 </div>
//               ))}

//               <div ref={endRef}></div>
//             </div>

//             <div className="bottom">
//               <button
//                 className="sendButton"
//                 ref={buttonRef}
//                 onClick={handleButtonClick}
//               >
//                 ارسال
//               </button>
//               <div className="emoji">
//                 <img
//                   src={Emoji}
//                   alt=""
//                   onClick={() => setOpen((prev) => !prev)}
//                 />
//                 <div className="picker">
//                   <EmojiPicker open={open} onEmojiClick={handleEmoji} />
//                 </div>
//               </div>
//               <input
//                 type="text"
//                 placeholder="اكتب رسالة..."
//                 onChange={(e) => setText(e.target.value)}
//                 onKeyDown={handleKeyDown}
//                 value={text}
//               />
//             </div>
//           </div>
//         )}
//       </Fragment>
//     </Modal>
//   );
// };

// export default RealChatModal;
