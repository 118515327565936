import "./Departments.scss";
import Button from "../../Components/Button/Button";
import EditIcon from "./../../Assets/edit-icon.svg";
import DeleteIcon from "./../../Assets/delete-icon.svg";
import CloseIcon from "./../../Assets/close-icon.svg";
import AttentionIcon from "./../../Assets/attention-icon.svg";
import AddIcon from "@mui/icons-material/AddOutlined";
import { DepartmentsColumns } from "../../SourceData";
import ViewIcon from "./../../Assets/details-icon.svg";
import { DataGrid } from "@mui/x-data-grid";
import { Modal, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import newRequest from "../../Utils/newRequest";
import { showErrorMessage } from "../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../Components/Loader/Loader";
const Departments = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:767px)");
  const [departments, setDepartments] = useState([]);
  const [rowId, setRowId] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);

  useEffect(() => {
    getDepartments();
  }, []);

  const getDepartments = async () => {
    setLoading(true);
    try {
      const response = await newRequest.get("/type_levels");
      setDepartments(response?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMultiDelete = async () => {
    if (selectedRowsIds.length === 0) {
      return message.warning("من فضك قم بتحديد الصفوف التي تريد حذفها اولاً");
    }
    setLoading(true);
    try {
      for (const rowId of selectedRowsIds) {
        await newRequest.delete(`/type_levels/${rowId}`);
      }
      await getDepartments();
      setSelectedRowsIds([]);
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await newRequest.delete(`/type_levels/${rowId}`);
      await getDepartments();
      setRowId("");
      message.success("تم حذف القسم بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  const handleActive = async (id) => {
    setLoading(true);
    try {
      await newRequest.put(`/type_levels/${id}/activateToggle`);
      await getDepartments();
      message.success("تم التعديل بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 280,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="cell-action" style={{ display: "flex" }}>
            <div
              className="cell-btn"
              onClick={() => {
                navigate("/departments/view", { state: params?.row });
              }}
            >
              <img src={ViewIcon} alt="" />
            </div>
            <div
              className="cell-btn"
              onClick={() => {
                navigate("/departments/edit", { state: params?.row });
              }}
            >
              <img src={EditIcon} alt="" />
            </div>

            <div
              className="cell-btn"
              onClick={() => {
                setOpenMessage(true);
                setRowId(params?.row?.id);
              }}
            >
              <img src={DeleteIcon} alt="" />
            </div>

            <div
              className="cell-btn "
              onClick={() => handleActive(params?.row?.id)}
            >
              <Button
                text={params.row.active === 1 ? "ايقاف" : "تفعيل"}
                size={"small"}
                variant={params.row.active === 1 ? "fill" : "outlined"}
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="departments">
      <div className="container">
        <div className="spcial-header">
          <h4 className="title">الاقسام الرئيسية / المستويات</h4>
          <div className="actions-container">
            <Button
              text={"اضف قسم رئيسي / مستوي"}
              size={isMobile ? "full" : "fit"}
              variant={"fill"}
              icon={<AddIcon />}
              path={"/departments/create"}
              isMobile=""
            />

            <Button
              text={"حذف متعدد"}
              size={isMobile ? "full" : "small"}
              variant={"outlined"}
              onClick={handleMultiDelete}
            />
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="data-grid">
            <DataGrid
              sx={{ minHeight: 600 }}
              columns={DepartmentsColumns.concat(actionColumn)}
              rows={departments.map((row, index) => {
                return { ...row, index: index + 1 };
              })}
              params={departments}
              disableDensitySelector
              disableRowSelectionOnClick
              disableVirtualization
              initialState={{
                ...departments.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 50]}
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setSelectedRowsIds(newRowSelectionModel);
              }}
              rowSelectionModel={selectedRowsIds}
            />
          </div>
        )}
      </div>

      {/* Message Modal */}
      <Modal
        open={openMessage}
        onClose={() => {
          setOpenMessage(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div
            className="header"
            onClick={() => {
              setOpenMessage(false);
              setRowId("");
            }}
          >
            <img src={CloseIcon} alt="" />
          </div>
          <div className="body">
            <img src={AttentionIcon} alt="" />
            <h2>انتبة</h2>
            <h5>هل انت متاكد من حذف هذا القسم من</h5>
            <h6>الاقسام</h6>
          </div>
          <div className="footer">
            <button
              className="action-btn"
              onClick={() => {
                setOpenMessage(false);
                handleDelete();
              }}
            >
              حذف
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Departments;
