import "./StudentsTrainersMessages.scss";
import AnswerIcon from "./../../../Assets/answer-icon.svg";
import ViewIcon from "./../../../Assets/details-icon.svg";
import ChatIcon from "./../../../Assets/chat-icon.svg";
import { Fragment, useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import Button from "../../../Components/Button/Button";
import { SupportMessagesColumns } from "../../../SourceData";
import Loader from "../../../Components/Loader/Loader";
import { message } from "antd";
import RealChatModal from "../../../Components/Modals/RealChatModal/RealChatModal";

const StudentsTrainersMessages = () => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const isTrainer = location?.pathname?.includes("-trainer");
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [selectedUser, setSlectedUser] = useState({});

  useEffect(() => {
    getMessages();
  }, [isTrainer]);

  const getMessages = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(
        `/support_chats?type=${isTrainer ? "trainer" : "student"}`
      );
      setMessages(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleActive = async (row) => {
    setLoading(true);
    try {
      await newRequest.put(`/support_chats/${row.id}`, {
        finish: row.finish === 1 ? 0 : 1,
      });
      getMessages();
      message.success("تم تغير الحالة بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  const handleMultiDelete = async () => {
    if (selectedRowsIds.length === 0) {
      return message.warning("من فضك قم بتحديد الصفوف التي تريد حذفها اولاً");
    }
    setLoading(true);
    try {
      for (const rowId of selectedRowsIds) {
        await newRequest.delete(`/questions/${rowId}`);
      }
      await getMessages();
      setSelectedRowsIds([]);
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 280,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="cell-action" style={{ display: "flex" }}>
            {/* View Action */}
            <div
              className="cell-btn"
              onClick={() => {
                navigate(
                  isTrainer
                    ? "/support/trainer-messages/view"
                    : "/support/student-messages/view",
                  { state: params?.row }
                );
              }}
            >
              <img src={ViewIcon} alt="" />
            </div>
            {/* Add Note */}
            <div
              className="cell-btn"
              onClick={() => {
                navigate(
                  isTrainer
                    ? "/support/trainer-messages/note"
                    : "/support/student-messages/note",
                  {
                    state: params?.row,
                  }
                );
              }}
            >
              <img src={AnswerIcon} alt="" />
            </div>

            {/* Chat Action */}
            <div
              className="cell-btn"
              onClick={() => {
                setOpen(true);
                setSlectedUser(params?.row);
              }}
            >
              <img src={ChatIcon} alt="" />
            </div>

            <div className="cell-btn ">
              <Button
                text={params.row.finish === 1 ? "مغلقة" : "تم الانتهاء"}
                size={"small"}
                variant={params.row.finish === 1 ? "fill" : "outlined"}
                onClick={() => handleActive(params.row)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <div className="students-support">
        <div className="container">
          <div className="spcial-header">
            <h4 className="title">
              {isTrainer ? "رسائل الدعم للمدرب" : "رسائل الدعم للطالب"}
            </h4>
            <div className="actions-container">
              <Button
                text={"حذف متعدد"}
                size={isMobile ? "full" : "small"}
                variant={"outlined"}
                onClick={handleMultiDelete}
              />
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="data-grid">
              <DataGrid
                sx={{ minHeight: 600 }}
                columns={SupportMessagesColumns.concat(actionColumn)}
                rows={messages.map((row, index) => {
                  return { ...row, index: index + 1 };
                })}
                params={messages}
                disableDensitySelector
                disableRowSelectionOnClick
                disableVirtualization
                initialState={{
                  ...messages.initialState,
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelectedRowsIds(newRowSelectionModel);
                }}
                rowSelectionModel={selectedRowsIds}
              />
            </div>
          )}
        </div>
      </div>

      <RealChatModal
        open={open}
        onClose={() => setOpen(false)}
        data={selectedUser}
      />
    </Fragment>
  );
};

export default StudentsTrainersMessages;
