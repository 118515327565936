import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./userSlice";
import questionReducer from "./questionSlice";
import studentsReducer from "./studentsSlice";
import trainerReducer from "./trainerSlice";
import dashReducer from "./dashSlice";
import countryReducer from "./countrySlice";
import cityReducer from "./citySlice";
import regionReducer from "./regionSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  user: userReducer,
  question: questionReducer,
  students: studentsReducer,
  trainer: trainerReducer,
  dash: dashReducer,
  country: countryReducer,
  city: cityReducer,
  region: regionReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
