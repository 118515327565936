import "./Statistics.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import Button from "../../../../Components/Button/Button";
import { useMediaQuery } from "@mui/material";
import newRequest from "../../../../Utils/newRequest";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import Loader from "../../../../Components/Loader/Loader";

const Statistics = (props) => {
  const user = props?.user;
  const isMobile = useMediaQuery("(max-width:767px)");
  const [activeItem, setActiveItem] = useState(1);
  const [statistics, setStatistics] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getStatistics();
  }, []);

  const getStatistics = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(`/statistics/${user?.id}`);
      setStatistics(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const menuItems = [
    {
      key: 1,
      text: "التدريبات",
      size: "medium",
    },
    {
      key: 2,
      text: "مسابقات",
      size: "medium",
    },
    {
      key: 3,
      text: "بطولات",
      size: "medium",
    },
  ];
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        عرض الاحصائيات
      </AccordionSummary>
      <div className="statics container">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="header"></div>
            <table>
              <thead>
                <tr>
                  <th>التدريبات</th>
                  <th>المسابقات</th>
                  <th>البطولات</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{statistics?.trainings_count}</td>
                  <td>{statistics?.competitions_count}</td>
                  <td>{statistics?.championships_count}</td>
                </tr>
              </tbody>
            </table>

            <div className="actions-container">
              {menuItems.map((item) => (
                <div
                  key={item.key}
                  onClick={() => setActiveItem(item.key)}
                  style={{ width: isMobile && "100%" }}
                >
                  <Button
                    text={item.text}
                    variant={activeItem === item.key ? "fill" : "outlined"}
                    size={isMobile ? "full" : item.size}
                  />
                </div>
              ))}
            </div>

            {activeItem === 2 && (
              <div className="text-container">
                {/* <div className="text-item">المركز : 1</div> */}
                <div className="text-item">
                  عدد المرات : {statistics?.competitions?.length}
                </div>
              </div>
            )}

            {activeItem === 3 && (
              <div className="text-container">
                {/* <div className="text-item">المركز : 1</div> */}
                <div className="text-item">
                  عدد المرات : {statistics?.championships?.length}
                </div>
              </div>
            )}

            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>المستوي</th>
                  <th>الاقسام الفرعية</th>
                  <th>إجمالي الاسئلة</th>
                  <th>الاجابات الخاطئة</th>
                  <th>الاجابات الصحيحة</th>
                </tr>
              </thead>
              <tbody>
                {activeItem === 1 &&
                  statistics?.trainings?.map((item, index) => (
                    <tr key={index + 1}>
                      <td>{index + 1}</td>
                      <td>{item?.type_level?.title}</td>
                      <td>{item?.level_category?.title}</td>
                      <td>{item?.level_category?.questions_count}</td>
                      <td>5</td>
                      <td>15</td>
                    </tr>
                  ))}
                {activeItem === 2 &&
                  statistics?.competitions?.map((item, index) => (
                    <tr key={index + 1}>
                      <td>{index + 1}</td>
                      <td>{item?.type_level?.title}</td>
                      <td>{item?.level_category?.title}</td>
                      <td>{item?.level_category?.questions_count}</td>
                      <td>5</td>
                      <td>15</td>
                    </tr>
                  ))}
                {activeItem === 3 &&
                  statistics?.championships?.map((item, index) => (
                    <tr key={index + 1}>
                      <td>{index + 1}</td>
                      <td>{item?.type_level?.title}</td>
                      <td>{item?.level_category?.title}</td>
                      <td>{item?.level_category?.questions_count}</td>
                      <td>5</td>
                      <td>15</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </Accordion>
  );
};

export default Statistics;
