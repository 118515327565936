import "./CreateEditStudent.scss";
import Button from "../../../Components/Button/Button";
import AddIcon from "@mui/icons-material/AddOutlined";
import {
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { message } from "antd";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Loader from "../../../Components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

const CreateEditStudent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state;
  const isCreate = location.pathname.includes("/create");
  const isMobile = useMediaQuery("(max-width:767px)");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordMatched, setIsPasswordMatched] = useState(true);
  const [student, setStudent] = useState({
    name: user?.name || "",
    phone: user?.phone || "",
    birth_date: user?.birth_date || "",
    // email: user?.email || "",
    gender: user?.gender || "",
    country_id: user?.country?.id || "",
    city_id: user?.city?.id || "",
    region_id: user?.region?.id || "",
    password: "",
    password_confirmation: "",
    role: "student",
    student: checked ? 1 : 0,
    provide_name: "admin",
  });

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    getCities();
  }, [student?.country_id]);

  useEffect(() => {
    getRegions();
  }, [student?.city_id]);

  const getCountries = async () => {
    setLoading(true);
    try {
      const countriesResponce = await newRequest.get(`/countries`, {
        headers: {
          "Accept-Language": "ar",
        },
      });
      setCountries(countriesResponce?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const getCities = async () => {
    try {
      const citiesResponce = await newRequest.get(
        `/cities?country_id=${student?.country_id}`,
        {
          headers: {
            "Accept-Language": "ar",
          },
        }
      );
      setCities(citiesResponce?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  const getRegions = async () => {
    try {
      const regionsResponce = await newRequest.get(
        `/regions?city_id=${student?.city_id || user?.region?.id}`,
        {
          headers: {
            "Accept-Language": "ar",
          },
        }
      );
      setRegions(regionsResponce?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  const handleChange = (e) => {
    setStudent((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSwitch = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (isCreate) {
      if (student?.password !== student?.password_confirmation) {
        setIsPasswordMatched(false);
        return;
      }
      try {
        await newRequest.post("/users", {
          ...student,
          // special_trainer: checked,
        });
        setStudent({});
        message.success("تم اضافة الطالب بنجاح");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setIsPasswordMatched(true);
        setLoading(false);
      }
    } else {
      const { password, password_confirmation, ...trainerWithoutPasswords } =
        student;
      try {
        await newRequest.put(`/users/${user?.id}`, {
          ...trainerWithoutPasswords,
        });
        message.success("تم تعديل الطالب بنجاح");
        navigate(`/students`);
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setIsPasswordMatched(true);
        setLoading(false);
      }
    }
  };

  return (
    <div className="create">
      <div className="container">
        <div className="header">
          <h3>{isCreate ? "اضافه طالب جديد" : "تعديل بيانات طالب "}</h3>
          {/* {isCreate && (
            <div style={{ display: "flex" }}>
              <Button
                text="اضافتة كطالب مميز"
                variant="outlined"
                size={isMobile ? "full" : "fit"}
                icon={<AddIcon />}
                onClick={() => setChecked(!checked)}
              />
              <div className="switch-btn">
                <Switch checked={checked} onChange={handleSwitch} />
              </div>
            </div>
          )} */}
        </div>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الاسم</label>
                <TextField
                  required
                  fullWidth
                  id="fullWidth"
                  className="form-input"
                  name="name"
                  onChange={handleChange}
                  value={student?.name}
                />
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">رقم الموبايل</label>
                <TextField
                  required
                  fullWidth
                  id="fullWidth"
                  className="form-input"
                  name="phone"
                  onChange={handleChange}
                  value={student?.phone}
                />
              </FormControl>
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">تاريخ الميلاد</label>
                <TextField
                  type="date"
                  required
                  fullWidth
                  id="fullWidth"
                  className="form-input"
                  name="birth_date"
                  onChange={handleChange}
                  value={moment(student?.birth_date).format("YYYY-MM-DD")}
                />
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="aria-label">الدولة</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  className="select"
                  name="country_id"
                  value={student?.country_id}
                  onChange={handleChange}
                >
                  {countries?.map((item) => (
                    <MenuItem value={item?.id} key={item?.id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="aria-label">المدينة</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  className="select"
                  value={student?.city_id}
                  name="city_id"
                  onChange={handleChange}
                >
                  {cities?.map((item) => (
                    <MenuItem value={item?.id} key={item?.id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="aria-label">المنطقة</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  className="select"
                  value={student?.region_id}
                  name="region_id"
                  onChange={handleChange}
                >
                  {regions?.map((item) => (
                    <MenuItem value={item?.id} key={item?.id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                {/* <label className="form-label">البريد الالكتروني</label>
                <TextField
                  required
                  fullWidth
                  id="fullWidth"
                  className="form-input"
                  name="email"
                  onChange={handleChange}
                  value={student?.email}
                /> */}
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="aria-label">الجنس</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  className="select"
                  name="gender"
                  value={student?.gender}
                  onChange={handleChange}
                >
                  <MenuItem value="male" key={"male"}>
                    ذكر
                  </MenuItem>
                  <MenuItem value="female" key={"female"}>
                    أنثي
                  </MenuItem>
                </Select>
              </FormControl>
              {isCreate && (
                <>
                  <FormControl sx={{ m: 1, flex: 1 }} className="select">
                    <label className="form-label">كلمة المرور</label>
                    <TextField
                      required
                      fullWidth
                      id="fullWidth"
                      className="form-input"
                      placeholder="ادخل كلمة المرور"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      onChange={handleChange}
                      error={!isPasswordMatched}
                      helperText={
                        !isPasswordMatched && "كلمة المرور غير متطابقة"
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, flex: 1 }} className="select">
                    <label className="form-label">تاكيد كلمة المرور</label>
                    <TextField
                      required
                      fullWidth
                      id="fullWidth"
                      className="form-input"
                      placeholder="تأكيد كلمة المرور"
                      name="password_confirmation"
                      type={showPassword ? "text" : "password"}
                      onChange={handleChange}
                      error={!isPasswordMatched}
                      helperText={
                        !isPasswordMatched && "كلمة المرور غير متطابقة"
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </>
              )}
            </div>

            <div className="form-actions">
              <Button
                text={isCreate ? "اضافة" : "تعديل"}
                size="small"
                variant="fill"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateEditStudent;

// const [updatedStudent, setUpdatedStudent] = useState({
//   name: user?.name || "",
//   phone: user?.phone || "",
//   email: user?.email || "",
//   gender: user?.gender || "",
//   country_id: user?.country?.id || "",
//   city_id: user?.city?.id || "",
//   region_id: user?.region?.id || "",
//   password: "",
//   password_confirmation: "",
//   role: "trainer",
//   special_trainer: checked ? 1 : 0,
// });
