import "./ViewTrainer.scss";
import Avatr from "./../../../Assets/Avatr.png";
import Button from "../../../Components/Button/Button";
import AddIcon from "@mui/icons-material/AddOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import newRequest from "../../../Utils/newRequest";
import { message } from "antd";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import Loader from "../../../Components/Loader/Loader";
import MainInfo from "./MainInfo/MainInfo";
import Chats from "./Chats/Chats";
import WorkAreas from "./WorkAreas/WorkAreas";
import TrainingsAndCompetitions from "./TrainingsAndCompetitions/TrainingsAndCompetitions";
import Gallery from "./Gallery/Gallery";
import Rating from "./Rating/Rating";
import SpecialStudents from "./SpecialStudents/SpecialStudents";

const ViewTrainer = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const location = useLocation();
  const row = location.state;
  const [loading, setLoading] = useState(false);
  const [trainer, setTrainer] = useState({});

  useEffect(() => {
    getTrainer();
  }, []);

  const getTrainer = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(`/trainers/${row?.id}`);
      setTrainer(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleActive = async () => {
    setLoading(true);
    try {
      const res = await newRequest.put(`trainers/${row?.id}/specialToggle`);
      setTrainer(res?.data?.data);
      message.success("تم التعديل بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="view">
        <div className="container">
          <div className="header">
            <h3>تفاصيل المدرب</h3>
            <div className="actions-container">
              <Button
                text={
                  trainer?.special_trainer
                    ? "حذف كمدرب مميز"
                    : "اضافتة كمدرب مميز"
                }
                variant="outlined"
                size={isMobile ? "full" : "fit"}
                icon={trainer?.special_trainer ? "" : <AddIcon />}
                onClick={handleActive}
              />
              <Button
                text="تغير كلمة السر"
                variant="fill"
                size={isMobile ? "full" : "medium"}
              />
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="accordions-container">
              {/* الداتا الاساسية */}
              <MainInfo user={trainer} />
              {/*قائمة الدردشات مع الطلاب*/}
              <Chats id={row?.id} />
              {/*مناطق العمل*/}
              <WorkAreas id={row?.id} />
              {/*تفاصيل التدريبات و المسابقات*/}
              <TrainingsAndCompetitions id={row?.id} />
              {/* الصور والفديوهات الخاصة */}
              <Gallery id={row?.id} />
              {/* الطلاب المميزين */}
              <SpecialStudents trainer={trainer} />
              {/* التقيم */}
              <Rating id={row?.id} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewTrainer;
