import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  questions: [],
};

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    questionFetched: (state, action) => {
      state.questions = action.payload;
    },
    questionUpdated: (state, action) => {
      state.questions = action.payload;
    },
  },
});

export const { questionFetched, questionUpdated } = questionSlice.actions;

export default questionSlice.reducer;
