import "./Countries.scss";
import AddIcon from "@mui/icons-material/AddOutlined";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "./../../../Assets/edit-icon.svg";
import DeleteIcon from "./../../../Assets/delete-icon.svg";
import ViewIcon from "./../../../Assets/details-icon.svg";
import CloseIcon from "./../../../Assets/close-icon.svg";
import AttentionIcon from "./../../../Assets/attention-icon.svg";
import newRequest from "../../../Utils/newRequest";
import Loader from "../../../Components/Loader/Loader";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import { Modal, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import { CountriesColumns } from "../../../SourceData";
import MessageModal from "../../../Components/Modals/MessageModal/MessageModal";
import { message } from "antd";

const Countries = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const [countries, setCountries] = useState([]);
  const [openMessage, setOpenMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState("");
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get("/countries");
      setCountries(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMultiDelete = async () => {
    if (selectedRowsIds.length === 0) {
      return message.warning("من فضك قم بتحديد الصفوف التي تريد حذفها اولاً");
    }
    setLoading(true);
    try {
      for (const questionId of selectedRowsIds) {
        await newRequest.delete(`/countries/${questionId}`);
      }
      await getCountries();
      setSelectedRowsIds([]);
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenMessage(false);
    try {
      await newRequest.delete(`/countries/${rowId}`);
      await getCountries();
      setRowId("");
      message.success("تم حذف الدولة بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 280,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="cell-action" style={{ display: "flex" }}>
            {/* <div
              className="cell-btn"
              onClick={() => {
                navigate("/countries/edit", { state: params?.row });
              }}
            >
              <img src={ViewIcon} alt="" />
            </div> */}

            <div
              className="cell-btn"
              onClick={() => {
                navigate("/countries/edit", { state: params?.row });
              }}
            >
              <img src={EditIcon} alt="" />
            </div>

            <div
              className="cell-btn"
              onClick={() => {
                setOpenMessage(true);
                setRowId(params?.row?.id);
              }}
            >
              <img src={DeleteIcon} alt="" />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="common-page">
      <div className="container">
        <div className="spcial-header">
          <h4 className="title">اضافة ( الدول )</h4>
          <div className="actions-container">
            <Button
              text={"اضافة"}
              size={isMobile ? "full" : "small"}
              variant={"fill"}
              icon={<AddIcon />}
              path={"/countries/create"}
              isMobile=""
            />

            <Button
              text={"حذف متعدد"}
              size={isMobile ? "full" : "small"}
              variant={"outlined"}
              onClick={handleMultiDelete}
            />
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="data-grid">
            <DataGrid
              sx={{ minHeight: 600 }}
              columns={CountriesColumns.concat(actionColumn)}
              rows={countries.map((row, index) => {
                return { ...row, index: index + 1 };
              })}
              disableDensitySelector
              disableRowSelectionOnClick
              disableVirtualization
              initialState={{
                ...countries.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 50]}
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setSelectedRowsIds(newRowSelectionModel);
              }}
              rowSelectionModel={selectedRowsIds}
            />
          </div>
        )}
      </div>

      {/* Message Modal */}
      <Modal
        open={openMessage}
        onClose={() => {
          setOpenMessage(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div
            className="header"
            onClick={() => {
              setOpenMessage(false);
              setRowId("");
            }}
          >
            <img src={CloseIcon} alt="" />
          </div>
          <div className="body">
            <img src={AttentionIcon} alt="" />
            <h2>انتبة</h2>
            <h5>هل انت متاكد من حذف هذه الدولة من</h5>
            <h6>الدول</h6>
          </div>
          <div className="footer">
            <button className="action-btn" onClick={handleDelete}>
              حذف
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Countries;
