import "./TraniersStudentsSliders.scss";
import AddIcon from "@mui/icons-material/AddOutlined";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "./../../../../Assets/edit-icon.svg";
import DeleteIcon from "./../../../../Assets/delete-icon.svg";
import CloseIcon from "./../../../../Assets/close-icon.svg";
import AttentionIcon from "./../../../../Assets/attention-icon.svg";
import { Modal, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../Components/Button/Button";
import { TraniersStudentsSlidersColumns } from "../../../../SourceData";
import newRequest from "../../../../Utils/newRequest";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../../../Components/Loader/Loader";

const TraniersStudentsSliders = () => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTrainer = location?.pathname?.includes("/trainer");
  const navigate = useNavigate();
  const [sliders, setSliders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [rowId, setRowId] = useState("");

  useEffect(() => {
    getSliders();
  }, [isTrainer]);

  const getSliders = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(
        `/sliders?type=${isTrainer ? "trainer" : "student"}`
      );
      setSliders(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenMessage(false);
    try {
      await newRequest.delete(`/sliders/${rowId}`);
      await getSliders();
      message.success("تم حذف الصورة بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setRowId("");
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 280,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="cell-action" style={{ display: "flex" }}>
            <div
              className="cell-btn"
              onClick={() => {
                navigate(
                  isTrainer
                    ? "/slider-images/trainers/edit"
                    : "/slider-images/students/edit",
                  { state: params?.row }
                );
              }}
            >
              <img src={EditIcon} alt="" />
            </div>
            <div
              className="cell-btn"
              onClick={() => {
                setOpenMessage(true);
                setRowId(params.row.id);
              }}
            >
              <img src={DeleteIcon} alt="" />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="common-page">
      <div className="container">
        <div className="spcial-header">
          <h4 className="title">
            الصفحات التعرفية ( {isTrainer ? "المدرب" : "الطالب"} )
          </h4>
          <div className="actions-container">
            <Button
              text={"اضافة صورة"}
              size={isMobile ? "full" : "fit"}
              variant={"fill"}
              icon={<AddIcon />}
              path={
                isTrainer
                  ? "/slider-images/trainers/create"
                  : "/slider-images/students/create"
              }
            />
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="data-grid">
            <DataGrid
              sx={{ height: 700 }}
              columns={TraniersStudentsSlidersColumns.concat(actionColumn)}
              rows={sliders.map((row, index) => {
                return { ...row, index: index + 1 };
              })}
              params={sliders}
              disableDensitySelector
              disableRowSelectionOnClick
              disableVirtualization
              initialState={{
                ...sliders.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 50]}
              rowHeight={70}
            />
          </div>
        )}
      </div>

      {/* Message Modal */}
      <Modal
        open={openMessage}
        onClose={() => {
          setOpenMessage(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div
            className="header"
            onClick={() => {
              setOpenMessage(false);
              setRowId("");
            }}
          >
            <img src={CloseIcon} alt="" />
          </div>
          <div className="body">
            <img src={AttentionIcon} alt="" />
            <h2>انتبة</h2>
            <h5>هل انت متاكد من حذف هذه الصورة من</h5>
            <h6>السلايدر</h6>
          </div>
          <div className="footer">
            <button className="action-btn" onClick={handleDelete}>
              حذف
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TraniersStudentsSliders;
