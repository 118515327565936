import "./Sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import SubAdminIcon from "@mui/icons-material/SupervisorAccount";
import ProfileIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import PackagesIcon from "@mui/icons-material/LibraryBooks";
import DepartmentsIcon from "@mui/icons-material/Category";
import QuestionsIcon from "@mui/icons-material/QuestionMark";
import StudentsIcon from "@mui/icons-material/School";
import TrainersIcon from "@mui/icons-material/SupervisedUserCircle";
import ChampionshipsIcon from "@mui/icons-material/EmojiEvents";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SupportIcon from "@mui/icons-material/SupportAgent";
import ContactIcon from "@mui/icons-material/ContactSupport";
import { ListItemButton } from "@mui/material";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const location = useLocation();
  const [activeButton, setActiveButton] = useState(null);
  const [openSettings, setOpenSettings] = useState(false);
  const [openPackages, setOpenPackages] = useState(false);
  const [openDepartments, setOpenDepartments] = useState(false);
  const [openSupport, setOpenSupport] = useState(false);
  const currentUser = useSelector((state) => state.user.currentUser);
  const userPermissions = currentUser?.permissions;

  const hasPermission = (permissionId, userPermissions) => {
    return userPermissions?.some(
      (permission) => permission.id === permissionId
    );
  };

  useEffect(() => {
    setActiveButton(location.pathname);
  }, [location.pathname]);

  const handleClickSettings = () => {
    setOpenSettings(!openSettings);
  };

  const handleClickPackages = () => {
    setOpenPackages(!openPackages);
  };

  const handleClickDepartments = () => {
    setOpenDepartments(!openDepartments);
  };

  const handleClickSupport = () => {
    setOpenSupport(!openSupport);
  };

  return (
    <div className="sidebar">
      {currentUser?.role === "admin" && (
        <div className="container">
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className="menu"
          >
            <ListItemButton
              component={Link}
              to="/"
              onClick={() => setActiveButton("/")}
              className={
                activeButton === "/" ? "list-item active" : "list-item"
              }
            >
              <ListItemIcon>
                <HomeIcon className="icon" />
              </ListItemIcon>

              <ListItemText
                primary="الصفحة الرئيسية"
                className="list-item-title"
              />
            </ListItemButton>

            <ListItemButton
              component={Link}
              to="/admins"
              onClick={() => setActiveButton("/admins")}
              className={
                activeButton?.includes("admins")
                  ? "list-item active"
                  : "list-item"
              }
            >
              <ListItemIcon>
                <SubAdminIcon className="icon" />
              </ListItemIcon>
              <ListItemText
                primary="المسؤلين الفرعين"
                className="list-item-title"
              />
            </ListItemButton>

            <ListItemButton
              component={Link}
              to="/profile"
              className={
                activeButton === "/profile" ? "list-item active" : "list-item"
              }
            >
              <ListItemIcon>
                <ProfileIcon className="icon" />
              </ListItemIcon>
              <ListItemText
                primary="الصفحة الشخصية"
                className="list-item-title"
              />
            </ListItemButton>

            <div className={openSettings ? "list active" : "list"}>
              <ListItemButton onClick={handleClickSettings}>
                <ListItemIcon>
                  <SettingsIcon className="icon" />
                </ListItemIcon>
                <ListItemText primary="الإعدادات" className="list-item-title" />
                {openSettings ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              <Collapse in={openSettings} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/terms-and-conditions"
                    className={
                      activeButton?.includes("terms")
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                  >
                    <ListItemText
                      primary="الشروط والاحكام"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>

              <Collapse in={openSettings} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/social-media-links"
                    className={
                      activeButton === "/social-media-links"
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                  >
                    <ListItemText
                      primary="روابط التواصل الاجتماعي"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>

              <Collapse in={openSettings} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/profile-pages"
                    className={
                      activeButton?.includes("profile-pages")
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                  >
                    <ListItemText
                      primary="الصفحات التعريفية"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>

              <Collapse in={openSettings} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/slider-images"
                    className={
                      activeButton?.includes("slider-images")
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                  >
                    <ListItemText
                      primary="صور السلايدر"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>

              <Collapse in={openSettings} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/download-link"
                    className={
                      activeButton === "/download-link"
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                  >
                    <ListItemText
                      primary="لينك تحميل التطبيق"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>

              <Collapse in={openSettings} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/countries"
                    className={
                      activeButton?.includes("countries")
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                  >
                    <ListItemText
                      primary="الدول"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>

              <Collapse in={openSettings} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/cities"
                    className={
                      activeButton?.includes("cities")
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                  >
                    <ListItemText
                      primary="المدن"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>

              <Collapse in={openSettings} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/regions"
                    className={
                      activeButton?.includes("regions")
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                  >
                    <ListItemText
                      primary="المناطق"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>

              <Collapse in={openSettings} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/student-points"
                    className={
                      activeButton === "/student-points"
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                  >
                    <ListItemText
                      primary="نقاط الطلاب"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>

              <Collapse in={openSettings} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/student-ranges"
                    className={
                      activeButton?.includes("student-ranges")
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                  >
                    <ListItemText
                      primary="نطاقات نقاط التطبيق للطلاب"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>
            </div>

            <div className={openPackages ? "list active" : "list"}>
              <ListItemButton onClick={handleClickPackages}>
                <ListItemIcon>
                  <PackagesIcon className="icon" />
                </ListItemIcon>
                <ListItemText primary="الباقات" className="list-item-title" />
                {openPackages ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              <Collapse in={openPackages} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    component={Link}
                    to="/packages/trainer"
                    onClick={() => setActiveButton("/packages/trainer")}
                    className={
                      activeButton?.includes("packages/trainer")
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                    sx={{
                      pl: 4,
                    }}
                  >
                    <ListItemText
                      primary="باقة المدرب"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>

              <Collapse in={openPackages} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    component={Link}
                    to="/packages/student"
                    className={
                      activeButton?.includes("packages/student")
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                    sx={{
                      pl: 4,
                    }}
                  >
                    <ListItemText
                      primary="باقة الطالب"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>

              <Collapse in={openPackages} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    component={Link}
                    to="/packages/promo-code"
                    className={
                      activeButton?.includes("packages/promo-code")
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                    sx={{
                      pl: 4,
                    }}
                  >
                    <ListItemText
                      primary="اكواد الخصم"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>
            </div>

            <div className={openDepartments ? "list active" : "list"}>
              <ListItemButton
                onClick={handleClickDepartments}
                className={openDepartments ? "active" : ""}
              >
                <ListItemIcon>
                  <DepartmentsIcon className="icon" />
                </ListItemIcon>
                <ListItemText primary="الأقسام" className="list-item-title" />
                {openDepartments ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              <Collapse in={openDepartments} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/departments"
                    className={
                      activeButton?.includes("/departments")
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                  >
                    <ListItemText
                      primary="الأقسام الرئيسية"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>

              <Collapse in={openDepartments} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/sub-departments"
                    className={
                      activeButton?.includes("/sub-departments")
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                  >
                    <ListItemText
                      primary="الأقسام الفرعية"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>
            </div>

            <ListItemButton
              component={Link}
              to="/questions"
              onClick={() => setActiveButton("/questions")}
              className={
                activeButton?.includes("/questions")
                  ? "list-item active"
                  : "list-item"
              }
            >
              <ListItemIcon>
                <QuestionsIcon className="icon" />
              </ListItemIcon>
              <ListItemText primary="الاسئلة" className="list-item-title" />
            </ListItemButton>

            <ListItemButton
              component={Link}
              to="/students"
              onClick={() => setActiveButton("/students")}
              className={
                activeButton?.includes("/students")
                  ? "list-item active"
                  : "list-item"
              }
            >
              <ListItemIcon>
                <StudentsIcon className="icon" />
              </ListItemIcon>
              <ListItemText
                primary="الطلاب المسجلين"
                className="list-item-title"
              />
            </ListItemButton>

            <ListItemButton
              component={Link}
              to="/trainers"
              onClick={() => setActiveButton("/trainers")}
              className={
                activeButton?.includes("/trainers")
                  ? "list-item active"
                  : "list-item"
              }
            >
              <ListItemIcon>
                <TrainersIcon className="icon" />
              </ListItemIcon>
              <ListItemText
                primary="المدربين المسجلين"
                className="list-item-title"
              />
            </ListItemButton>

            <ListItemButton
              component={Link}
              to="/championships"
              onClick={() => setActiveButton("/championships")}
              className={
                activeButton?.includes("/championships")
                  ? "list-item active"
                  : "list-item"
              }
            >
              <ListItemIcon>
                <ChampionshipsIcon className="icon" />
              </ListItemIcon>
              <ListItemText primary="البطولات" className="list-item-title" />
            </ListItemButton>

            <ListItemButton
              component={Link}
              to="/notifications"
              onClick={() => setActiveButton("/notifications")}
              className={
                activeButton?.includes("/notifications")
                  ? "list-item active"
                  : "list-item"
              }
            >
              <ListItemIcon>
                <NotificationsIcon className="icon" />
              </ListItemIcon>
              <ListItemText primary="الاشعارات" className="list-item-title" />
            </ListItemButton>

            <div className={openSupport ? "list active" : "list"}>
              <ListItemButton onClick={handleClickSupport}>
                <ListItemIcon>
                  <SupportIcon className="icon" />
                </ListItemIcon>
                <ListItemText
                  primary="الدعم الفني"
                  className="list-item-title"
                />
                {openSupport ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              <Collapse in={openSupport} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/support/student"
                    className={
                      activeButton?.includes("/support/student")
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                  >
                    <ListItemText
                      primary="اسئلة الطلاب"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>

              <Collapse in={openSupport} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/support/trainer"
                    className={
                      activeButton?.includes("/support/trainer")
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                  >
                    <ListItemText
                      primary="اسئلة المدرب"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>

              <Collapse in={openSupport} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/support-student-messages"
                    className={
                      activeButton?.includes("/support-student-messages")
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                  >
                    <ListItemText
                      primary="رسائل الدعم للطلاب"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>

              <Collapse in={openSupport} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to="/support-trainer-messages"
                    className={
                      activeButton?.includes("/support-trainer-messages")
                        ? "collapse-item active"
                        : "collapse-item"
                    }
                  >
                    <ListItemText
                      primary="رسائل الدعم للمدرب"
                      className="collapse-item-title"
                    />
                  </ListItemButton>
                </List>
              </Collapse>
            </div>

            <ListItemButton
              component={Link}
              to="/contact"
              onClick={() => setActiveButton("/contact")}
              className={
                activeButton?.includes("/contact")
                  ? "list-item active"
                  : "list-item"
              }
            >
              <ListItemIcon>
                <ContactIcon className="icon" />
              </ListItemIcon>
              <ListItemText primary="تواصل معنا" className="list-item-title" />
            </ListItemButton>
          </List>
        </div>
      )}

      {currentUser?.role === "sub_admin" && (
        <div className="container">
          <List
            sx={{ width: "220px", bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            className="menu"
          >
            <ListItemButton
              component={Link}
              to="/"
              onClick={() => setActiveButton("/")}
              className={
                activeButton === "/" ? "list-item active" : "list-item"
              }
            >
              <ListItemIcon>
                <HomeIcon className="icon" />
              </ListItemIcon>

              <ListItemText
                primary="الصفحة الرئيسية"
                className="list-item-title"
              />
            </ListItemButton>

            {hasPermission(1, userPermissions) && (
              <ListItemButton
                component={Link}
                to="/admins"
                onClick={() => setActiveButton("/admins")}
                className={
                  activeButton === "/admins" ? "list-item active" : "list-item"
                }
              >
                <ListItemIcon>
                  <SubAdminIcon className="icon" />
                </ListItemIcon>
                <ListItemText primary="المسؤلين الفرعين" />
              </ListItemButton>
            )}

            <ListItemButton
              component={Link}
              to="/profile"
              className={
                activeButton === "/profile" ? "list-item active" : "list-item"
              }
            >
              <ListItemIcon>
                <ProfileIcon className="icon" />
              </ListItemIcon>
              <ListItemText primary="الصفحة الشخصية" />
            </ListItemButton>

            {hasPermission(
              5 || 31 || 23 || 27 || 7 || 11 || 15 || 19,
              userPermissions
            ) && (
              <div className={openSettings ? "list active" : "list"}>
                <ListItemButton onClick={handleClickSettings}>
                  <ListItemIcon>
                    <SettingsIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary="الإعدادات" />
                  {openSettings ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                {hasPermission(31, userPermissions) && (
                  <Collapse in={openSettings} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        component={Link}
                        to="/terms-and-conditions"
                        className={
                          activeButton === "/terms-and-conditions"
                            ? "collapse-item active"
                            : "collapse-item"
                        }
                      >
                        <ListItemText primary="الشروط والاحكام" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                )}

                {/* {hasPermission(5, userPermissions) && ( */}
                <Collapse in={openSettings} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      component={Link}
                      to="/social-media-links"
                      className={
                        activeButton === "/social-media-links"
                          ? "collapse-item active"
                          : "collapse-item"
                      }
                    >
                      <ListItemText primary="روابط التواصل الاجتماعي" />
                    </ListItemButton>
                  </List>
                </Collapse>
                {/* )} */}

                {hasPermission(23, userPermissions) && (
                  <Collapse in={openSettings} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        component={Link}
                        to="/profile-pages"
                        className={
                          activeButton === "/profile-pages"
                            ? "collapse-item active"
                            : "collapse-item"
                        }
                      >
                        <ListItemText primary="الصفحات التعريفية" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                )}

                {hasPermission(27, userPermissions) && (
                  <Collapse in={openSettings} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        component={Link}
                        to="/slider-images"
                        className={
                          activeButton === "/slider-images"
                            ? "collapse-item active"
                            : "collapse-item"
                        }
                      >
                        <ListItemText primary="صور السلايدر" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                )}

                {/* {hasPermission(5, userPermissions) && ( */}
                <Collapse in={openSettings} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      component={Link}
                      to="/download-link"
                      className={
                        activeButton === "/download-link"
                          ? "collapse-item active"
                          : "collapse-item"
                      }
                    >
                      <ListItemText primary="لينك تحميل التطبيق" />
                    </ListItemButton>
                  </List>
                </Collapse>
                {/* )} */}

                {hasPermission(7, userPermissions) && (
                  <Collapse in={openSettings} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        component={Link}
                        to="/countries"
                        className={
                          activeButton === "/countries"
                            ? "collapse-item active"
                            : "collapse-item"
                        }
                      >
                        <ListItemText primary="الدول" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                )}

                {hasPermission(11, userPermissions) && (
                  <Collapse in={openSettings} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        component={Link}
                        to="/cities"
                        className={
                          activeButton === "/cities"
                            ? "collapse-item active"
                            : "collapse-item"
                        }
                      >
                        <ListItemText primary="المدن" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                )}

                {hasPermission(15, userPermissions) && (
                  <Collapse in={openSettings} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        component={Link}
                        to="/regions"
                        className={
                          activeButton === "/regions"
                            ? "collapse-item active"
                            : "collapse-item"
                        }
                      >
                        <ListItemText primary="المناطق" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                )}

                {/* {hasPermission(5, userPermissions) && ( */}
                <Collapse in={openSettings} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      component={Link}
                      to="/student-points"
                      className={
                        activeButton === "/student-points"
                          ? "collapse-item active"
                          : "collapse-item"
                      }
                    >
                      <ListItemText primary="نقاط الطلاب" />
                    </ListItemButton>
                  </List>
                </Collapse>
                {/* )} */}

                {hasPermission(19, userPermissions) && (
                  <Collapse in={openSettings} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        component={Link}
                        to="/student-ranges"
                        className={
                          activeButton === "/student-ranges"
                            ? "collapse-item active"
                            : "collapse-item"
                        }
                      >
                        <ListItemText primary="نطاقات نقاط التطبيق للطلاب" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                )}
              </div>
            )}

            {hasPermission(35 || 39, userPermissions) && (
              <div className={openPackages ? "list active" : "list"}>
                <ListItemButton onClick={handleClickPackages}>
                  <ListItemIcon>
                    <PackagesIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary="الباقات" />
                  {openPackages ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={openPackages} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/packages/trainer"
                      onClick={() => setActiveButton("/packages/trainer")}
                      className={
                        activeButton === "/packages/trainer"
                          ? "collapse-item active"
                          : "collapse-item"
                      }
                      sx={{
                        pl: 4,
                      }}
                    >
                      <ListItemText primary="باقة المدرب" />
                    </ListItemButton>
                  </List>
                </Collapse>

                <Collapse in={openPackages} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      component={Link}
                      to="/packages/student"
                      className={
                        activeButton === "/packages/student"
                          ? "collapse-item active"
                          : "collapse-item"
                      }
                      sx={{
                        pl: 4,
                      }}
                    >
                      <ListItemText primary="باقة الطالب" />
                    </ListItemButton>
                  </List>
                </Collapse>

                {hasPermission(39, userPermissions) && (
                  <Collapse in={openPackages} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        component={Link}
                        to="/packages/promo-code"
                        className={
                          activeButton === "/packages/promo-code"
                            ? "collapse-item active"
                            : "collapse-item"
                        }
                        sx={{
                          pl: 4,
                        }}
                      >
                        <ListItemText primary="اكواد الخصم" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                )}
              </div>
            )}

            {hasPermission(43 || 47, userPermissions) && (
              <div className={openDepartments ? "list active" : "list"}>
                <ListItemButton
                  onClick={handleClickDepartments}
                  className={openDepartments ? "active" : ""}
                >
                  <ListItemIcon>
                    <DepartmentsIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary="الأقسام" />
                  {openDepartments ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                {hasPermission(43, userPermissions) && (
                  <Collapse in={openDepartments} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        component={Link}
                        to="/departments"
                        className={
                          activeButton === "/departments"
                            ? "collapse-item active"
                            : "collapse-item"
                        }
                      >
                        <ListItemText primary="الأقسام الرئيسية" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                )}

                {hasPermission(47, userPermissions) && (
                  <Collapse in={openDepartments} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        component={Link}
                        to="/sub-departments"
                        className={
                          activeButton === "/sub-departments"
                            ? "collapse-item active"
                            : "collapse-item"
                        }
                      >
                        <ListItemText primary="الأقسام الفرعية" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                )}
              </div>
            )}

            {hasPermission(51, userPermissions) && (
              <ListItemButton
                component={Link}
                to="/questions"
                onClick={() => setActiveButton("/questions")}
                className={
                  activeButton === "/questions"
                    ? "list-item active"
                    : "list-item"
                }
              >
                <ListItemIcon>
                  <QuestionsIcon className="icon" />
                </ListItemIcon>
                <ListItemText primary="الاسئلة" />
              </ListItemButton>
            )}

            {hasPermission(59, userPermissions) && (
              <ListItemButton
                component={Link}
                to="/students"
                onClick={() => setActiveButton("/students")}
                className={
                  activeButton === "/students"
                    ? "list-item active"
                    : "list-item"
                }
              >
                <ListItemIcon>
                  <StudentsIcon className="icon" />
                </ListItemIcon>
                <ListItemText primary="الطلاب المسجلين" />
              </ListItemButton>
            )}

            {hasPermission(55, userPermissions) && (
              <ListItemButton
                component={Link}
                to="/trainers"
                onClick={() => setActiveButton("/trainers")}
                className={
                  activeButton === "/trainers"
                    ? "list-item active"
                    : "list-item"
                }
              >
                <ListItemIcon>
                  <TrainersIcon className="icon" />
                </ListItemIcon>
                <ListItemText primary="المدربين المسجلين" />
              </ListItemButton>
            )}

            {hasPermission(63, userPermissions) && (
              <ListItemButton
                component={Link}
                to="/championships"
                onClick={() => setActiveButton("/championships")}
                className={
                  activeButton === "/championships"
                    ? "list-item active"
                    : "list-item"
                }
              >
                <ListItemIcon>
                  <ChampionshipsIcon className="icon" />
                </ListItemIcon>
                <ListItemText primary="البطولات" />
              </ListItemButton>
            )}

            {hasPermission(67, userPermissions) && (
              <ListItemButton
                component={Link}
                to="/notifications"
                onClick={() => setActiveButton("/notifications")}
                className={
                  activeButton === "/notifications"
                    ? "list-item active"
                    : "list-item"
                }
              >
                <ListItemIcon>
                  <NotificationsIcon className="icon" />
                </ListItemIcon>
                <ListItemText primary="الاشعارات" />
              </ListItemButton>
            )}

            {hasPermission(71 || 75, userPermissions) && (
              <div className={openSupport ? "list active" : "list"}>
                <ListItemButton onClick={handleClickSupport}>
                  <ListItemIcon>
                    <SupportIcon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary="الدعم الفني" />
                  {openSupport ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                {hasPermission(71, userPermissions) && (
                  <>
                    <Collapse in={openSupport} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton
                          sx={{ pl: 4 }}
                          component={Link}
                          to="/support/student"
                          className={
                            activeButton === "/support/student"
                              ? "collapse-item active"
                              : "collapse-item"
                          }
                        >
                          <ListItemText primary="اسئلة الطلاب" />
                        </ListItemButton>
                      </List>
                    </Collapse>

                    <Collapse in={openSupport} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton
                          sx={{ pl: 4 }}
                          component={Link}
                          to="/support/trainer"
                          className={
                            activeButton === "/support/trainer"
                              ? "collapse-item active"
                              : "collapse-item"
                          }
                        >
                          <ListItemText primary="اسئلة المدرب" />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </>
                )}

                {hasPermission(75, userPermissions) && (
                  <>
                    <Collapse in={openSupport} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton
                          sx={{ pl: 4 }}
                          component={Link}
                          to="/support/student-messages"
                          className={
                            activeButton === "/support/student-messages"
                              ? "collapse-item active"
                              : "collapse-item"
                          }
                        >
                          <ListItemText primary="رسائل الدعم للطلاب" />
                        </ListItemButton>
                      </List>
                    </Collapse>

                    <Collapse in={openSupport} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton
                          sx={{ pl: 4 }}
                          component={Link}
                          to="/support/trainer-messages"
                          className={
                            activeButton === "/support/trainer-messages"
                              ? "collapse-item active"
                              : "collapse-item"
                          }
                        >
                          <ListItemText primary="رسائل الدعم للمدرب" />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </>
                )}
              </div>
            )}

            {hasPermission(83, userPermissions) && (
              <ListItemButton
                component={Link}
                to="/contact"
                // onClick={() => setActiveButton("/contact")}
                className={
                  activeButton === "/contact" ? "list-item active" : "list-item"
                }
              >
                <ListItemIcon>
                  <ContactIcon className="icon" />
                </ListItemIcon>
                <ListItemText primary="تواصل معنا" />
              </ListItemButton>
            )}
          </List>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
