import "./NotificationSearch.scss";
import { useEffect, useState, useCallback } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { showErrorMessage } from "../../Utils/showErrorMessage";
import newRequest from "../../Utils/newRequest";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const NotificationSearch = ({ type, onSelectedOptionsChange }) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSearch = useCallback(async (searchQuery) => {
    setLoading(true);
    try {
      if (searchQuery.length > 2) {
        const res = await newRequest.get(`/${type}?search=${searchQuery}`);
        setResult(res?.data?.data || []);
      } else {
        setResult([]);
      }
    } catch (error) {
      showErrorMessage(error);
      setResult([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      handleSearch(inputValue);
    }, 300);
    return () => clearTimeout(debounceTimer);
  }, [inputValue, handleSearch]);

  const handleSelectedOptionsChange = (newValue) => {
    setSelectedOptions(newValue);
    // Call the callback function passed from the parent
    onSelectedOptionsChange(newValue);
  };

  return (
    <div className="notification-search">
      <div className="control-group">
        <Autocomplete
          disablePortal
          sx={{ m: 1, width: "100%" }}
          multiple
          id="checkboxes-tags-demo"
          options={result}
          loading={loading}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              placeholder="ابحث بالاسم او الهاتف"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          inputValue={inputValue}
          onInputChange={(event, newInputValue, reason) => {
            if (reason !== "reset") {
              setInputValue(newInputValue);
            }
          }}
          value={selectedOptions}
          onChange={(event, newValue) => {
            handleSelectedOptionsChange(newValue);
          }}
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </div>
    </div>
  );
};

export default NotificationSearch;
