import "./CreateEditRegion.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Button from "../../../../Components/Button/Button";
import newRequest from "../../../../Utils/newRequest";
import { message } from "antd";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import Loader from "../../../../Components/Loader/Loader";

const CreateEditRegion = () => {
  const location = useLocation();
  const region = location.state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const isCreate = location.pathname.includes("create");
  const [newRegion, setNewRegion] = useState({
    name: {
      ar: "",
      en: "",
    },
    city_id: "",
  });

  const [editRegion, setEditRegion] = useState({
    name: {
      ar: region?.name || "",
    },
    city_id: region?.city?.id,
  });

  useEffect(() => {
    getCities();
  }, []);

  const getCities = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get("/cities");
      setCities(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    if (isCreate) {
      try {
        await newRequest.post(`/regions`, { ...newRegion });
        message.success(
          isCreate ? "تم اضافة المنطقة بنجاح" : "تم تعديل المنطقة بنجاح"
        );
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setNewRegion({});
        setLoading(false);
      }
    } else {
      try {
        await newRequest.put(`/regions/${region?.id}`, { ...editRegion });
        message.success(
          isCreate ? "تم اضافة المنطقة بنجاح" : "تم تعديل المنطقة بنجاح"
        );
        navigate(`/regions`);
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    const [property, language] = name.split(".");
    if (isCreate) {
      setNewRegion((prevState) => ({
        ...prevState,
        [property]: {
          ...prevState[property],
          [language]: value,
        },
      }));
    } else {
      setEditRegion((prevState) => ({
        ...prevState,
        [property]: {
          ...prevState[property],
          [language]: value,
        },
      }));
    }
  };

  const handleSelect = (e) => {
    const { value, name } = e.target;
    if (isCreate) {
      setNewRegion((prev) => {
        return { ...prev, [name]: value };
      });
    } else {
      setEditRegion((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };

  return (
    <div className="create-profile-page">
      <div className="container">
        <div className="header">
          <h3 className="title">
            {isCreate ? "اضافة ( منطقة )" : "تعديل ( منطقة )"}
          </h3>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">المحافظة</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  className="select"
                  name="city_id"
                  onChange={handleSelect}
                  value={isCreate ? newRegion?.city_id : editRegion?.city_id}
                >
                  {cities.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الاسم بالعربية</label>
                <TextField
                  fullWidth
                  className="form-input"
                  required
                  name="name.ar"
                  onChange={handleChange}
                  value={isCreate ? newRegion?.name?.ar : editRegion?.name?.ar}
                />
              </FormControl>
              {isCreate && (
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">الاسم بالانجليزية</label>
                  <TextField
                    fullWidth
                    className="form-input"
                    required
                    name="name.en"
                    onChange={handleChange}
                  />
                </FormControl>
              )}
            </div>

            {/* <div className="control-group">
            <FormControl sx={{ m: 1, flex: 1 }} className="select">
              <label className="form-label">المنطقة</label>
              <Select
                placeholder="ادخل العنوان"
                fullWidth
                className="form-input"
                required
                // onChange={(e) => setAnswer(e.target.value)}
              />
            </FormControl>
            <FormControl
              sx={{ m: 1, flex: 1 }}
              className="select"
            ></FormControl>
          </div>

          <div className="control-group">
            <FormControl sx={{ m: 1, flex: 1 }} className="select">
              <label className="form-label">Country</label>
              <Select
                fullWidth
                className="form-input"
                required
                // onChange={(e) => setAnswer(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ m: 1, flex: 1 }} className="select">
              <label className="form-label">City</label>
              <Select
                fullWidth
                className="form-input"
                required
                // onChange={(e) => setAnswer(e.target.value)}
              />
            </FormControl>
          </div>

          <div className="control-group">
            <FormControl sx={{ m: 1, flex: 1 }} className="select">
              <label className="form-label">Regoin</label>
              <Select
                fullWidth
                className="form-input"
                required
                // onChange={(e) => setAnswer(e.target.value)}
              />
            </FormControl>
            <FormControl
              sx={{ m: 1, flex: 1 }}
              className="select"
            ></FormControl>
          </div> */}

            <div className="action-control">
              <Button
                text={isCreate ? "اضافة" : "تعديل"}
                variant={"fill"}
                size={"small"}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateEditRegion;
