import "./Login.scss";
import LoginImg from "./../../Assets/login-img.svg";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import newRequest from "../../Utils/newRequest";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import EyeIcon from "@mui/icons-material/Visibility";
import EyeSlashIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import {
  loginFailure,
  loginStarted,
  loginSuccess,
} from "../../Redux/userSlice";
import { showErrorMessage } from "../../Utils/showErrorMessage";

const Login = () => {
  const [forgetPassword, setForgetPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [visible, setVisiblity] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(loginStarted());
    try {
      const res = await newRequest.post("/login_email", { email, password });
      localStorage.setItem("user", JSON.stringify(res?.data?.data));
      dispatch(loginSuccess(res?.data?.data));
      setLoading(false);
      navigate("/");
    } catch (error) {
      setLoading(false);
      dispatch(loginFailure());
      showErrorMessage(error);
    }
  };

  // To Close Message Error Automaticlly
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const Icon = visible ? (
    <EyeSlashIcon
      className="second-icon"
      onClick={() => {
        setVisiblity(!visible);
      }}
    />
  ) : (
    <EyeIcon
      className="second-icon"
      onClick={() => {
        setVisiblity(!visible);
      }}
    />
  );
  const InputType = visible ? "text" : "password";

  return (
    <div className="login">
      <div className="container">
        <div className="img-container">
          <img src={LoginImg} alt="" />
        </div>
        <div className="form-container">
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              variant="standard"
              sx={{ width: "100%", gap: "10px" }}
            >
              {errorMessage}
            </Alert>
          </Snackbar>
          <form action="" onSubmit={handleSubmit}>
            <h1 className="title">تسجيل الدخول للوحة التحكم</h1>
            <p className="message">
              مرحبا بك مرة اخري, قم بتسجيل الدخول الي لوحة التحكم الخاصة بك
            </p>

            <div className="control-group">
              <div className="form-control">
                <label htmlFor="email">بريدك الإلكتروني</label>
                <input
                  id="email"
                  type="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="form-control">
                <label htmlFor="password">كلمة المرور</label>
                <input
                  id="password"
                  type={InputType}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
                {password && Icon}
              </div>
            </div>

            <div className="control-group">
              <div className="form-select">
                <label htmlFor="admin">أدمن</label>
                <input id="admin" type="radio" name="role" required />
              </div>

              <div className="form-select">
                <label htmlFor="subadmin">مسئول فرعي</label>
                <input id="subadmin" type="radio" name="role" required />
              </div>
            </div>

            <div className="form-action">
              <button className="action-btn" type="submit">
                {loading ? (
                  <CircularProgress className="circular-progress" size={24} />
                ) : (
                  "تسجيل الدخول"
                )}
              </button>
            </div>

            <Link to="/forget-password">هل نسيت كلمة المرور ؟</Link>

            <div className="form-control"></div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
