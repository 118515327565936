import "./Chart.scss";
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useEffect, useState } from "react";
import locale from "antd/es/date-picker/locale/ar_EG";
import Loader from "../../Loader/Loader";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const initialMonths = [
  { month: { ar: "يناير", en: "Jan" }, value: { students: 0, trainers: 0 } },
  { month: { ar: "فبراير", en: "Feb" }, value: { students: 0, trainers: 0 } },
  { month: { ar: "مارس", en: "Mar" }, value: { students: 0, trainers: 0 } },
  { month: { ar: "أبريل", en: "Apr" }, value: { students: 0, trainers: 0 } },
  { month: { ar: "مايو", en: "May" }, value: { students: 0, trainers: 0 } },
  { month: { ar: "يونيو", en: "Jun" }, value: { students: 0, trainers: 0 } },
  { month: { ar: "يوليو", en: "Jul" }, value: { students: 0, trainers: 0 } },
  { month: { ar: "أغسطس", en: "Aug" }, value: { students: 0, trainers: 0 } },
  { month: { ar: "سبتمبر", en: "Sep" }, value: { students: 0, trainers: 0 } },
  { month: { ar: "أكتوبر", en: "Oct" }, value: { students: 0, trainers: 0 } },
  { month: { ar: "نوفمبر", en: "Nov" }, value: { students: 0, trainers: 0 } },
  { month: { ar: "ديسمبر", en: "Dec" }, value: { students: 0, trainers: 0 } },
];

const Chart = () => {
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [chartData, setChartData] = useState([]);
  const dashData = useSelector((state) => state?.dash?.data);

  useEffect(() => {
    const students = dashData?.students_attendence_chart || {};
    const trainers = dashData?.trainers_attendence_chart || {};
    const updatedMonths = initialMonths.map((item) => {
      const monthKey = item?.month?.en;
      const studentCount = students[monthKey] || 0;
      const trainerCount = trainers[monthKey] || 0;
      return {
        ...item,
        value: {
          students: studentCount,
          trainers: trainerCount,
        },
      };
    });

    const transformedData = updatedMonths.map((month) => ({
      month: month?.month?.ar,
      students: month.value.students,
      trainers: month.value.trainers,
    }));

    setChartData(transformedData);
  }, []);

  return (
    <div className="chart">
      <div className="header-container">
        <h3 className="chart-title">كل الحضور</h3>
      </div>
      {!dashData ? (
        <Loader />
      ) : (
        <ResponsiveContainer width="100%" aspect={4 / 1}>
          <LineChart
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke="#e0dfdf" strokeDasharray="5 5" />

            <XAxis dataKey="month" stroke="#5550bd" />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="students"
              stroke="#FF7300"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="trainers" stroke="#A1E3CB" />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default Chart;

// const handleDateChange = (dates, dateStrings) => {
//   if (dates && dates.length === 2) {
//     const formattedStartDate = dates[0].format("YYYY-MM");
//     const formattedEndDate = dates[1].format("YYYY-MM");
//     setStartDate(formattedStartDate);
//     setEndDate(formattedEndDate);
//   } else {
//     setStartDate(null);
//     setEndDate(null);
//   }
// };

{
  /* <RangePicker
          picker="month"
          onChange={handleDateChange}
          locale={locale}
        /> */
}
