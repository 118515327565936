import "./ViewNotification.scss";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Button from "../../../Components/Button/Button";

import { DataGrid } from "@mui/x-data-grid";
import MessageModal from "../../../Components/Modals/MessageModal/MessageModal";
import { useState } from "react";
const ViewNotification = () => {
  const location = useLocation();
  const department = location.state;
  const isMobile = useMediaQuery("(max-width:767px)");
  const [openMessage, setOpenMessage] = useState(false);

  const rows = [
    {
      id: 1,
      name: "Eyad Adel",
      name1: "Eyad Adel",
      name2: "Eyad Adel",
      name3: "Eyad Adel",
      name4: "Eyad Adel",
      name5: "Eyad Adel",
      name6: "Eyad Adel",
    },
  ];

  const ViewChampionshipColumns = [
    {
      field: "id",
      headerName: "#",
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "name",
      headerName: "الأسئلة",
      minWidth: 240,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "name2",
      headerName: "اسماء المشتركين",
      minWidth: 240,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "name3",
      headerName: "مركز البطولة",
      minWidth: 240,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "name4",
      headerName: "الفئه العمرية",
      minWidth: 240,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "name5",
      headerName: "الشهادة ( عربي )",
      minWidth: 240,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "name6",
      headerName: "الشهادة ( انجليزي )",
      minWidth: 240,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
  ];

  return (
    <div className="edit">
      <div className="container">
        <div className="header">
          <h3>عرض البطولة</h3>
          <div
            onClick={() => {
              setOpenMessage(true);
            }}
            style={{ width: isMobile ? "100%" : "" }}
          >
            <Button
              text="حذف متعدد"
              variant="outlined"
              size={isMobile ? "full" : "small"}
            />
          </div>
        </div>
        <div className="data-grid">
          <DataGrid
            sx={{ minHeight: 600 }}
            columns={ViewChampionshipColumns}
            rows={rows.map((row, index) => {
              return { ...row, index: index + 1 };
            })}
            disableDensitySelector
            checkboxSelection
            disableRowSelectionOnClick
            disableVirtualization
            initialState={{
              ...rows.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50]}
          />
        </div>
      </div>
      {/* Message Modal */}
      <MessageModal
        message="هل انت متاكد من حذف هذا القسم من"
        extraMessage={"الاقسام الرئسية"}
        open={openMessage}
        onClose={() => setOpenMessage(false)}
      />
    </div>
  );
};

export default ViewNotification;
