import "./Support.scss";
import AddIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "./../../Assets/edit-icon.svg";
import DeleteIcon from "./../../Assets/delete-icon.svg";
import { Fragment, useEffect, useState } from "react";
import { Modal, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import { DataGrid } from "@mui/x-data-grid";
import CloseIcon from "./../../Assets/close-icon.svg";
import AttentionIcon from "./../../Assets/attention-icon.svg";
import { SupportColumns } from "../../SourceData";
import { showErrorMessage } from "../../Utils/showErrorMessage";
import Button from "../../Components/Button/Button";
import newRequest from "../../Utils/newRequest";
import Loader from "../../Components/Loader/Loader";

const Support = () => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [openMessage, setOpenMessage] = useState(false);
  const [rowId, setRowId] = useState("");
  const [loading, setLoading] = useState(false);
  const isTrainer = location?.pathname?.includes("/trainer");
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);

  useEffect(() => {
    getQuestions();
  }, [isTrainer]);

  const getQuestions = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(
        `/support_questions?type=${isTrainer ? "trainer" : "student"}`
      );
      setQuestions(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMultiDelete = async () => {
    if (selectedRowsIds.length === 0) {
      return message.warning("من فضك قم بتحديد الصفوف التي تريد حذفها اولاً");
    }
    setLoading(true);
    try {
      for (const rowId of selectedRowsIds) {
        await newRequest.delete(`/support_questions/${rowId}`);
      }
      await getQuestions();
      setSelectedRowsIds([]);
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenMessage(true);
    try {
      await newRequest.delete(`/support_questions/${rowId}`);
      await getQuestions();
      setRowId("");
      message.success("تم حذف السؤال بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setOpenMessage(false);
    }
  };

  const handleActive = async (id) => {
    setLoading(true);
    try {
      await newRequest.put(`/support_questions/${id}/activateToggle`);
      await getQuestions();
      message.success("تم التعديل بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setOpenMessage(false);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 280,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="cell-action" style={{ display: "flex" }}>
            {/* Edit Action */}
            <div
              className="cell-btn"
              onClick={() => {
                navigate(
                  isTrainer ? "/support/trainer/edit" : "/support/student/edit",
                  { state: params?.row }
                );
              }}
            >
              <img src={EditIcon} alt="" />
            </div>
            {/* Delete Action */}
            <div
              className="cell-btn"
              onClick={() => {
                setOpenMessage(true);
                setRowId(params.row.id);
              }}
            >
              <img src={DeleteIcon} alt="" />
            </div>

            <div
              className="cell-btn "
              onClick={() => handleActive(params.row.id)}
            >
              <Button
                text={params.row.active === true ? "ايقاف" : "تفعيل"}
                size={"small"}
                variant={params.row.active === true ? "fill" : "outlined"}
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <div className="support">
        <div className="container">
          <div className="spcial-header">
            <h4 className="title">
              {isTrainer ? "اسئلة المدرب" : "اسئلة الطالب"}
            </h4>
            <div className="actions-container">
              <Button
                text={"اضافة سؤال"}
                size={isMobile ? "full" : "medium"}
                variant={"outlined"}
                icon={<AddIcon />}
                path={
                  isTrainer
                    ? "/support/trainer/create"
                    : "/support/student/create"
                }
              />

              <Button
                text={"حذف متعدد"}
                size={isMobile ? "full" : "small"}
                variant={"outlined"}
                onClick={handleMultiDelete}
              />
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="data-grid">
              <DataGrid
                sx={{ minHeight: 600 }}
                columns={SupportColumns.concat(actionColumn)}
                rows={questions.map((row, index) => {
                  return { ...row, index: index + 1 };
                })}
                params={questions}
                disableDensitySelector
                disableRowSelectionOnClick
                disableVirtualization
                initialState={{
                  ...questions.initialState,
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelectedRowsIds(newRowSelectionModel);
                }}
                rowSelectionModel={selectedRowsIds}
              />
            </div>
          )}
        </div>
      </div>

      {/* Message Modal */}
      <Modal
        open={openMessage}
        onClose={() => {
          setOpenMessage(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div
            className="header"
            onClick={() => {
              setOpenMessage(false);
              setRowId("");
            }}
          >
            <img src={CloseIcon} alt="" />
          </div>
          <div className="body">
            <img src={AttentionIcon} alt="" />
            <h2>انتبة</h2>

            <h5>هل انت متاكد من حذف هذا السؤال من</h5>

            <h6>اسئلة الدعم</h6>
          </div>
          <div className="footer">
            <button className="action-btn" onClick={handleDelete}>
              حذف
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default Support;
