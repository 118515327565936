import "./App.css";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Main from "./Pages/Main/Main";
import Login from "./Pages/Login/Login";
import Home from "./Pages/Home/Home";
import Admins from "./Pages/Admins/Admins";
import Packages from "./Pages/Packages/Packages";
import Departments from "./Pages/Departments/Departments";
import Questions from "./Pages/Questions/Questions";
import Students from "./Pages/Students/Students";
import Trainers from "./Pages/Trainers/Trainers";
import Championships from "./Pages/Championships/Championships";
import Notifications from "./Pages/Notifications/Notifications";
import Contact from "./Pages/Contact/Contact";
import { useSelector } from "react-redux";
import EditQuestion from "./Pages/Questions/EditQuestion/EditQuestion";
import AddExcel from "./Pages/Questions/AddExcel/AddExcel";
import EditStudent from "./Pages/Students/EditStudent/EditStudent";
import ViewStudent from "./Pages/Students/ViewStudent/ViewStudent";
import EditDepartment from "./Pages/Departments/EditDepartment/EditDepartment";
import ViewDepartment from "./Pages/Departments/ViewDepartment/ViewDepartment";
import CreateDepartment from "./Pages/Departments/CreateDepartment/CreateDepartment";
import SubDepartments from "./Pages/SubDepartments/SubDepartments";

import ViewSubDepartment from "./Pages/SubDepartments/SubDepartments";
import CreateTrainer from "./Pages/Trainers/CreateTrainer/CreateTrainer";
import EditTrainer from "./Pages/Trainers/EditTrainer/EditTrainer";
import ViewTrainer from "./Pages/Trainers/ViewTrainer/ViewTrainer";
import CreateChampionship from "./Pages/Championships/CreateChampionship/CreateChampionship";
import CreateExam from "./Pages/Championships/CreateExam/CreateExam";
import ViewChampionship from "./Pages/Championships/ViewChampionship/ViewChampionship";
import ViewNotification from "./Pages/Notifications/ViewNotification/ViewNotification";

import ViewMessage from "./Pages/Support/ViewMessage/ViewMessage";
import AnswerMessage from "./Pages/Support/AnswerMessage/AnswerMessage";
import ViewContactMessage from "./Pages/Contact/ViewContactMessage/ViewContactMessage";
import AnswerContactMessage from "./Pages/Contact/AnswerContactMessage/AnswerContactMessage";
import CreateAdmin from "./Pages/Admins/CreateAdmin/CreateAdmin";
import EditAdmin from "./Pages/Admins/EditAdmin/EditAdmin";
import Profile from "./Pages/Profile/Profile";
import TermsAndConditions from "./Pages/Settings/TermsAndConditions/TermsAndConditions";
import SocialMediaLinks from "./Pages/Settings/SocialMediaLinks/SocialMediaLinks";
import ProfilePages from "./Pages/Settings/ProfilePages/ProfilePages";
import SliderImages from "./Pages/Settings/SliderImages/SliderImages";
import DownloadLink from "./Pages/Settings/DownloadLink/DownloadLink";
import Countries from "./Pages/Settings/Countries/Countries";
import StudentPoints from "./Pages/Settings/StudentPoints/StudentPoints";
import StudentRanges from "./Pages/Settings/StudentRanges/StudentRanges";
import CreateEditRange from "./Pages/Settings/StudentRanges/CreateEditRange/CreateEditRange";
import CreateEditPackage from "./Pages/Packages/CreateEditPackage/CreateEditPackage";
import PromoCode from "./Pages/Packages/PromoCode/PromoCode";
import CreateCode from "./Pages/Packages/PromoCode/CreateCode/CreateCode";
import Support from "./Pages/Support/Support";
import CreateEditSupportQuestion from "./Pages/Support/CreateEditSupportQuestion/CreateEditSupportQuestion";
import StudentsTrainersMessages from "./Pages/Support/StudentsTrainersMessages/StudentsTrainersMessages";
import CreateQuestion from "./Pages/Questions/CreateQuestion/CreateQuestion";
import StudentTrainersTermsAndConditions from "./Pages/Settings/TermsAndConditions/StudentTrainersTermsAndConditions/StudentTrainersTermsAndConditions";
import CreateEditTermsAndConditions from "./Pages/Settings/TermsAndConditions/CreateEditTermsAndConditions/CreateEditTermsAndConditions";
import TraniersStudentsSliders from "./Pages/Settings/SliderImages/TraniersStudentsSliders/TraniersStudentsSliders";
import CreateEditSlider from "./Pages/Settings/SliderImages/CreateEditSlider/CreateEditSlider";
import StudentsTrainersPages from "./Pages/Settings/ProfilePages/StudentsTrainersPages/StudentsTrainersPages";
import CreateEditProfilePage from "./Pages/Settings/ProfilePages/CreateEditProfilePage/CreateEditProfilePage";
import CreateEditCountry from "./Pages/Settings/Countries/CreateEditCountry/CreateEditCountry";
import Cities from "./Pages/Settings/Cities/Cities";
import CreateEditCity from "./Pages/Settings/Cities/CreateEditCities/CreateEditCity";
import Regions from "./Pages/Settings/Regions/Regions";
import CreateEditRegion from "./Pages/Settings/Regions/CreateEditRegion/CreateEditRegion";
import CreateEditStudent from "./Pages/Students/CreateEditStudent/CreateEditStudent";
import CreateNotification from "./Pages/Notifications/CreateNotification/CreateNotification";
import CreateEditSubDepartment from "./Pages/SubDepartments/CreateSubDepartment/CreateEditSubDepartment";
import LastChampionshipWinners from "./Pages/Home/LastChampionshipWinners/LastChampionshipWinners";
import LastLogin from "./Pages/Home/LastLogin/LastLogin";
import MostStudents from "./Pages/Home/MostStudents/MostStudents";
import ForgetPassword from "./Pages/ForgetPassword/ForgetPassword";

function App() {
  const currentUser = useSelector((state) => state.user.currentUser);

  const router = createBrowserRouter([
    {
      path: "/forget-password",
      element: !currentUser ? <ForgetPassword /> : <Navigate to="/" />,
    },
    {
      path: "/login",
      element: !currentUser ? <Login /> : <Navigate to="/" />,
    },
    {
      path: "/",
      element: currentUser ? <Main /> : <Navigate to="/login" />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/championship-winners",
          element: <LastChampionshipWinners />,
        },
        {
          path: "/last-login",
          element: <LastLogin />,
        },
        {
          path: "/most-students",
          element: <MostStudents />,
        },
        {
          path: "/admins",
          element: <Admins />,
        },
        {
          path: "/admins/create",
          element: <CreateAdmin />,
        },
        {
          path: "/admins/edit",
          element: <CreateAdmin />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/terms-and-conditions",
          element: <TermsAndConditions />,
        },
        {
          path: "/terms-and-conditions/students",
          element: <StudentTrainersTermsAndConditions />,
        },
        {
          path: "/terms-and-conditions/students/edit",
          element: <CreateEditTermsAndConditions />,
        },
        {
          path: "/terms-and-conditions/students/create",
          element: <CreateEditTermsAndConditions />,
        },
        {
          path: "/terms-and-conditions/trainers",
          element: <StudentTrainersTermsAndConditions />,
        },
        {
          path: "/terms-and-conditions/trainers/edit",
          element: <CreateEditTermsAndConditions />,
        },
        {
          path: "/terms-and-conditions/trainers/create",
          element: <CreateEditTermsAndConditions />,
        },
        {
          path: "/social-media-links",
          element: <SocialMediaLinks />,
        },
        {
          path: "/profile-pages",
          element: <ProfilePages />,
        },
        {
          path: "/profile-pages/students",
          element: <StudentsTrainersPages />,
        },
        {
          path: "/profile-pages/trainers",
          element: <StudentsTrainersPages />,
        },
        {
          path: "/profile-pages/students/edit",
          element: <CreateEditProfilePage />,
        },
        {
          path: "/profile-pages/students/create",
          element: <CreateEditProfilePage />,
        },
        {
          path: "/profile-pages/trainers/edit",
          element: <CreateEditProfilePage />,
        },
        {
          path: "/profile-pages/trainers/create",
          element: <CreateEditProfilePage />,
        },
        {
          path: "/slider-images",
          element: <SliderImages />,
        },
        {
          path: "/slider-images/students",
          element: <TraniersStudentsSliders />,
        },
        {
          path: "/slider-images/trainers",
          element: <TraniersStudentsSliders />,
        },

        {
          path: "/slider-images/trainers/edit",
          element: <CreateEditSlider />,
        },
        {
          path: "/slider-images/students/edit",
          element: <CreateEditSlider />,
        },
        {
          path: "/slider-images/trainers/create",
          element: <CreateEditSlider />,
        },
        {
          path: "/slider-images/students/create",
          element: <CreateEditSlider />,
        },
        {
          path: "/download-link",
          element: <DownloadLink />,
        },
        {
          path: "/countries",
          element: <Countries />,
        },
        {
          path: "/cities",
          element: <Cities />,
        },
        {
          path: "/cities/create",
          element: <CreateEditCity />,
        },
        {
          path: "/cities/edit",
          element: <CreateEditCity />,
        },
        {
          path: "/regions",
          element: <Regions />,
        },
        {
          path: "/regions/create",
          element: <CreateEditRegion />,
        },
        {
          path: "/regions/edit",
          element: <CreateEditRegion />,
        },
        {
          path: "/countries/create",
          element: <CreateEditCountry />,
        },
        {
          path: "/countries/edit",
          element: <CreateEditCountry />,
        },
        {
          path: "/student-points",
          element: <StudentPoints />,
        },
        {
          path: "/student-ranges",
          element: <StudentRanges />,
        },
        {
          path: "/student-ranges/edit",
          element: <CreateEditRange />,
        },
        {
          path: "/student-ranges/create",
          element: <CreateEditRange />,
        },
        {
          path: "/packages/trainer",
          element: <Packages />,
        },
        {
          path: "/packages/student",
          element: <Packages />,
        },
        {
          path: "/packages/trainer/edit",
          element: <CreateEditPackage />,
        },
        {
          path: "/packages/student/edit",
          element: <CreateEditPackage />,
        },
        {
          path: "/packages/trainer/create",
          element: <CreateEditPackage />,
        },
        {
          path: "/packages/student/create",
          element: <CreateEditPackage />,
        },
        {
          path: "/packages/promo-code",
          element: <PromoCode />,
        },
        {
          path: "/packages/promo-code/create",
          element: <CreateCode />,
        },
        {
          path: "/packages/promo-code/edit",
          element: <CreateCode />,
        },
        {
          path: "/departments",
          element: <Departments />,
        },
        {
          path: "/departments/create",
          element: <CreateDepartment />,
        },
        {
          path: "/departments/view",
          element: <ViewDepartment />,
        },
        {
          path: "/departments/edit",
          element: <EditDepartment />,
        },
        {
          path: "/sub-departments",
          element: <SubDepartments />,
        },
        {
          path: "/sub-departments/create",
          element: <CreateEditSubDepartment />,
        },
        {
          path: "/sub-departments/edit",
          element: <CreateEditSubDepartment />,
        },
        {
          path: "/sub-departments/view",
          element: <ViewSubDepartment />,
        },
        {
          path: "/questions",
          element: <Questions />,
        },
        {
          path: "/questions/add-excel",
          element: <AddExcel />,
        },
        {
          path: "/questions/create",
          element: <CreateQuestion />,
        },
        {
          path: "/questions/edit",
          element: <EditQuestion />,
        },
        {
          path: "/students",
          element: <Students />,
        },
        {
          path: "/students/free-package",
          element: <Students />,
        },
        {
          path: "/students/online-students",
          element: <Students />,
        },
        {
          path: "/students/most-trainings",
          element: <Students />,
        },
        {
          path: "/students/create",
          element: <CreateEditStudent />,
        },
        {
          path: "/students/edit",
          element: <CreateEditStudent />,
        },
        {
          path: "/students/view",
          element: <ViewStudent />,
        },
        {
          path: "/trainers",
          element: <Trainers />,
        },
        {
          path: "/trainers/special-trainers",
          element: <Trainers />,
        },
        {
          path: "/trainers/free-package",
          element: <Trainers />,
        },
        {
          path: "/trainers/create",
          element: <CreateTrainer />,
        },
        {
          path: "/trainers/edit",
          element: <CreateTrainer />,
        },
        {
          path: "/trainers/view",
          element: <ViewTrainer />,
        },
        {
          path: "/trainers/chats",
          element: <ViewTrainer />,
        },
        {
          path: "/championships",
          element: <Championships />,
        },
        {
          path: "/championships/view",
          element: <ViewChampionship />,
        },
        {
          path: "/championships/create",
          element: <CreateChampionship />,
        },
        {
          path: "/championships/create-exam",
          element: <CreateExam />,
        },
        {
          path: "/notifications",
          element: <Notifications />,
        },
        {
          path: "/notifications/view",
          element: <ViewNotification />,
        },
        {
          path: "/notifications/create",
          element: <CreateNotification />,
        },
        {
          path: "/support/student",
          element: <Support />,
        },
        {
          path: "/support/trainer",
          element: <Support />,
        },
        {
          path: "/support/trainer/create",
          element: <CreateEditSupportQuestion />,
        },
        {
          path: "/support/student/create",
          element: <CreateEditSupportQuestion />,
        },
        {
          path: "/support/trainer/edit",
          element: <CreateEditSupportQuestion />,
        },
        {
          path: "/support/student/edit",
          element: <CreateEditSupportQuestion />,
        },
        {
          path: "/support-student-messages",
          element: <StudentsTrainersMessages />,
        },
        {
          path: "/support-trainer-messages",
          element: <StudentsTrainersMessages />,
        },
        {
          path: "/support/student-messages/view",
          element: <ViewMessage />,
        },
        {
          path: "/support/trainer-messages/view",
          element: <ViewMessage />,
        },
        {
          path: "/support/student-messages/note",
          element: <AnswerMessage />,
        },
        {
          path: "/support/trainer-messages/note",
          element: <AnswerMessage />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "/contact/view",
          element: <ViewContactMessage />,
        },
        {
          path: "/contact/answer",
          element: <AnswerContactMessage />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
