import "./Search.scss";
import { Modal } from "@mui/material";
import FilterIcon from "./../../../Assets/filter-icon.svg";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect, useState } from "react";
import Button from "../../Button/Button";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import Loader from "../../Loader/Loader";

const INITIAL_FILTERS = {
  country: "",
  city: "",
  region: "",
};

const Search = (props) => {
  const [country, setCountry] = useState(false);
  const [city, setCity] = useState(false);
  const [region, setRegion] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    country: "",
    city: "",
    region: "",
  });

  useEffect(() => {
    if (props.open) {
      getCountries();
    }
  }, [props.open]);

  useEffect(() => {
    const getCities = async () => {
      try {
        const citiesResponce = await newRequest.get(
          `/cities?country_id=${filters.country}`,
          {
            headers: {
              "Accept-Language": "ar",
            },
          }
        );
        setCities(citiesResponce?.data?.data);
      } catch (error) {
        showErrorMessage(error);
      } finally {
      }
    };

    getCities();
  }, [filters.country]);

  useEffect(() => {
    const getRegions = async () => {
      try {
        const regionsResponce = await newRequest.get(
          `/regions?city_id=${filters?.city}`,
          {
            headers: {
              "Accept-Language": "ar",
            },
          }
        );
        setRegions(regionsResponce?.data?.data);
      } catch (error) {
        showErrorMessage(error);
      } finally {
      }
    };

    getRegions();
  }, [filters.city]);

  const getCountries = async () => {
    setLoading(true);
    try {
      const countriesResponce = await newRequest.get(`/countries`, {
        headers: {
          "Accept-Language": "ar",
        },
      });
      setCountries(countriesResponce?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleSubmit = () => {
    const activeFilters = {};
    if (country) activeFilters.country = filters.country;
    if (city) activeFilters.city = filters.city;
    if (region) activeFilters.region = filters.region;

    props.onFilterSubmit(activeFilters);
    props.onClose();
  };

  return (
    <Modal className="modal" open={props.open} onClose={props?.onClose}>
      <div className="container">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="header">
              <img src={FilterIcon} alt="" />
              <h3>البحث في الطلاب المسجلين</h3>
            </div>

            <div className="body">
              <div className="item">
                <input
                  type="checkbox"
                  checked={country}
                  onChange={() => setCountry(!country)}
                />
                <select
                  className={country ? "checked" : ""}
                  value={filters.country}
                  onChange={(e) =>
                    handleFilterChange("country", e.target.value)
                  }
                >
                  <option value="" disabled>
                    الدولة
                  </option>
                  {countries.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="item">
                <input
                  type="checkbox"
                  checked={city}
                  onChange={() => setCity(!city)}
                />
                <select
                  className={city ? "checked" : ""}
                  value={filters.city}
                  onChange={(e) => handleFilterChange("city", e.target.value)}
                >
                  <option value="" disabled>
                    محافظة
                  </option>
                  {cities.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="item">
                <input
                  type="checkbox"
                  checked={region}
                  onChange={() => setRegion(!region)}
                />
                <select
                  className={region ? "checked" : ""}
                  value={filters.region}
                  onChange={(e) => handleFilterChange("region", e.target.value)}
                >
                  <option value="" disabled>
                    المنطقة
                  </option>
                  {regions.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* <div className="item">
            <input
              type="checkbox"
              checked={status}
              onChange={() => setStatus(!status)}
            />
            <select name="" id="" className={status ? "checked" : ""}>
              <option value="1">حالة الحساب</option>
            </select>
          </div>

          <div className="item">
            <input
              type="checkbox"
              checked={age}
              onChange={() => setAge(!age)}
            />
            <select name="" id="" className={age ? "checked" : ""}>
              <option value="1">الفئة العمرية</option>
            </select>
          </div>

          <div className="item">
            <input
              type="checkbox"
              checked={outstandingStudents}
              onChange={() => setOutstandingStudents(!outstandingStudents)}
            />
            <select
              name=""
              id=""
              className={outstandingStudents ? "checked" : ""}
            >
              <option value="1">الطلاب المتميزين</option>
            </select>
          </div>

          <div className="item">
            <input
              type="checkbox"
              checked={registerType}
              onChange={() => setRegisterType(!registerType)}
            />
            <select name="" id="" className={registerType ? "checked" : ""}>
              <option value="1">طريقة التسجيل</option>
            </select>
          </div>

          <div className="item">
            <input
              type="checkbox"
              checked={level}
              onChange={() => setLevel(!level)}
            />
            <select name="" id="" className={level ? "checked" : ""}>
              <option value="1">المستوى</option>
            </select>
          </div>

          <div className="item">
            <input
              type="checkbox"
              checked={packge}
              onChange={() => setPackge(!packge)}
            />
            <select name="" id="" className={packge ? "checked" : ""}>
              <option value="1">اسم الباقة</option>
            </select>
          </div>
*/}
            </div>

            <div className="footer">
              <Button
                text="بحث"
                variant="fill"
                size="medium"
                onClick={handleSubmit}
              />
              <Button
                text="الغاء"
                variant="outlined"
                size="medium"
                onClick={() => {
                  const resetFilters = { ...INITIAL_FILTERS };
                  setFilters(resetFilters);
                  setRegion(false);
                  setCountry(false);
                  setCity(false);
                  props.onFilterSubmit(resetFilters);
                  props?.onClose();
                }}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default Search;
