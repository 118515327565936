import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Modal, TextField, useMediaQuery } from "@mui/material";
import { message } from "antd";
import AddIcon from "@mui/icons-material/AddOutlined";
import { debounce } from "lodash";

import "./Students.scss";
import { StudentsColumns } from "../../SourceData";
import Button from "../../Components/Button/Button";
import Loader from "../../Components/Loader/Loader";
import newRequest from "../../Utils/newRequest";
import { showErrorMessage } from "../../Utils/showErrorMessage";

import FilterIcon from "./../../Assets/filter-icon.svg";
import CloseIcon from "./../../Assets/close-icon.svg";
import AttentionIcon from "./../../Assets/attention-icon.svg";
import EditIcon from "./../../Assets/edit-icon.svg";
import ViewIcon from "./../../Assets/details-icon.svg";
import DeleteIcon from "./../../Assets/delete-icon.svg";
import Search from "../../Components/Modals/Search/Search";

const Students = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:767px)");

  const [students, setStudents] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [rowId, setRowId] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [query, setQuery] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const onlineStudents = location.pathname.includes("/online-students");
  const mostTrainingStudents = location.pathname.includes("/most-trainings");
  const isFreePackage = location.pathname.includes("/free-package");

  const getEndpoint = useCallback(() => {
    if (isFreePackage) return `/subscription_students`;
    if (onlineStudents) return `/students?online=1`;
    if (mostTrainingStudents) return `/most_trainings_students`;
    return `/students`;
  }, [isFreePackage, onlineStudents, mostTrainingStudents]);

  const getStudents = useCallback(
    async (searchQuery = "") => {
      setLoading(true);
      try {
        let endpoint = `${getEndpoint()}?page=${
          paginationModel.page + 1
        }&rows=${paginationModel.pageSize}`;

        if (searchQuery.trim() !== "") {
          endpoint += `&search=${searchQuery}`;
        }

        if (filters.country) endpoint += `&country_id=${filters.country}`;
        if (filters.city) endpoint += `&city_id=${filters.city}`;
        if (filters.region) endpoint += `&region_id=${filters.region}`;

        const res = await newRequest.get(endpoint);
        setStudents(res?.data?.data?.data);
        setTotalRows(res?.data?.data?.meta?.total);
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    },
    [getEndpoint, paginationModel, filters]
  );

  const debouncedSearch = useMemo(
    () => debounce((searchQuery) => getStudents(searchQuery), 300),
    [getStudents]
  );

  useEffect(() => {
    debouncedSearch(query);
    return () => debouncedSearch.cancel();
  }, [query, debouncedSearch]);

  useEffect(() => {
    getStudents();
  }, [getStudents, paginationModel, filters]);

  const handleMultiDelete = async () => {
    if (selectedRowsIds.length === 0) {
      return message.warning("من فضلك قم بتحديد الصفوف التي تريد حذفها أولاً");
    }
    setLoading(true);
    try {
      await newRequest.post("/users_multi_delete", {
        ids: selectedRowsIds,
      });
      await getStudents();
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
      setSelectedRowsIds([]);
    }
  };

  const handleActive = async (id) => {
    setLoading(true);
    try {
      await newRequest.put(`/users/${id}/activateToggle`);
      getStudents();
      message.success("تم التعديل حالة المستخدم بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenMessage(false);
    try {
      await newRequest.delete(`/users/${rowId}`);
      getStudents();
      message.success("تم حذف الطالب بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setRowId("");
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 300,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <div className="cell-action" style={{ display: "flex" }}>
          <div
            className="cell-btn"
            onClick={() => navigate("/students/edit", { state: params?.row })}
          >
            <img src={EditIcon} alt="" />
          </div>
          <div
            className="cell-btn"
            onClick={() => navigate("/students/view", { state: params?.row })}
          >
            <img src={ViewIcon} alt="" />
          </div>
          <div
            className="cell-btn"
            onClick={() => {
              setOpenMessage(true);
              setRowId(params.row.id);
            }}
          >
            <img src={DeleteIcon} alt="" />
          </div>
          <div className="cell-btn" onClick={() => handleActive(params.row.id)}>
            <Button
              text={params.row.active === 1 ? "ايقاف" : "تفعيل"}
              variant={params.row.active === 1 ? "fill" : "outlined"}
              size={"small"}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="students">
        <div className="container">
          <div className="spcial-header">
            <h4 className="title">الطلاب</h4>
            <div className="search">
              <TextField
                id="outlined-search"
                type="search"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                sx={{ width: "400px" }}
              />
              <div
                className="filter-container"
                onClick={() => setOpenFilter(true)}
              >
                <img src={FilterIcon} alt="" />
              </div>
            </div>
            <div className="actions-container">
              <Button
                size={isMobile ? "fit" : "large"}
                variant="fill"
                text="اضافة طالب جديد"
                icon={<AddIcon />}
                path="/students/create"
              />
              <Button
                size={isMobile ? "small" : "medium"}
                variant="outlined"
                text="حذف متعدد"
                onClick={handleMultiDelete}
              />
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="data-grid">
              <DataGrid
                rows={students.map((row, index) => ({
                  ...row,
                  index: index + 1,
                }))}
                columns={StudentsColumns.concat(actionColumn)}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[10, 50, 100]}
                rowCount={totalRows}
                paginationMode="server"
                autoHeight
                disableVirtualization
                checkboxSelection
                onRowSelectionModelChange={setSelectedRowsIds}
                rowSelectionModel={selectedRowsIds}
              />
            </div>
          )}
        </div>
      </div>

      <Modal
        open={openMessage}
        onClose={() => {
          setOpenMessage(false);
          setRowId("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div
            className="header"
            onClick={() => {
              setOpenMessage(false);
              setRowId("");
            }}
          >
            <img src={CloseIcon} alt="" />
          </div>
          <div className="body">
            <img src={AttentionIcon} alt="" />
            <h2>انتبه</h2>
            <h5>هل أنت متأكد من حذف هذا الطالب من</h5>
            <h6>الطلاب المسجلين</h6>
          </div>
          <div className="footer">
            <button className="action-btn" onClick={handleDelete}>
              حذف
            </button>
          </div>
        </div>
      </Modal>

      <Search
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        onFilterSubmit={setFilters}
      />
    </>
  );
};

export default Students;
