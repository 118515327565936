// CreateExam.js
import "./CreateExam.scss";
import { useState, useEffect } from "react";
import Button from "../../../Components/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import newRequest from "../../../Utils/newRequest";
import { message } from "antd";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import Loader from "../../../Components/Loader/Loader";
import LevelExam from "./LevelExam/LevelExam";

const CreateExam = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { levels, id, question_type_id } = location?.state;
  const [loading, setLoading] = useState(false);
  const [levelExams, setLevelExams] = useState([]);

  useEffect(() => {
    setLevelExams(
      levels.map((level) => ({
        exam: level.id,
        values: {
          questions_count: "",
          question_speed: "",
          questions_count_2num: "",
          questions_count_3num: "",
          questions_count_4num: "",
          questions_count_5num: "",
          questions_count_6num: "",
          questions_count_7num: "",
          questions_count_8num: "",
          questions_count_9num: "",
          questions_count_10num: "",
        },
      }))
    );
  }, [levels]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (levelExams.length === 0) {
      message.warning("عفواً لا يمكن اضافة امتحان, قم بوضع مستويات");
      return;
    }

    let isValid = true;
    const formattedLevels = [];

    levelExams.forEach((exam) => {
      const { values } = exam;
      const questionsCount = parseInt(values.questions_count) || 0;
      const sumOfQuestionCounts = [
        "questions_count_2num",
        "questions_count_3num",
        "questions_count_4num",
        "questions_count_5num",
        "questions_count_6num",
        "questions_count_7num",
        "questions_count_8num",
        "questions_count_9num",
        "questions_count_10num",
      ].reduce((sum, key) => sum + (parseInt(values[key]) || 0), 0);

      if (questionsCount !== sumOfQuestionCounts) {
        const levelTitle =
          levels.find((level) => level.id === exam.exam)?.title || "Unknown";

        message.warning(
          `عدد ارقام الاسئلة في امتحان  ${levelTitle} لا يساوي عدد الاسئلة ${sumOfQuestionCounts} `
        );
        isValid = false;
      }

      if (isValid) {
        const formattedValues = Object.entries(values)
          .filter(
            ([key, value]) => key.startsWith("questions_count_") && value !== ""
          )
          .map(([key, value]) => ({ [key]: value }));

        formattedLevels.push({
          level_id: exam.exam,
          questions_count: parseInt(values.questions_count),
          question_speed: parseInt(values.question_speed),
          values: formattedValues,
        });
      }
    });

    if (isValid) {
      setLoading(true);
      try {
        for (const exam of levelExams) {
          const requestData = {
            ...exam.values,
            type_level_id: exam.exam,
            championship_id: id,
          };
          await newRequest.post("/add_level_exam", requestData);
        }
        message.success("تم اضافة الامتحانات بنجاح");
        navigate("/championships");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (examId, name, value) => {
    setLevelExams((prevExams) =>
      prevExams.map((exam) =>
        exam.exam === examId
          ? { ...exam, values: { ...exam.values, [name]: value } }
          : exam
      )
    );
  };

  return (
    <div className="create">
      <div className="container">
        <h3 className="title">اضافة امتحان لكل مستوي</h3>
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit}>
            {levels?.map((item, index) => (
              <LevelExam
                key={item.id}
                item={item}
                questionType={question_type_id}
                examValues={levelExams[index]?.values || {}}
                onChange={(name, value) => handleChange(item.id, name, value)}
              />
            ))}

            <div className="form-actions">
              <Button text="اضافة" size="large" variant="fill" type="submit" />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateExam;
