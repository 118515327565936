import "./StudentFriends.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import newRequest from "../../../../Utils/newRequest";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import moment from "moment";

const StudentFriends = (props) => {
  const user = props?.user;

  const [friends, setFriends] = useState([]);

  useEffect(() => {
    getFriends();
  }, []);

  const getFriends = async () => {
    try {
      const res = await newRequest.get(`/friends/${user?.id}`);
      setFriends(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        الأصدقاء المميزين
      </AccordionSummary>
      <div className="close-friends container">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>الاسم</th>
              <th>رقم الموبايل</th>
              <th>المستويات المميز بها</th>
              <th>العمر</th>
            </tr>
          </thead>
          <tbody>
            {friends?.map((row, index) => (
              <tr key={index + 1}>
                <td>{index + 1}</td>
                <td>{row?.name}</td>
                <td>{row?.phone}</td>
                <td>جميع الاقسام</td>
                <td>{moment(row?.birth_date).format("YYYY/MM/DD")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Accordion>
  );
};

export default StudentFriends;
