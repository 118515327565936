import "./CreateEditTermsAndConditions.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControl, TextField } from "@mui/material";
import Button from "../../../../Components/Button/Button";
import { useState } from "react";
import newRequest from "../../../../Utils/newRequest";
import { message } from "antd";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import Loader from "../../../../Components/Loader/Loader";

const CreateEditTermsAndConditions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const editTerm = location.state;
  const isTrainer = location.pathname.includes("/trainer");
  const isCreate = location.pathname.includes("/create");
  const [loading, setLoading] = useState(false);
  const [updatedTerm, setUpdatedTerm] = useState({
    title: {
      ar: editTerm?.title || "",
    },
    content: {
      ar: editTerm?.content || "",
    },
    country_id: editTerm?.country_id || "1",
    type: editTerm?.type,
  });

  const [newTerm, setNewTerm] = useState({
    title: {
      ar: "",
      en: "",
    },
    content: {
      ar: "",
      en: "",
    },
    country_id: "1",
    type: isTrainer ? "trainer" : "student",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [field, language] = name.split(".");
    if (isCreate) {
      setNewTerm((prev) => ({
        ...prev,
        [field]: {
          ...prev[field],
          [language]: value,
        },
      }));
    } else {
      setUpdatedTerm((prev) => ({
        ...prev,
        [field]: {
          ...prev[field],
          [language]: value,
        },
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    if (isCreate) {
      try {
        await newRequest.post("/terms", {
          ...newTerm,
        });
        setNewTerm({
          title: {
            ar: "",
            en: "",
          },
          content: {
            ar: "",
            en: "",
          },
        });
        message.success("تم اضافة الشرط بنجاح");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await newRequest.put(`/terms/${editTerm?.id}`, {
          ...updatedTerm,
        });
        setNewTerm({
          title: {
            ar: updatedTerm?.title?.ar || "",
          },
          content: {
            ar: updatedTerm?.content?.ar || "",
          },
          country_id: updatedTerm?.country_id || "",
        });
        message.success("تم تعديل الشرط بنجاح");
        navigate(
          `/terms-and-conditions/${isTrainer ? "trainers" : "students"}`
        );
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="terms-and-conditions-edit">
      <div className="container">
        <h4 className="title">
          اضافة الشروط والاحكام ( {isTrainer ? "المدرب" : "الطالب"} )
        </h4>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">العنوان</label>
                <TextField
                  placeholder="ادخل العنوان"
                  fullWidth
                  className="form-input"
                  required
                  name="title.ar"
                  value={isCreate ? newTerm?.title?.ar : updatedTerm?.title?.ar}
                  onChange={handleChange}
                />
              </FormControl>
              {isCreate && (
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">العنوان بالانجليزية</label>
                  <TextField
                    placeholder="ادخل العنوان"
                    fullWidth
                    className="form-input"
                    required
                    name="title.en"
                    value={
                      isCreate ? newTerm?.title?.en : updatedTerm?.title?.en
                    }
                    onChange={handleChange}
                  />
                </FormControl>
              )}
            </div>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">النص ( باللغة العربية )</label>
                <textarea
                  name="content.ar"
                  value={
                    isCreate ? newTerm?.content?.ar : updatedTerm?.content?.ar
                  }
                  onChange={handleChange}
                  placeholder="النص"
                  // rows={5}
                  style={{ height: "160px" }}
                ></textarea>
              </FormControl>
              {isCreate && (
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">
                    النص ( باللغة الانجليزية )
                  </label>
                  <textarea
                    name="content.en"
                    value={
                      isCreate ? newTerm?.content?.en : updatedTerm?.content?.en
                    }
                    onChange={handleChange}
                    placeholder="النص"
                    // rows={5}
                    style={{ height: "160px" }}
                  ></textarea>
                </FormControl>
              )}
            </div>

            <div className="action-control">
              <Button
                text={isCreate ? "اضافة" : "تعديل"}
                variant={"fill"}
                size={"small"}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateEditTermsAndConditions;
