import "./CreateEditProfilePage.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControl, TextField, useMediaQuery } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUploadOutlined";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { useState } from "react";
import Button from "../../../../Components/Button/Button";
import newRequest from "../../../../Utils/newRequest";
import { message } from "antd";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import Loader from "../../../../Components/Loader/Loader";

const CreateEditProfilePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const page = location.state;
  const isTrainer = location.pathname.includes("trainer");
  const isCreate = location.pathname.includes("create");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [editPage, setEditPage] = useState({
    title: {
      ar: page?.title || "",
    },
    desc: {
      ar: page?.desc || "",
    },
    type: isTrainer ? "trainer" : "student",
  });

  const [newPage, setNewPage] = useState({
    title: {
      ar: "",
      en: "",
    },
    desc: {
      ar: "",
      en: "",
    },
    type: isTrainer ? "trainer" : "student",
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    const [property, language] = name.split(".");
    if (isCreate) {
      setNewPage((prevState) => ({
        ...prevState,
        [property]: {
          ...prevState[property],
          [language]: value,
        },
      }));
    } else {
      setEditPage((prevState) => ({
        ...prevState,
        [property]: {
          ...prevState[property],
          [language]: value,
        },
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let requestHeaders = { "Content-Type": "multipart/form-data" };
    let requestData = {};

    if (isCreate) {
      try {
        await newRequest.post(
          `/intro_pages`,
          { ...newPage, image },
          {
            headers: requestHeaders,
          }
        );
        message.success(
          isCreate ? "تم اضافة الصفحة بنجاح" : "تم تعديل الصفحة بنجاح"
        );
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setImage(null);
        setNewPage({
          title: {
            ar: "",
            en: "",
          },
          desc: {
            ar: "",
            en: "",
          },
          type: isTrainer ? "trainer" : "student",
        });
        setLoading(false);
      }
    } else {
      if (image) {
        requestData = { ...editPage, image: image, _method: "PUT" };
        requestHeaders = { "Content-Type": "multipart/form-data" };
      } else {
        requestData = { ...editPage, _method: "PUT" };
        requestHeaders = { "Content-Type": "multipart/form-data" };
      }
      try {
        const res = await newRequest.post(
          `/intro_pages/${page?.id}`,
          requestData,
          {
            headers: requestHeaders,
          }
        );

        setImage(res?.data?.data?.image);
        message.success(
          isCreate ? "تم اضافة الصفحة بنجاح" : "تم تعديل الصفحة بنجاح"
        );
        navigate(`/profile-pages/${isTrainer ? "trainers" : "students"}`);
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="edit-profile-page">
      <div className="container">
        <div className="header">
          <h3 className="title">
            {isTrainer
              ? `${isCreate ? "انشاء" : "تعديل"} صفحة تعريفية ( للمدرب )`
              : `${isCreate ? "انشاء" : "تعديل"} صفحة تعريفية ( للطالب )`}
          </h3>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="file-group">
              {(image || page?.image) && (
                <img
                  src={
                    (image || page?.image) instanceof File
                      ? URL.createObjectURL(image || page?.image)
                      : image || page?.image
                  }
                  alt=""
                />
              )}

              <div className="form-control">
                <label htmlFor="file" className="file-label">
                  <FileUploadIcon className="icon" />
                  {isCreate ? " تحميل الصورة" : " تعديل الصورة"}
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">العنوان بالعربية</label>
                <TextField
                  placeholder="ادخل العنوان"
                  fullWidth
                  className="form-input"
                  required
                  name="title.ar"
                  onChange={handleChange}
                  value={isCreate ? newPage?.title?.ar : editPage?.title?.ar}
                />
              </FormControl>
              {isCreate && (
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">العنوان بالانجليزية</label>
                  <TextField
                    placeholder="ادخل العنوان"
                    fullWidth
                    className="form-input"
                    required
                    name="title.en"
                    onChange={handleChange}
                  />
                </FormControl>
              )}
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الوصف بالعربية</label>
                <TextField
                  placeholder="ادخل الوصف"
                  fullWidth
                  className="form-input"
                  required
                  name="desc.ar"
                  onChange={handleChange}
                  value={isCreate ? newPage?.desc?.ar : editPage?.desc?.ar}
                />
              </FormControl>
              {isCreate && (
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">الوصف بالانجليزية</label>
                  <TextField
                    placeholder="ادخل الوصف"
                    fullWidth
                    className="form-input"
                    required
                    name="desc.en"
                    onChange={handleChange}
                  />
                </FormControl>
              )}
            </div>

            <div className="action-control">
              <Button
                text={isCreate ? "اضافة" : "تعديل"}
                variant={"fill"}
                size={"small"}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateEditProfilePage;
