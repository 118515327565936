// export const getRemainingDays = (startDate, endDate) => {
//   const start = new Date(startDate);
//   const end = new Date(endDate);
//   const timeDiff = end.getTime() - start.getTime();
//   const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
//   return diffDays;
// };

import moment from "moment";
export const getRemainingDays = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  if (!start.isValid() || !end.isValid()) {
    return "Invalid date";
  }
  // const daysDifference = end.diff(start, "days") + 1;
  const daysDifference = end.diff(start, "days");
  return daysDifference;
};
