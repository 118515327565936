import "./CreateQuestion.scss";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import Button from "../../../Components/Button/Button";
import { useEffect, useState } from "react";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../../Components/Loader/Loader";

const CreateQuestion = () => {
  const [numbers, setNumbers] = useState(Array(10).fill(""));
  const [questionType, setQuestionType] = useState("1");
  const [mainCategories, setMainCategories] = useState([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState("1");
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [answer, setAnswer] = useState("");
  const [firstChoice, setFirstChoice] = useState("");
  const [secondChoice, setSecondChoice] = useState("");
  const [loading, setLoading] = useState(false);

  //  القسم الرئيسي/المستويات
  useEffect(() => {
    const getMainCategories = async () => {
      try {
        const mainCategoriesResponce = await newRequest.get(
          `type_levels?training_type_id=${questionType}`
        );
        setMainCategories(mainCategoriesResponce?.data?.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        showErrorMessage(error);
      }
    };
    getMainCategories();
  }, [questionType]);

  // الاقسام الفرعية
  useEffect(() => {
    const getSubCategories = async () => {
      try {
        const subCategoriesResponce = await newRequest.get(
          `/level_categories?type_level_id=${selectedMainCategory}`
        );
        setSubCategories(subCategoriesResponce?.data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showErrorMessage(error);
      }
    };
    getSubCategories();
  }, [selectedMainCategory]);

  const handleInputChange = (index, value) => {
    const newValues = [...numbers];
    newValues[index] = value;
    setNumbers(newValues);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (numbers.every((num) => num === "")) {
      return message.error("عفواً قم بادخال ارقام السؤال !");
    }
    setLoading(true);
    const filterdNumbers = numbers.filter((item) => item !== "");
    try {
      await newRequest.post("/questions", {
        training_type_id: questionType,
        type_level_id: selectedMainCategory,
        level_category_id: selectedSubCategory,
        numbers_count: filterdNumbers?.length,
        answer: answer,
        numbers: filterdNumbers,
        choices: [firstChoice, secondChoice],
      });
      setSelectedMainCategory("");
      setQuestionType("");
      setSelectedSubCategory("");
      setAnswer("");
      setNumbers(Array(10).fill(""));
      setFirstChoice("");
      setSecondChoice("");
      setLoading(false);
      message.success("تم اضافة السؤال بنجاح");
    } catch (error) {
      setLoading(false);
      showErrorMessage(error);
    }
  };

  return (
    <div className="add-question">
      <div className="container">
        <h4 className="title">اضافة السؤال</h4>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              {numbers.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  className="add-input"
                  value={value}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
              ))}
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="aria-label">نوع السؤال</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  className="select"
                  value={questionType}
                  onChange={(e) => setQuestionType(e.target.value)}
                >
                  <MenuItem value="1">عام</MenuItem>
                  <MenuItem value="2">تفصيلي</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="aria-label">القسم الرئيسي/المستويات</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  className="select"
                  value={selectedMainCategory}
                  onChange={(e) => setSelectedMainCategory(e.target.value)}
                >
                  {mainCategories?.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="aria-label">القسم الفرعي</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  required
                  value={selectedSubCategory}
                  onChange={(e) => setSelectedSubCategory(e.target.value)}
                >
                  {subCategories.slice(0, 3).map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">عدد الارقام في السؤال</label>
                <TextField
                  required
                  fullWidth
                  id="fullWidth"
                  className="form-input"
                  pattern="[0-9]*"
                  value={numbers?.filter((item) => item !== "")?.length}
                  // onChange={(e) => setQuestionNumbers(e.target.value)}
                />
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الاجابة الصحيحة</label>
                <TextField
                  fullWidth
                  className="form-input"
                  required
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                />
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الاختيار الاول</label>
                <TextField
                  fullWidth
                  className="form-input"
                  required
                  value={firstChoice}
                  onChange={(e) => setFirstChoice(e.target.value)}
                />
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الاختيار الثاني</label>
                <TextField
                  fullWidth
                  className="form-input"
                  required
                  value={secondChoice}
                  onChange={(e) => setSecondChoice(e.target.value)}
                />
              </FormControl>
            </div>

            <div className="action-control">
              <Button text={"اضافة"} variant={"fill"} size={"small"} />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateQuestion;
