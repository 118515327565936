import "./CreateEditSupportQuestion.scss";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import Button from "../../../Components/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../Components/Loader/Loader";
import { message } from "antd";

const CreateEditSupportQuestion = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const editQuestion = location.state;
  const isTrainer = location?.pathname?.includes("/trainer");
  const isCreate = location.pathname.includes("/create");
  const [question, setQuestion] = useState("");
  const [loading, setLoading] = useState(false);
  const [newQuestion, setNewQuestion] = useState({
    question: editQuestion?.question || "",
    type: isTrainer ? "trainer" : "student",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (isCreate) {
      try {
        await newRequest.post("/support_questions", {
          ...newQuestion,
        });
        setNewQuestion({
          question: "",
          type: isTrainer ? "trainer" : "student",
        });

        message.success("تم اضافة السؤال بنجاح");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await newRequest.put(`/support_questions/${editQuestion?.id}`, {
          ...newQuestion,
        });
        setNewQuestion({
          question: newQuestion?.question || "",
        });
        message.success("تم تعديل السؤال بنجاح");
        navigate(`/support/${isTrainer ? "trainers" : "students"}`);
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    setNewQuestion((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  return (
    <div className="create-support">
      <div className="container">
        <h4 className="title">اضافة سؤال ( {isTrainer ? "مدرب" : "طالب"} )</h4>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">السؤال</label>
                <TextField
                  placeholder="ادخل السؤال"
                  fullWidth
                  className="form-input"
                  required
                  name="question"
                  value={newQuestion?.question}
                  onChange={handleChange}
                />
              </FormControl>
            </div>

            <div className="action-control">
              <Button
                text={isCreate ? "اضافة" : "تعديل"}
                variant={"fill"}
                size={"small"}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateEditSupportQuestion;
