import "./ViewChampionship.scss";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Button from "../../../Components/Button/Button";

import { DataGrid } from "@mui/x-data-grid";
import MessageModal from "../../../Components/Modals/MessageModal/MessageModal";
import { useState } from "react";
import { ViewChampionshipColumns } from "../../../SourceData";
const ViewChampionship = () => {
  const location = useLocation();
  const championship = location.state;
  const isMobile = useMediaQuery("(max-width:767px)");
  const [openMessage, setOpenMessage] = useState(false);

  const rows = championship?.winners_subscribers;
  return (
    <div className="edit">
      <div className="container">
        <div className="header">
          <h3>عرض البطولة</h3>
          <div
            onClick={() => {
              setOpenMessage(true);
            }}
            style={{ width: isMobile ? "100%" : "" }}
          >
            <Button
              text="حذف متعدد"
              variant="outlined"
              size={isMobile ? "full" : "small"}
            />
          </div>
        </div>
        <div className="data-grid">
          <DataGrid
            sx={{ minHeight: 600 }}
            columns={ViewChampionshipColumns}
            rows={rows.map((row, index) => {
              return { ...row, index: index + 1 };
            })}
            disableDensitySelector
            checkboxSelection
            disableRowSelectionOnClick
            disableVirtualization
            initialState={{
              ...rows.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50]}
          />
        </div>
      </div>
      {/* Message Modal */}
      <MessageModal
        message="هل انت متاكد من حذف هذا القسم من"
        extraMessage={"الاقسام الرئسية"}
        open={openMessage}
        onClose={() => setOpenMessage(false)}
      />
    </div>
  );
};

export default ViewChampionship;
