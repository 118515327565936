import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Modal, TextField, useMediaQuery } from "@mui/material";
import { message } from "antd";
import AddIcon from "@mui/icons-material/AddOutlined";
import { debounce } from "lodash";

import "./Trainers.scss";
import { TrainersColumns } from "../../SourceData";
import newRequest from "../../Utils/newRequest";
import { showErrorMessage } from "../../Utils/showErrorMessage";
import Button from "../../Components/Button/Button";
import Loader from "../../Components/Loader/Loader";
import Search from "../../Components/Modals/Search/Search";

import EditIcon from "./../../Assets/edit-icon.svg";
import ViewIcon from "./../../Assets/details-icon.svg";
import DeleteIcon from "./../../Assets/delete-icon.svg";
import FavIcon from "./../../Assets/fav-icon.svg";
import BlackFavIcon from "./../../Assets/blackfav-icon.svg";
import FilterIcon from "./../../Assets/filter-icon.svg";
import CloseIcon from "./../../Assets/close-icon.svg";
import AttentionIcon from "./../../Assets/attention-icon.svg";

const Trainers = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isSpecialTrainers = location.pathname.includes("/special-trainers");
  const isFreePackage = location.pathname.includes("/free-package");

  const [trainers, setTrainers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [rowId, setRowId] = useState("");
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [filters, setFilters] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [query, setQuery] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const getTrainers = useCallback(
    async (searchQuery = "") => {
      setLoading(true);
      try {
        const endpoint = buildEndpoint(searchQuery);
        const res = await newRequest.get(endpoint);
        setTrainers(res?.data?.data?.data);
        setTotalRows(res?.data?.data?.meta?.total);
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    },
    [paginationModel, filters]
  );

  const buildEndpoint = (searchQuery) => {
    let endpoint = `/trainers?page=${paginationModel.page + 1}&rows=${
      paginationModel.pageSize
    }`;

    if (isSpecialTrainers) endpoint += `&special_trainer=1`;
    if (isFreePackage) endpoint += `&subscription_trainers=1`;
    if (searchQuery.trim() !== "") endpoint += `&search=${searchQuery}`;
    if (filters.country) endpoint += `&country_id=${filters.country}`;
    if (filters.city) endpoint += `&city_id=${filters.city}`;
    if (filters.region) endpoint += `&region_id=${filters.region}`;

    return endpoint;
  };

  const debouncedGetTrainers = useMemo(
    () => debounce(getTrainers, 300),
    [getTrainers]
  );

  useEffect(() => {
    debouncedGetTrainers(query);
  }, [debouncedGetTrainers, query, paginationModel, filters]);

  const handleMultiDelete = async () => {
    if (selectedRowsIds.length === 0) {
      return message.warning("من فضلك قم بتحديد الصفوف التي تريد حذفها أولاً");
    }
    setLoading(true);
    try {
      await newRequest.post("/users_multi_delete", {
        ids: selectedRowsIds,
      });

      await getTrainers();
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
      setSelectedRowsIds([]);
    }
  };

  const handleDelete = async () => {
    setOpenMessage(false);
    try {
      await newRequest.delete(`/users/${rowId}`);
      await getTrainers();
      setRowId("");
      message.success("تم حذف المدرب بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  const handleActive = async (id) => {
    setLoading(true);
    try {
      await newRequest.put(`/users/${id}/activateToggle`);
      await getTrainers();
      message.success("تم التعديل بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterSubmit = (filters) => {
    setFilters(filters);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 300,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="cell-action" style={{ display: "flex" }}>
            {/* Action For Edit Trainer */}
            <div
              className="cell-btn"
              onClick={() => {
                navigate("/trainers/edit", { state: params?.row });
              }}
            >
              <img src={EditIcon} alt="" />
            </div>

            {/* Action For View Trainer */}
            <div
              className="cell-btn"
              onClick={() => {
                navigate("/trainers/view", { state: params?.row });
              }}
            >
              <img src={ViewIcon} alt="" />
            </div>

            {/* Action For Delete Trainer */}
            <div
              className="cell-btn"
              onClick={() => {
                setOpenMessage(true);
                setRowId(params?.row?.id);
              }}
            >
              <img src={DeleteIcon} alt="" />
            </div>

            <div className="cell-btn" onClick={() => {}}>
              {params?.row?.special_trainer ? (
                <img
                  src={FavIcon}
                  alt=""
                  style={{ width: "16px", height: "16px" }}
                />
              ) : (
                <img
                  src={BlackFavIcon}
                  alt=""
                  style={{ width: "16px", height: "16px" }}
                />
              )}
            </div>

            <div className="cell-btn ">
              <Button
                onClick={() => handleActive(params?.row?.id)}
                text={params.row.active === 1 ? "ايقاف" : "تفعيل"}
                size={"small"}
                variant={params.row.active === 1 ? "fill" : "outlined"}
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="trainers">
        <div className="container">
          <div className="spcial-header">
            <h4 className="title">المدربين</h4>
            <div className="search">
              <TextField
                id="outlined-search"
                type="search"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                sx={{ width: "400px" }}
              />
              <div
                className="filter-container"
                onClick={() => setOpenFilter(true)}
              >
                <img src={FilterIcon} alt="" />
              </div>
            </div>
            <div className="actions-container">
              <Button
                size={isMobile ? "fit" : "large"}
                variant="fill"
                text="اضافة مدرب جديد"
                icon={<AddIcon />}
                path="/trainers/create"
              />
              <Button
                size={isMobile ? "small" : "medium"}
                variant="outlined"
                text="حذف متعدد"
                onClick={handleMultiDelete}
              />
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="data-grid">
              <DataGrid
                rows={trainers.map((row, index) => ({
                  ...row,
                  index: index + 1,
                }))}
                columns={TrainersColumns.concat(actionColumn)}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[10, 50, 100]}
                rowCount={totalRows}
                paginationMode="server"
                autoHeight
                disableVirtualization
                checkboxSelection
                onRowSelectionModelChange={setSelectedRowsIds}
                rowSelectionModel={selectedRowsIds}
              />
            </div>
          )}
        </div>
      </div>

      {/* Message Modal */}
      <Modal
        open={openMessage}
        onClose={() => {
          setOpenMessage(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div
            className="header"
            onClick={() => {
              setOpenMessage(false);
              setRowId("");
            }}
          >
            <img src={CloseIcon} alt="" />
          </div>
          <div className="body">
            <img src={AttentionIcon} alt="" />
            <h2>انتبة</h2>
            <h5>هل انت متاكد من حذف هذا المدرب من</h5>
            <h6>المدربين</h6>
          </div>
          <div className="footer">
            <button className="action-btn" onClick={handleDelete}>
              حذف
            </button>
          </div>
        </div>
      </Modal>

      {/* Filter Modal */}
      <Search
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        onFilterSubmit={handleFilterSubmit}
      />
    </>
  );
};

export default Trainers;
