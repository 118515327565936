import "./Contact.scss";
import ViewIcon from "./../../Assets/details-icon.svg";
import StarIcon from "./../../Assets/star-icon.svg";
import { Fragment, useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { showErrorMessage } from "../../Utils/showErrorMessage";
import Button from "../../Components/Button/Button";
import { ContactColumns } from "../../SourceData";
import newRequest from "../../Utils/newRequest";
import Loader from "../../Components/Loader/Loader";
import { message } from "antd";

const Contact = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);

  useEffect(() => {
    getMessages();
  }, []);

  const getMessages = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get("/contact_us");
      setMessages(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMultiDelete = async () => {
    if (selectedRowsIds.length === 0) {
      return message.warning("من فضك قم بتحديد الصفوف التي تريد حذفها اولاً");
    }
    setLoading(true);
    try {
      for (const rowId of selectedRowsIds) {
        await newRequest.delete(`/questions/${rowId}`);
      }
      await getMessages();
      setSelectedRowsIds([]);
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 280,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="cell-action" style={{ display: "flex" }}>
            {/* View Action */}
            <div
              className="cell-btn"
              onClick={() => {
                navigate("/contact/view", {
                  state: params?.row,
                });
              }}
            >
              <img src={ViewIcon} alt="" />
            </div>
            {/* Star Action */}
            {/* <div
              className="cell-btn"
              onClick={() => {
                navigate("/trainers-support/messages/answer", {
                  state: params?.row,
                });
              }}
            >
              <img src={StarIcon} alt="" />
            </div> */}

            <div
              className="cell-btn "
              onClick={() => {
                // handleActive(params.row.id);
                navigate("/contact/answer", {
                  state: params?.row,
                });
              }}
            >
              <Button
                text={params.row.active === true ? "رد" : "رد"}
                size={"small"}
                variant={params.row.active === true ? "fill" : "outlined"}
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <div className="contact">
        <div className="container">
          <div className="spcial-header">
            <h4 className="title">تواصل معنا</h4>
            <div className="actions-container">
              <Button
                text={"حذف متعدد"}
                size={isMobile ? "full" : "small"}
                variant={"outlined"}
                // onClick={handleMultiDelete}
              />
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="data-grid">
              <DataGrid
                sx={{ minHeight: 600 }}
                columns={ContactColumns.concat(actionColumn)}
                rows={messages.map((row, index) => {
                  return { ...row, index: index + 1 };
                })}
                disableDensitySelector
                disableRowSelectionOnClick
                disableColumnFilter
                disableVirtualization
                slotProps={{ toolbar: { showQuickFilter: true } }}
                initialState={{
                  ...messages.initialState,
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelectedRowsIds(newRowSelectionModel);
                }}
                rowSelectionModel={selectedRowsIds}
              />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Contact;
