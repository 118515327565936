import "./Widget.scss";
import { Link } from "react-router-dom";
import LeftArrowIcon from "@mui/icons-material/KeyboardArrowLeft";

const Widget = ({ data }) => {
  return (
    <div className="widget">
      <div className="body">
        <div className="content">
          <p>{data.count}</p>
          <p>{data.title}</p>
        </div>
        <img src={data.icon} alt="" />
      </div>

      <Link className="footer" to={data.path}>
        <p>عرض كل تفاصيل</p>
        <LeftArrowIcon />
      </Link>
    </div>
  );
};

export default Widget;
