import "./WorkAreas.scss";
import Accordion from "@mui/material/Accordion";
import EditIcon from "./../../../../Assets/pen-icon.svg";
import DeleteIcon from "./../../../../Assets/delete-icon-pink.png";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import newRequest from "../../../../Utils/newRequest";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../../../Components/Loader/Loader";
import Button from "../../../../Components/Button/Button";
import { FormControl, MenuItem, Modal, Select, TextField } from "@mui/material";

const WorkAreas = (props) => {
  const id = props?.id;

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [updatedRegion, setUpdatedRegion] = useState({});
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  useEffect(() => {
    if (accordionExpanded) {
      getTrainerRegions();
      getCountries();
    }
  }, [accordionExpanded]);

  useEffect(() => {
    if (updatedRegion?.country_id) {
      getCities();
    }
  }, [updatedRegion?.country_id]);

  useEffect(() => {
    if (updatedRegion?.city_id) {
      getRegions();
    }
  }, [updatedRegion?.city_id]);

  const getTrainerRegions = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(`/trainer_regions?trainer_id=${id}`);
      setRegions(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const getCountries = async () => {
    const countriesResponce = await newRequest.get(`/countries`);
    setCountries(countriesResponce?.data?.data);
  };

  const getCities = async () => {
    const citiesResponce = await newRequest.get(
      `/cities?country_id=${updatedRegion?.country_id}`
    );
    setCities(citiesResponce?.data?.data);
  };

  const getRegions = async () => {
    const regionsResponce = await newRequest.get(
      `/regions?city_id=${updatedRegion?.city_id}`
    );
    setAllRegions(regionsResponce?.data?.data);
  };

  const handleDeleteTrainerRegion = async (id) => {
    setLoading(true);
    try {
      await newRequest.delete(`/trainer_regions/${id}`);
      getTrainerRegions();
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setUpdatedRegion((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await newRequest.put(`/trainer_regions/${updatedRegion.id}`, {
        ...updatedRegion,
      });
      message.success("تم تعديل المنطقة بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
      setOpen(false);
      getTrainerRegions();
    }
  };

  const handleAccordionChange = (event, isExpanded) => {
    setAccordionExpanded(isExpanded);
  };

  return (
    <>
      <Accordion onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          مناطق العمل
        </AccordionSummary>
        {loading ? (
          <Loader />
        ) : (
          <div className="work-areas container">
            {regions?.map((item) => (
              <div className="area" key={item?.id}>
                <div className="text-container">
                  <span>{item?.region?.city?.country?.name}</span>
                  <span>{item?.region?.city?.name}</span>
                  <span>{item?.address}</span>
                </div>
                <div className="actions-container">
                  <div
                    className="edit-btn"
                    onClick={() => {
                      setOpen(true);
                      setUpdatedRegion(item);
                    }}
                  >
                    <img src={EditIcon} alt="" />
                  </div>
                  <div
                    className="view-btn"
                    onClick={() => handleDeleteTrainerRegion(item?.id)}
                  >
                    <img src={DeleteIcon} alt="" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Accordion>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="regions-modal"
      >
        <div className="container">
          {loading ? (
            <Loader />
          ) : (
            <form action="" onSubmit={handleSubmit}>
              <div className="control-group">
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label htmlFor="aria-label">الدولة</label>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    placeholder="الدولة"
                    required
                    className="select"
                    name="country_id"
                    value={updatedRegion?.country_id}
                    onChange={handleChange}
                  >
                    {countries?.map((item) => (
                      <MenuItem value={item?.id} key={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label htmlFor="aria-label">المدينة</label>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    placeholder="المدينة"
                    required
                    className="select"
                    name="city_id"
                    value={updatedRegion?.city_id}
                    onChange={handleChange}
                  >
                    {cities?.map((item) => (
                      <MenuItem value={item?.id} key={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label htmlFor="aria-label">المنطقة</label>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    placeholder="المنطقة"
                    required
                    className="select"
                    name="region_id"
                    value={updatedRegion?.region_id}
                    onChange={handleChange}
                  >
                    {allRegions?.map((item) => (
                      <MenuItem value={item?.id} key={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label htmlFor="aria-label">العنوان</label>
                  <TextField
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    placeholder="العنوان"
                    required
                    className="select"
                    name="address"
                    value={updatedRegion?.address}
                    onChange={handleChange}
                  >
                    {allRegions?.map((item) => (
                      <MenuItem value={item?.id} key={item?.id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>

                <div className="form-actions">
                  <Button text="تعديل" size="small" variant="fill" />
                  <Button
                    text="الغاء"
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      setOpen(false);
                      setUpdatedRegion({});
                    }}
                  />
                </div>
              </div>
            </form>
          )}
        </div>
      </Modal>
    </>
  );
};

export default WorkAreas;
