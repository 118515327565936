import "./SocialMediaLinks.scss";
import { FormControl, TextField } from "@mui/material";
import Button from "../../../Components/Button/Button";
import Facebook from "./../../../Assets/facebook-icon.svg";
import Whatsapp from "./../../../Assets/whatsapp-icon.svg";
import Telegram from "./../../../Assets/telegram-icon.svg";
import Youtube from "./../../../Assets/youtube-icon.svg";
import Instagram from "./../../../Assets/instagram-icon.svg";
import Twitter from "./../../../Assets/twitter-icon.svg";
import Linkedin from "./../../../Assets/linkedin-icon.svg";
import Tiktok from "./../../../Assets/tiktok-icon.svg";
import { useEffect, useState } from "react";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import Loader from "../../../Components/Loader/Loader";
import { message } from "antd";
const SocialMediaLinks = () => {
  const [links, setLinks] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getLinks();
  }, []);

  const getLinks = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get("/settings");
      setLinks(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await newRequest.post("/settings", { ...links });
      getLinks();
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
      message.success("تم التعديل بنجاح");
    }
  };

  const handleChange = (e) => {
    setLinks((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  return (
    <div className="social-media-links">
      <div className="container">
        <h4 className="title">روابط التواصل الاجتماعي</h4>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">
                  <img src={Facebook} alt="" />
                  <span>فيس بوك</span>
                </label>
                <TextField
                  fullWidth
                  className="form-input"
                  value={links?.facebook}
                  name="facebook"
                  onChange={handleChange}
                />
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">
                  <img src={Whatsapp} alt="" />
                  <span>واتساب</span>
                </label>
                <TextField
                  fullWidth
                  className="form-input"
                  value={links?.whatsapp}
                  name="whatsapp"
                  onChange={handleChange}
                />
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">
                  <img src={Telegram} alt="" />
                  <span>تيليجرام</span>
                </label>
                <TextField
                  fullWidth
                  className="form-input"
                  value={links?.telegram}
                  name="telegram"
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">
                  <img src={Youtube} alt="" />
                  <span>يوتيوب</span>
                </label>
                <TextField
                  fullWidth
                  className="form-input"
                  value={links?.youtube}
                  name="youtube"
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">
                  <img src={Instagram} alt="" />
                  <span>انستجرام</span>
                </label>
                <TextField
                  fullWidth
                  className="form-input"
                  value={links?.instagram}
                  name="instagram"
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">
                  <img src={Twitter} alt="" />
                  <span>اكس / تويتر</span>
                </label>
                <TextField
                  fullWidth
                  className="form-input"
                  value={links?.x_twitter}
                  name="x_twitter"
                  onChange={handleChange}
                />
              </FormControl>
            </div>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">
                  <img src={Linkedin} alt="" />
                  <span>ليندك ان</span>
                </label>
                <TextField
                  fullWidth
                  className="form-input"
                  value={links?.linkedin}
                  name="linkedin"
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">
                  <img src={Tiktok} alt="" />
                  <span>تيك توك</span>
                </label>
                <TextField
                  fullWidth
                  className="form-input"
                  value={links?.tiktok}
                  name="tiktok"
                  onChange={handleChange}
                />
              </FormControl>
            </div>

            <div className="action-control">
              <Button text={"تعديل"} variant={"fill"} size={"small"} />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default SocialMediaLinks;
