import "./CreateAdmin.scss";
import { useState } from "react";
import Button from "../../../Components/Button/Button";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import newRequest from "../../../Utils/newRequest";
import { message } from "antd";
import Loader from "../../../Components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import { AdminPermissions } from "../../../SourceData";
import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";

const CreateAdmin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const admin = location.state;
  const isCreate = location.pathname.includes("/create");
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordMatched, setIsPasswordMatched] = useState(true);
  const [loading, setLoading] = useState(false);
  const [newAdmin, setNewAdmin] = useState({ role: "sub_admin" });
  const [updatedAdmin, setUpdatedAdmin] = useState({
    name: admin?.name,
    email: admin?.email,
    phone: admin?.phone,
    permissions: admin?.permissions?.map((item) => item.id),
  });
  const [checkedPermissions, setCheckedPermissions] = useState(
    updatedAdmin?.permissions || []
  );

  const handleCheckboxChange = (permissionId, isChecked) => {
    if (isChecked) {
      setCheckedPermissions((prevCheckedPermissions) => [
        ...prevCheckedPermissions,
        permissionId,
      ]);
    } else {
      setCheckedPermissions((prevCheckedPermissions) =>
        prevCheckedPermissions.filter((id) => id !== permissionId)
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newAdmin?.password !== newAdmin?.password_confirmation) {
      setIsPasswordMatched(false);
      return;
    }
    setLoading(true);

    if (isCreate) {
      try {
        await newRequest.post("/users", {
          ...newAdmin,
          permissions: checkedPermissions,
        });
        message.success("تم اضافة المسؤل بنجاح");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setIsPasswordMatched(true);
        setLoading(false);
        setNewAdmin({ role: "sub_admin" });
        setCheckedPermissions([]);
      }
    } else {
      try {
        await newRequest.put(`/users/${admin.id}`, {
          ...updatedAdmin,
          permissions: checkedPermissions,
        });
        message.success("تم تعديل المسؤل بنجاح");
        navigate("/admins");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setIsPasswordMatched(true);
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    if (isCreate) {
      const { name, value } = e.target;
      setNewAdmin((prev) => {
        return { ...prev, [name]: value };
      });
    } else {
      const { name, value } = e.target;
      setUpdatedAdmin((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <div className="create-admin">
      <div className="container">
        <h4 className="title">اضافة مسؤل فرعي</h4>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الاسم</label>
                <TextField
                  placeholder="ادخل الاسم"
                  fullWidth
                  className="form-input"
                  required
                  name="name"
                  onChange={handleChange}
                  value={updatedAdmin?.name}
                />
              </FormControl>
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الايميل</label>
                <TextField
                  placeholder="ادخل الايميل"
                  fullWidth
                  className="form-input"
                  required
                  type="email"
                  name="email"
                  value={updatedAdmin?.email}
                  onChange={handleChange}
                />
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">رقم الموبايل</label>
                <TextField
                  placeholder="ادخل رقم الموبايل"
                  fullWidth
                  className="form-input"
                  required
                  name="phone"
                  value={updatedAdmin?.phone}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">كلمة المرور</label>
                <TextField
                  required={isCreate}
                  fullWidth
                  id="fullWidth"
                  className="form-input"
                  placeholder="ادخل كلمة المرور"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  error={!isPasswordMatched}
                  helperText={!isPasswordMatched && "كلمة المرور غير متطابقة"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">تاكيد كلمة المرور</label>
                <TextField
                  required={isCreate}
                  fullWidth
                  id="fullWidth"
                  className="form-input"
                  placeholder="تأكيد كلمة المرور"
                  name="password_confirmation"
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  error={!isPasswordMatched}
                  helperText={!isPasswordMatched && "كلمة المرور غير متطابقة"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <FormControl
                sx={{ m: 1, flex: 1 }}
                className="select"
              ></FormControl>
            </div>

            <h4 className="title">الصلاحيات</h4>
            <div className="check-group">
              {AdminPermissions?.map((item) => (
                <div className="check" key={item.id}>
                  <div className="header">{item?.title}</div>
                  <div className="body">
                    {item?.permissions?.map((i) => (
                      <div className="item" key={i.id}>
                        <input
                          type="checkbox"
                          checked={checkedPermissions.includes(i.id)}
                          onChange={(e) =>
                            handleCheckboxChange(i.id, e.target.checked)
                          }
                        />
                        <label>{i?.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            <div className="form-actions">
              <Button
                text={isCreate ? "اضافة" : "تعديل"}
                size="large"
                variant="fill"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateAdmin;

// import "./CreateAdmin.scss";
// import { useState } from "react";
// import Button from "../../../Components/Button/Button";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
// import newRequest from "../../../Utils/newRequest";
// import { message } from "antd";
// import Loader from "../../../Components/Loader/Loader";
// import { useLocation, useNavigate } from "react-router-dom";
// import { showErrorMessage } from "../../../Utils/showErrorMessage";
// import { AdminPermissions } from "../../../SourceData";
// import FileUploadIcon from "@mui/icons-material/FileUploadOutlined";
// import {
//   FormControl,
//   IconButton,
//   InputAdornment,
//   TextField,
// } from "@mui/material";

// const CreateAdmin = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const admin = location.state;
//   const isCreate = location.pathname.includes("/create");
//   const [image, setImage] = useState(null);
//   const [showPassword, setShowPassword] = useState(false);
//   const [isPasswordMatched, setIsPasswordMatched] = useState(true);
//   const [loading, setLoading] = useState(false);
//   const [newAdmin, setNewAdmin] = useState({ role: "sub_admin" });
//   const [updatedAdmin, setUpdatedAdmin] = useState({
//     name: admin?.name,
//     email: admin?.email,
//     phone: admin?.phone,
//     permissions: admin?.permissions?.map((item) => item.id),
//   });
//   const [checkedPermissions, setCheckedPermissions] = useState(
//     updatedAdmin?.permissions || []
//   );

//   const handleCheckboxChange = (permissionId, isChecked) => {
//     if (isChecked) {
//       setCheckedPermissions((prevCheckedPermissions) => [
//         ...prevCheckedPermissions,
//         permissionId,
//       ]);
//     } else {
//       setCheckedPermissions((prevCheckedPermissions) =>
//         prevCheckedPermissions.filter((id) => id !== permissionId)
//       );
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (newAdmin?.password !== newAdmin?.password_confirmation) {
//       setIsPasswordMatched(false);
//       return;
//     }
//     setLoading(true);

//     if (isCreate) {
//       if (image) {
//         try {
//           await newRequest.post(
//             "/users",
//             {
//               ...newAdmin,
//               permissions: checkedPermissions,
//               logo: image,
//             },
//             { headers: { "Content-Type": "multipart/form-data" } }
//           );
//           message.success("تم اضافة المسؤل بنجاح");
//         } catch (error) {
//           showErrorMessage(error);
//         } finally {
//           setIsPasswordMatched(true);
//           setLoading(false);
//           setNewAdmin({ role: "sub_admin" });
//           setCheckedPermissions([]);
//         }
//       } else {
//         try {
//           await newRequest.post("/users", {
//             ...newAdmin,
//             permissions: checkedPermissions,
//           });
//           message.success("تم اضافة المسؤل بنجاح");
//         } catch (error) {
//           showErrorMessage(error);
//         } finally {
//           setIsPasswordMatched(true);
//           setLoading(false);
//           setNewAdmin({ role: "sub_admin" });
//           setCheckedPermissions([]);
//           setImage(null);
//         }
//       }
//     } else {
//       if (image) {
//         try {
//           await newRequest.put(`/users/${admin.id}`, {
//             ...updatedAdmin,
//             permissions: checkedPermissions,
//             logo: image,
//           });
//           message.success("تم تعديل المسؤل بنجاح");
//           navigate("/admins");
//         } catch (error) {
//           showErrorMessage(error);
//         } finally {
//           setIsPasswordMatched(true);
//           setLoading(false);
//         }
//       } else {
//         try {
//           await newRequest.put(`/users/${admin.id}`, {
//             ...updatedAdmin,
//             permissions: checkedPermissions,
//           });
//           message.success("تم تعديل المسؤل بنجاح");
//           navigate("/admins");
//         } catch (error) {
//           showErrorMessage(error);
//         } finally {
//           setIsPasswordMatched(true);
//           setLoading(false);
//         }
//       }
//     }
//   };

//   const handleChange = (e) => {
//     if (isCreate) {
//       const { name, value } = e.target;
//       setNewAdmin((prev) => {
//         return { ...prev, [name]: value };
//       });
//     } else {
//       const { name, value } = e.target;
//       setUpdatedAdmin((prev) => {
//         return { ...prev, [name]: value };
//       });
//     }
//   };

//   const handleClickShowPassword = () => setShowPassword((show) => !show);

//   return (
//     <div className="create-admin">
//       <div className="container">
//         <h4 className="title">اضافة مسؤل فرعي</h4>
//         {loading ? (
//           <Loader />
//         ) : (
//           <form action="" onSubmit={handleSubmit}>
//             <div className="file-group">
//               {(image || admin?.logo) && (
//                 <img
//                   src={
//                     (image || admin?.logo) instanceof File
//                       ? URL.createObjectURL(image || admin?.logo)
//                       : image || admin?.logo
//                   }
//                   alt=""
//                 />
//               )}

//               <div className="form-control">
//                 <label htmlFor="file" className="file-label">
//                   <FileUploadIcon className="icon" />
//                   تحميل الصورة
//                 </label>
//                 <input
//                   type="file"
//                   id="file"
//                   onChange={(e) => setImage(e.target.files[0])}
//                 />
//               </div>
//             </div>

//             <div className="control-group">
//               <FormControl sx={{ m: 1, flex: 1 }} className="select">
//                 <label className="form-label">الاسم</label>
//                 <TextField
//                   placeholder="ادخل الاسم"
//                   fullWidth
//                   className="form-input"
//                   required
//                   name="name"
//                   onChange={handleChange}
//                   value={updatedAdmin?.name}
//                 />
//               </FormControl>
//               <FormControl sx={{ m: 1, flex: 1 }} className="select">
//                 <label className="form-label">الايميل</label>
//                 <TextField
//                   placeholder="ادخل الايميل"
//                   fullWidth
//                   className="form-input"
//                   required
//                   type="email"
//                   name="email"
//                   value={updatedAdmin?.email}
//                   onChange={handleChange}
//                 />
//               </FormControl>
//             </div>

//             <div className="control-group">
//               <FormControl sx={{ m: 1, flex: 1 }} className="select">
//                 <label className="form-label">رقم الموبايل</label>
//                 <TextField
//                   placeholder="ادخل رقم الموبايل"
//                   fullWidth
//                   className="form-input"
//                   required
//                   name="phone"
//                   value={updatedAdmin?.phone}
//                   onChange={handleChange}
//                 />
//               </FormControl>
//               <FormControl sx={{ m: 1, flex: 1 }} className="select">
//                 <label className="form-label">كلمة المرور</label>
//                 <TextField
//                   required={isCreate}
//                   fullWidth
//                   id="fullWidth"
//                   className="form-input"
//                   placeholder="ادخل كلمة المرور"
//                   name="password"
//                   type={showPassword ? "text" : "password"}
//                   onChange={handleChange}
//                   error={!isPasswordMatched}
//                   helperText={!isPasswordMatched && "كلمة المرور غير متطابقة"}
//                   InputProps={{
//                     endAdornment: (
//                       <InputAdornment position="end">
//                         <IconButton
//                           aria-label="toggle password visibility"
//                           onClick={handleClickShowPassword}
//                           edge="end"
//                         >
//                           {showPassword ? <VisibilityOff /> : <Visibility />}
//                         </IconButton>
//                       </InputAdornment>
//                     ),
//                   }}
//                 />
//               </FormControl>
//             </div>

//             <div className="control-group">
//               <FormControl sx={{ m: 1, flex: 1 }} className="select">
//                 <label className="form-label">تاكيد كلمة المرور</label>
//                 <TextField
//                   required={isCreate}
//                   fullWidth
//                   id="fullWidth"
//                   className="form-input"
//                   placeholder="تأكيد كلمة المرور"
//                   name="password_confirmation"
//                   type={showPassword ? "text" : "password"}
//                   onChange={handleChange}
//                   error={!isPasswordMatched}
//                   helperText={!isPasswordMatched && "كلمة المرور غير متطابقة"}
//                   InputProps={{
//                     endAdornment: (
//                       <InputAdornment position="end">
//                         <IconButton
//                           aria-label="toggle password visibility"
//                           onClick={handleClickShowPassword}
//                           edge="end"
//                         >
//                           {showPassword ? <VisibilityOff /> : <Visibility />}
//                         </IconButton>
//                       </InputAdornment>
//                     ),
//                   }}
//                 />
//               </FormControl>
//               <FormControl
//                 sx={{ m: 1, flex: 1 }}
//                 className="select"
//               ></FormControl>
//             </div>

//             <h4 className="title">الصلاحيات</h4>
//             <div className="check-group">
//               {AdminPermissions?.map((item) => (
//                 <div className="check" key={item.id}>
//                   <div className="header">{item?.title}</div>
//                   <div className="body">
//                     {item?.permissions?.map((i) => (
//                       <div className="item" key={i.id}>
//                         <input
//                           type="checkbox"
//                           checked={checkedPermissions.includes(i.id)}
//                           onChange={(e) =>
//                             handleCheckboxChange(i.id, e.target.checked)
//                           }
//                         />
//                         <label>{i?.name}</label>
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               ))}
//             </div>

//             <div className="form-actions">
//               <Button
//                 text={isCreate ? "اضافة" : "تعديل"}
//                 size="large"
//                 variant="fill"
//               />
//             </div>
//           </form>
//         )}
//       </div>
//     </div>
//   );
// };

// export default CreateAdmin;
