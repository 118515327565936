import "./CreateEditRange.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControl, Select, TextField, useMediaQuery } from "@mui/material";
import { useState } from "react";
import Button from "../../../../Components/Button/Button";
import newRequest from "../../../../Utils/newRequest";
import { message } from "antd";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import Loader from "../../../../Components/Loader/Loader";

const CreateEditRange = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const editRange = location.state;
  const isCreate = location.pathname.includes("/create");
  const [loading, setLoading] = useState(false);
  const [newRange, setNewRange] = useState({
    level: editRange?.level || "",
    from: editRange?.from || "",
    to: editRange?.to || "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (isCreate) {
      try {
        await newRequest.post("/point_levels", {
          ...newRange,
        });
        setNewRange({
          level: editRange?.level || "",
          from: editRange?.from || "",
          to: editRange?.to || "",
        });
        message.success("تم اضافة النطاق بنجاح");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await newRequest.put(`/point_levels/${editRange?.id}`, {
          ...newRange,
        });
        setNewRange({
          level: editRange?.level || "",
          from: editRange?.from || "",
          to: editRange?.to || "",
        });
        message.success("تم تعديل النطاق بنجاح");
        navigate(`/student-ranges`);
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    setNewRange((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  return (
    <div className="create-range">
      <div className="container">
        <div className="header">
          <h3 className="title">تعديل نطاقات نقاط التطبيق للطلاب</h3>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">المستوي</label>
                <TextField
                  fullWidth
                  className="form-input"
                  required
                  value={newRange?.level}
                  name="level"
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">عدد النقاط ( من )</label>
                <TextField
                  fullWidth
                  className="form-input"
                  required
                  value={newRange?.from}
                  name="from"
                  onChange={handleChange}
                />
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">عدد النقاط ( الي )</label>
                <TextField
                  fullWidth
                  className="form-input"
                  required
                  value={newRange?.to}
                  name="to"
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl
                sx={{ m: 1, flex: 1 }}
                className="select"
              ></FormControl>
            </div>

            <div className="action-control">
              <Button
                text={isCreate ? "اضافة" : "تعديل"}
                variant={"fill"}
                size={"small"}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateEditRange;
