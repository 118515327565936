import "./LastLogin.scss";
import { DataGrid } from "@mui/x-data-grid";

import { useEffect, useState } from "react";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import newRequest from "../../../Utils/newRequest";
import { LastLoginColumns } from "../../../SourceData";
import Loader from "../../../Components/Loader/Loader";

const LastLogin = () => {
  const [loading, setLoading] = useState(false);
  const [lastLogin, setLastLogin] = useState([]);
  useEffect(() => {
    getLastChampionshipWinners();
  }, []);

  const getLastChampionshipWinners = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get("/last_login_users");
      setLastLogin(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="last-login">
      <div className="container">
        <h3>أخر تسجيل علي النظام </h3>
        {loading ? (
          <Loader />
        ) : (
          <DataGrid
            sx={{ minHeight: 600 }}
            columns={LastLoginColumns}
            rows={lastLogin.map((row, index) => {
              return { ...row, index: index + 1 };
            })}
            disableDensitySelector
            disableVirtualization
            initialState={{
              ...lastLogin.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50]}
            checkboxSelection
          />
        )}
      </div>
    </div>
  );
};

export default LastLogin;
