import "./EditDepartment.scss";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Button from "../../../Components/Button/Button";
import FileUploadIcon from "@mui/icons-material/FileUploadOutlined";
import { useState } from "react";
import newRequest from "../../../Utils/newRequest";
import { message } from "antd";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import Loader from "../../../Components/Loader/Loader";
const EditDepartment = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const location = useLocation();
  const department = location.state;
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [departmentTitle, setDepartmentTitle] = useState(
    department?.title || ""
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let requestData;
    let requestHeaders;

    if (image) {
      const formData = new FormData();
      formData.append("icon", image);
      requestData = {
        title: departmentTitle,
        training_type_id: department?.training_type_id,
        icon: image,
        _method: "PUT",
      };
      requestHeaders = { "Content-Type": "multipart/form-data" };
    } else {
      requestData = {
        title: departmentTitle,
        training_type_id: department?.training_type_id,
        _method: "PUT",
      };
      requestHeaders = { "Content-Type": "application/json" };
    }

    try {
      await newRequest.post(`/type_levels/${department?.id}`, requestData, {
        headers: requestHeaders,
      });

      message.success("تم تعديل القسم بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="edit">
      <div className="container">
        <div className="header">
          <h3 className="title">تعديل الاقسام الرئيسية / المستويات</h3>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            {image ? (
              <img
                src={image instanceof File ? URL.createObjectURL(image) : image}
                alt=""
              />
            ) : (
              <img src={department?.icon} alt="" />
            )}

            <div className="form-control file-control">
              <label htmlFor="file" className="file-label">
                <FileUploadIcon className="icon" />
                تحميل الصورة
              </label>
              <input
                type="file"
                id="file"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>
            <div className="form-control">
              <label htmlFor="text">اسم القسم الرئيسي/ المستوى</label>
              <input
                id="text"
                type="text"
                value={departmentTitle}
                onChange={(e) => setDepartmentTitle(e.target.value)}
                placeholder="أدخل اسم القسم الرئيسي/ المستوي"
                required
              />
            </div>
            <div className="form-actions">
              <Button
                text="تعديل"
                size={isMobile ? "full" : "medium"}
                variant="fill"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditDepartment;
