import "./CreateCode.scss";
import { useState } from "react";
import { FormControl, TextField } from "@mui/material";
import Button from "../../../../Components/Button/Button";
import newRequest from "../../../../Utils/newRequest";
import { message } from "antd";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import Loader from "../../../../Components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";

const CreateCode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const code = location.state;
  const isCreate = location.pathname.includes("create");
  const [updatedCode, setUpdatedCode] = useState({
    code: code?.code || "",
    max_use: code?.max_use || "",
    discount: code?.discount || "",
    end_date: code?.end_date || "",
  });

  const [newCode, setNewCode] = useState({
    code: "",
    max_use: "",
    discount: "",
    end_date: "",
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (isCreate) {
      try {
        await newRequest.post("/coupons", {
          ...newCode,
        });
        setNewCode({});

        message.success("تم اضافة الكود بنجاح");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await newRequest.put(`/coupons/${code.id}`, {
          ...updatedCode,
        });
        message.success("تم تعديل الكود بنجاح");
        navigate("/packages/promo-code");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (isCreate) {
      setNewCode((prevState) => ({ ...prevState, [name]: value }));
    } else {
      setUpdatedCode((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  return (
    <div className="create-edit-package">
      <div className="container">
        <h4 className="title">اضافة كود خصم</h4>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الكود</label>
                <TextField
                  placeholder="ادخل الكود"
                  fullWidth
                  className="form-input"
                  required
                  name="code"
                  value={newCode?.code || updatedCode?.code}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">تاريخ الانتهاء</label>
                <TextField
                  fullWidth
                  className="form-input"
                  required
                  type="date"
                  name="end_date"
                  value={newCode?.end_date || updatedCode?.end_date}
                  onChange={handleChange}
                />
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">نسبة الخصم بالمئة</label>
                <TextField
                  placeholder="ادخل النسبة"
                  fullWidth
                  className="form-input"
                  required
                  inputProps={{ pattern: "[0-9]*" }}
                  name="discount"
                  value={newCode?.discount || updatedCode?.discount}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الحد الاقصي للاستخدام</label>
                <TextField
                  placeholder="اقصي عدد مستخدمين"
                  fullWidth
                  className="form-input"
                  required
                  inputProps={{ pattern: "[0-9]*" }}
                  name="max_use"
                  value={newCode?.max_use || updatedCode?.max_use}
                  onChange={handleChange}
                />
              </FormControl>
            </div>

            <div className="form-actions">
              <Button
                text={isCreate ? "اضافة" : "تعديل"}
                size="large"
                variant="fill"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateCode;
