import "./ViewDepartment.scss";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Button from "../../../Components/Button/Button";
import { ViewDepartmentColumns } from "../../../SourceData";
import { DataGrid } from "@mui/x-data-grid";
import MessageModal from "../../../Components/Modals/MessageModal/MessageModal";
import { useEffect, useState } from "react";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
const ViewDepartment = () => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const department = location.state;
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [subDepartments, setSubDepartments] = useState([]);

  useEffect(() => {
    getSubDepartments();
  }, []);

  const getSubDepartments = async () => {
    setLoading(true);
    try {
      const response = await newRequest.get(
        `/level_categories?type_level_id=${department?.id}`
      );
      setSubDepartments(response?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="edit">
      <div className="container">
        <div className="header">
          <h3>{department?.title}</h3>
          <div
            onClick={() => {
              setOpenMessage(true);
            }}
            style={{ width: isMobile ? "100%" : "" }}
          >
            <Button
              text="حذف متعدد"
              variant="outlined"
              size={isMobile ? "full" : "small"}
            />
          </div>
        </div>
        <div className="data-grid">
          <DataGrid
            sx={{ minHeight: 600 }}
            columns={ViewDepartmentColumns}
            rows={subDepartments.map((row, index) => {
              return { ...row, index: index + 1 };
            })}
            params={subDepartments}
            disableDensitySelector
            loading={loading}
            checkboxSelection
            disableRowSelectionOnClick
            disableVirtualization
            initialState={{
              ...subDepartments.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50]}
          />
        </div>
      </div>
      {/* Message Modal */}
      <MessageModal
        message="هل انت متاكد من حذف هذا القسم من"
        extraMessage={"الاقسام الرئسية"}
        open={openMessage}
        onClose={() => setOpenMessage(false)}
      />
    </div>
  );
};

export default ViewDepartment;
