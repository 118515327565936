import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import "./PieChartBox.scss";

const PieChartBox = (props) => {
  const data = [
    { name: "طلاب", value: props.students, color: "#FDB812" },
    { name: "مدربين", value: props.trainers, color: "#A1E3CB" },
    // { name: "بطولات", value: 300, color: "#37474F" },
    // { name: "اخري", value: 600, color: "#F2F2F2" },
  ];

  return (
    <div className="pieChartBox">
      <h3 className="chart__title">حالة الحضور</h3>
      <div className="pie__chart">
        <ResponsiveContainer width="80%" height={300}>
          <PieChart>
            <Tooltip
              contentStyle={{ background: "white", borderRadius: "5px" }}
            />
            <Pie
              data={data}
              innerRadius={"70%"}
              outerRadius={"90%"}
              paddingAngle={5}
              dataKey="value"
            >
              {data.map((item) => (
                <Cell key={item.name} fill={item.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="options">
        {data.map((item) => (
          <div className="option" key={item.name}>
            <div className="title">
              <div className="dot" style={{ backgroundColor: item.color }} />
              <span>{item.name}</span>
            </div>
            <span>{item.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PieChartBox;
