import "./StudentRanges.scss";
import AddIcon from "@mui/icons-material/AddOutlined";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "./../../../Assets/edit-icon.svg";
import DeleteIcon from "./../../../Assets/delete-icon.svg";
import CloseIcon from "./../../../Assets/close-icon.svg";
import AttentionIcon from "./../../../Assets/attention-icon.svg";
import { Modal, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import { StudentRangesColumns } from "../../../SourceData";
import MessageModal from "../../../Components/Modals/MessageModal/MessageModal";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../../Components/Loader/Loader";

const StudentRanges = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const [openMessage, setOpenMessage] = useState(false);
  const [ranges, setRanges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowId, setRowId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getRanges();
  }, []);

  const getRanges = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get("/point_levels");
      setRanges(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenMessage(false);
    setLoading(true);
    try {
      await newRequest.delete(`/point_levels/${rowId}`);
      getRanges();
      setRowId("");

      message.success("تم حذف النطاق بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setOpenMessage(false);
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 280,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="cell-action" style={{ display: "flex" }}>
            <div
              className="cell-btn"
              onClick={() => {
                navigate("/student-ranges/edit", { state: params?.row });
              }}
            >
              <img src={EditIcon} alt="" />
            </div>

            <div
              className="cell-btn"
              onClick={() => {
                setOpenMessage(true);
                setRowId(params?.row?.id);
              }}
            >
              <img src={DeleteIcon} alt="" />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="student-ranges">
      <div className="container">
        <div className="spcial-header">
          <h4 className="title">نطاقات نقاط التطبيق للطلاب</h4>
          <div className="actions-container">
            <Button
              text={"اضافة"}
              size={isMobile ? "full" : "small"}
              variant={"fill"}
              icon={<AddIcon />}
              path={"/student-ranges/create"}
            />
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="data-grid">
            <DataGrid
              sx={{ minHeight: 600 }}
              columns={StudentRangesColumns.concat(actionColumn)}
              rows={ranges.map((row, index) => {
                return { ...row, index: index + 1 };
              })}
              params={ranges}
              disableDensitySelector
              disableRowSelectionOnClick
              disableVirtualization
              initialState={{
                ...ranges.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 50]}
            />
          </div>
        )}
      </div>

      {/* Message Modal */}
      <Modal
        open={openMessage}
        onClose={() => {
          setOpenMessage(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div
            className="header"
            onClick={() => {
              setOpenMessage(false);
              setRowId("");
            }}
          >
            <img src={CloseIcon} alt="" />
          </div>
          <div className="body">
            <img src={AttentionIcon} alt="" />
            <h2>انتبة</h2>
            <h5>هل انت متاكد من حذف هذا النطاق من</h5>
            <h6>نطاقات الطلاب</h6>
          </div>
          <div className="footer">
            <button className="action-btn" onClick={handleDelete}>
              حذف
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default StudentRanges;
