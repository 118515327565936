import "./LastChampionshipWinners.scss";
import { DataGrid } from "@mui/x-data-grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import newRequest from "../../../Utils/newRequest";
import { LastChampionshipWinnersColumns } from "../../../SourceData";
import Loader from "../../../Components/Loader/Loader";

const LastChampionshipWinners = () => {
  const [loading, setLoading] = useState(false);
  const [below5, setBelow5] = useState([]);
  const [between5And8, setBetween5And8] = useState([]);
  const [between8And10, setBetween8And10] = useState([]);
  const [above10, setAbove10] = useState([]);

  useEffect(() => {
    getLastChampionshipWinners();
  }, []);

  const getLastChampionshipWinners = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(
        "/last_championship_winners_subscribers"
      );

      setBelow5(res?.data?.data?.last_championship_winners_subscribers.below5);
      setBetween5And8(
        res?.data?.data?.last_championship_winners_subscribers?.between5And8
      );
      setBetween8And10(
        res?.data?.data?.last_championship_winners_subscribers?.between8And10
      );
      setAbove10(
        res?.data?.data?.last_championship_winners_subscribers?.above10
      );
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="championship-winners">
      <div className="container">
        <h3>الفائزين في اخر بطولة</h3>
        {loading ? (
          <Loader />
        ) : (
          <>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                الفائزين في اخر بطولة فئة ( أقل من 6 سنوات )
              </AccordionSummary>

              <DataGrid
                sx={{ minHeight: 600 }}
                columns={LastChampionshipWinnersColumns}
                rows={below5.map((row, index) => {
                  return { ...row, index: index + 1 };
                })}
                disableDensitySelector
                disableVirtualization
                initialState={{
                  ...below5.initialState,
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
                checkboxSelection
              />
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                الفائزين في اخر بطولة فئة ( من 6 إلى 8 سنوات )
              </AccordionSummary>

              <DataGrid
                sx={{ minHeight: 600 }}
                columns={LastChampionshipWinnersColumns}
                rows={between5And8?.map((row, index) => {
                  return { ...row, index: index + 1 };
                })}
                disableDensitySelector
                disableVirtualization
                initialState={{
                  ...between5And8?.initialState,
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
                checkboxSelection
              />
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                الفائزين في اخر بطولة فئة ( من 8 إلى 10 سنوات )
              </AccordionSummary>

              <DataGrid
                sx={{ minHeight: 600 }}
                columns={LastChampionshipWinnersColumns}
                rows={between8And10?.map((row, index) => {
                  return { ...row, index: index + 1 };
                })}
                disableDensitySelector
                disableVirtualization
                initialState={{
                  ...between8And10?.initialState,
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
                checkboxSelection
              />
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                الفائزين في اخر بطولة فئة ( فوق 10 سنوات )
              </AccordionSummary>

              <DataGrid
                sx={{ minHeight: 600 }}
                columns={LastChampionshipWinnersColumns}
                rows={above10?.map((row, index) => {
                  return { ...row, index: index + 1 };
                })}
                disableDensitySelector
                disableVirtualization
                initialState={{
                  ...above10?.initialState,
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
                checkboxSelection
              />
            </Accordion>
          </>
        )}
      </div>
    </div>
  );
};

export default LastChampionshipWinners;
