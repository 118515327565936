import "./ChatModal.scss";
import { Modal } from "@mui/material";
import CloseIcon from "./../../../Assets/close-icon.svg";
import PersonIcon from "./../../../Assets/person-icon.png";
import { useEffect, useState } from "react";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import Loader from "../../Loader/Loader";
import moment from "moment";
const ChatModal = (props) => {
  const data = props?.data;
  const [loading, setLoading] = useState(false);
  const [chat, setChat] = useState([]);

  useEffect(() => {
    if (data?.student_id) {
      getChat();
    }
  }, [data?.student_id]);

  const getChat = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(
        `/chat_messages?student_id=${data?.student_id}`
      );
      setChat(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={props?.open}
      onClose={props?.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="chat-modal"
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="container">
          <div className="chat-header">
            <div className="wrapper">
              <img src={data?.student?.logo || PersonIcon} alt="" />
              <span>ط/{data?.student?.name}</span>
            </div>
            <div className="close" onClick={props?.onClose}>
              <img src={CloseIcon} alt="" />
            </div>
          </div>
          <div className="chats-container">
            {chat?.map((item, index) => (
              <>
                {item?.sender_type === "student" ? (
                  <div className="chat-body" key={index}>
                    <span className="time">
                      {moment(item?.created_at).format("hh:mm A")}
                    </span>
                    <p className="chat">{item?.message}</p>
                  </div>
                ) : (
                  <div className="chat-body-replay" key={index}>
                    <span className="time">
                      {moment(item?.created_at).format("hh:mm a")}
                    </span>
                    <p className="chat">{item?.message}</p>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ChatModal;
