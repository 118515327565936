import "./Rating.scss";
import DeleteIcon from "./../../../../Assets/delete-icon-pink.png";
import Star from "./../../../../Assets/star-icon.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import newRequest from "../../../../Utils/newRequest";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import moment from "moment";
import Loader from "../../../../Components/Loader/Loader";
import { message } from "antd";

const Rating = (props) => {
  const id = props?.id;
  const [rates, setRates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  useEffect(() => {
    if (accordionExpanded) {
      getRates();
    }
  }, [accordionExpanded]);

  const getRates = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(`/trainer_reviews?trainer_id=${id}`);
      setRates(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await newRequest.delete(`/trainer_reviews/${id}`);
      getRates();
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const RatingStars = ({ rate }) => {
    const stars = Array.from({ length: rate }, (_, i) => (
      <img key={i} src={Star} alt="" />
    ));

    return <>{stars}</>;
  };

  const handleAccordionChange = (event, isExpanded) => {
    setAccordionExpanded(isExpanded);
  };

  return (
    <Accordion onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        التقييمات
      </AccordionSummary>
      {loading ? (
        <Loader />
      ) : (
        <div className="rates container">
          {rates?.map((item) => (
            <div className="rate" key={item?.id}>
              <div className="rate-header">
                <div className="text-container">
                  <img src={item?.student?.logo} alt="" />
                  <div className="wrapper">
                    <span>
                      <b>{item?.student?.name}</b>
                    </span>
                    <span>
                      {moment(item?.created_at).format("DD/MM/YYYY - hh:mm A")}
                    </span>
                    <div className="star-container">
                      <RatingStars rate={item?.rate} />
                    </div>
                  </div>
                </div>
                <div className="actions-container">
                  <div
                    className="view-btn"
                    onClick={() => handleDelete(item?.id)}
                  >
                    <img src={DeleteIcon} alt="" />
                  </div>
                  <div className="switch-btn">
                    <input type="checkbox" />
                  </div>
                </div>
              </div>
              <div className="rate-body">{item?.comment}</div>
            </div>
          ))}
        </div>
      )}
    </Accordion>
  );
};

export default Rating;
