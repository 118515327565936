import "./Home.scss";
import Widget from "../../Components/Widget/Widget";
import PieChartBox from "../../Components/Charts/PieChartBox/PieChartBox";
import Chart from "../../Components/Charts/BigChartsBox/Chart";
import students_count from "./../../Assets/students_count.svg";
import online_students_count from "./../../Assets/students_count.svg";
import special_trainers_count from "./../../Assets/special_trainers_count.svg";
import championships_count from "./../../Assets/championships_count.svg";
import questions_count from "./../../Assets/questions_count.svg";
import most_trainings_students_count from "./../../Assets/special_trainers_count.svg";
import last_championship_subscribers from "./../../Assets/last_championship_subscribers.svg";
import { useEffect, useState } from "react";
import newRequest from "../../Utils/newRequest";
import { showErrorMessage } from "../../Utils/showErrorMessage";
import Loader from "../../Components/Loader/Loader";
import { useDispatch } from "react-redux";

import { storeData } from "../../Redux/dashSlice";

const Home = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get("/dash");
      setData(res?.data?.data);
      dispatch(storeData(res?.data?.data));
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const widgetsData = [
    {
      title: "الطلاب المسجلين",
      count: data?.students_count,
      icon: students_count,
      path: "/students",
    },
    {
      title: "المدربين المميزين",
      count: data?.special_trainers_count,
      icon: special_trainers_count,
      path: "/trainers/special-trainers",
    },
    {
      title: "عدد البطولات",
      count: data?.championships_count,
      icon: championships_count,
      path: "/championships",
    },
    {
      title: "عدد الاسئلة المضافة للنظام",
      count: data?.questions_count,
      icon: questions_count,
      path: "/questions",
    },
    {
      title: "الطلاب اشتراك تجريبي",
      count: data?.subscription_students_count,
      icon: students_count,
      path: "/students/free-package",
    },
    {
      title: "المدربين اشتراك تجريبي",
      count: data?.subscription_trainers_count,
      icon: special_trainers_count,
      path: "/trainers/free-package",
    },
    {
      title: "اكثر طلاب قامو بعمل تدريبات",
      count: data?.most_trainings_students_count,
      icon: most_trainings_students_count,
      path: "/students/most-trainings",
    },
    {
      title: "الفائزين في اخر بطولة",
      count: data?.last_championship_subscribers,
      icon: last_championship_subscribers,
      path: "/championship-winners",
    },
    {
      title: "الطلاب الاونلاين",
      count: data?.online_students_count,
      icon: online_students_count,
      path: "/students/online-students",
    },
    {
      title: "الطلاب الاكثر استخداماً للتطبيق",
      count: data?.online_students_count,
      icon: online_students_count,
      path: "/most-students",
    },
    {
      title: "اخر تسجيل علي النظام",
      count: data?.online_students_count,
      icon: online_students_count,
      path: "/last-login",
    },
  ];

  return (
    <div className="home">
      <div className="container">
        {loading ? (
          <Loader />
        ) : (
          <div className="widgets">
            {widgetsData.map((widget, index) => (
              <Widget key={index} data={widget} />
            ))}
          </div>
        )}
        <div className="charts">
          <div className="right-chart">
            <Chart />
          </div>
          <div className="left-chart">
            <PieChartBox
              students={data?.students_count}
              trainers={data?.trainers_count}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
