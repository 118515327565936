import "./PromoCode.scss";
import DeleteIcon from "./../../../Assets/delete-icon.svg";
import EditIcon from "./../../../Assets/edit-icon.svg";
import FilterIcon from "./../../../Assets/filter-icon.svg";
import CloseIcon from "./../../../Assets/close-icon.svg";
import AttentionIcon from "./../../../Assets/attention-icon.svg";
import AddIcon from "@mui/icons-material/AddOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { Modal, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import MessageModal from "../../../Components/Modals/MessageModal/MessageModal";
import { PromoCodeColumns } from "../../../SourceData";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../../Components/Loader/Loader";

const PromoCode = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [codes, setCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [rowId, setRowId] = useState("");
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: false,
    quickFilterValues: [],
  });
  const gridToolbarRef = useRef(null);

  useEffect(() => {
    getCodes();
  }, []);

  const getCodes = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get("/coupons");
      setCodes(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMultiDelete = async () => {
    setOpenMessage(false);
    if (selectedRowsIds.length === 0) {
      return message.info("من فضك قم بتحديد الصفوف التي تريد حذفها اولاً");
    }

    setLoading(true);
    try {
      for (const questionId of selectedRowsIds) {
        await newRequest.delete(`/coupons/${questionId}`);
      }
      await getCodes();
      setSelectedRowsIds([]);
      message.success("تم حذف الاكواد بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenMessage(false);
    try {
      await newRequest.delete(`/coupons/${rowId}`);
      await getCodes();
      setRowId("");

      message.success("تم حذف الكود بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  const handleActive = async (id) => {
    setLoading(true);
    try {
      await newRequest.put(`/coupons/${id}/activateToggle`);
      await getCodes();
      message.success("تم التعديل بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setOpenMessage(false);
    }
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    setFilterModel((prevModel) => ({
      ...prevModel,
      quickFilterValues: [event.target.value],
    }));
  };

  const handleFilterClickd = () => {
    if (gridToolbarRef.current) {
      gridToolbarRef.current.click();
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton ref={gridToolbarRef} />
      </GridToolbarContainer>
    );
  };
  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 280,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="cell-action" style={{ display: "flex" }}>
            <div
              className="cell-btn"
              onClick={() => {
                navigate("/packages/promo-code/edit", { state: params?.row });
              }}
            >
              <img src={EditIcon} alt="" />
            </div>

            <div
              className="cell-btn"
              onClick={() => {
                setOpenMessage(true);
                setRowId(params?.row?.id);
              }}
            >
              <img src={DeleteIcon} alt="" />
            </div>

            <div
              className="cell-btn "
              onClick={() => {
                handleActive(params?.row?.id);
              }}
            >
              <Button
                text={params.row.active === true ? "ايقاف" : "تفعيل"}
                size={"small"}
                variant={params.row.active === true ? "fill" : "outlined"}
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="promo-code">
      <div className="container">
        <div className="spcial-header">
          <h4 className="title">كود الخصم</h4>
          <div className="search">
            <div className="search-container">
              <SearchIcon className="icon" />
              <input
                type="text"
                placeholder="البحث بالاسم"
                onChange={handleSearch}
              />
            </div>
            <div className="filter-container" onClick={handleFilterClickd}>
              <img src={FilterIcon} alt="" />
            </div>
          </div>
          <div className="actions-container">
            <Button
              text={"حذف متعدد"}
              size={isMobile ? "full" : "small"}
              variant={"fill"}
              isMobile=""
              onClick={() => setOpenMessage(true)}
            />
            <Button
              text={"اضافة كود"}
              size={isMobile ? "full" : "medium"}
              variant={"fill"}
              icon={<AddIcon />}
              path={"/packages/promo-code/create"}
              isMobile=""
            />
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="data-grid">
            <DataGrid
              sx={{ minHeight: 600 }}
              columns={PromoCodeColumns.concat(actionColumn)}
              rows={codes.map((row, index) => {
                return { ...row, index: index + 1 };
              })}
              params={codes}
              disableDensitySelector
              disableRowSelectionOnClick
              disableVirtualization
              slots={{
                toolbar: CustomToolbar,
              }}
              filterModel={filterModel}
              onFilterModelChange={(newModel) => setFilterModel(newModel)}
              initialState={{
                ...codes.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 50]}
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setSelectedRowsIds(newRowSelectionModel);
              }}
              rowSelectionModel={selectedRowsIds}
            />
          </div>
        )}
      </div>

      {/* Message Modal */}
      <Modal
        open={openMessage}
        onClose={() => {
          setOpenMessage(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div
            className="header"
            onClick={() => {
              setOpenMessage(false);
              setRowId("");
            }}
          >
            <img src={CloseIcon} alt="" />
          </div>
          <div className="body">
            <img src={AttentionIcon} alt="" />
            <h2>انتبة</h2>
            <h5>
              {rowId.length === 0
                ? "هل انت متاكد من حذف هذه الاكواد من"
                : "هل انت متاكد من حذف هذا الكود من"}
            </h5>
            <h6>اكواد الخصم</h6>
          </div>
          <div className="footer">
            <button
              className="action-btn"
              onClick={rowId.length === 0 ? handleMultiDelete : handleDelete}
            >
              حذف
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PromoCode;
