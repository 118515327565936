import "./ViewStudent.scss";
import Button from "../../../Components/Button/Button";
import {
  FormControl,
  MenuItem,
  Modal,
  Select,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import MainInfo from "./MainInfo/MainInfo";
import newRequest from "../../../Utils/newRequest";
import { message } from "antd";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import Statistics from "./Statistics/Statistics";
import StudentLevels from "./StudentLevels/StudentLevels";
import StudentFriends from "./StudentFriends/StudentFriends";
import Loader from "../../../Components/Loader/Loader";
import Certificates from "./Certificates/Certificates";

const ViewStudent = () => {
  const location = useLocation();
  const row = location.state;
  const [loading, setLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);

  return (
    <>
      <div className="view">
        <div className="container">
          <div className="header">
            <h3>تفاصيل الطالب</h3>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="accordions-container">
              <MainInfo user={row} />
              <Statistics user={row} />
              <StudentLevels user={row} />
              <Certificates user={row} />
              <StudentFriends user={row} />
            </div>
          )}
        </div>
      </div>

      {/* <Modal
        open={editModal}
        onClose={() => setEditModal(false)}
        className="edit"
      >
        <div className="edit-container container">
          <div className="header">
            <FormControl sx={{ width: "100%" }}>
              <Select
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                value={10}
              >
                <MenuItem value={10}>الابطال المتفوقين</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="body">
            <div className="form-select">
              <p>الابطال المتفوقين</p>
              <input id="subadmin" type="radio" name="toggle" />
            </div>
            <div className="form-select">
              <p>الابطال المتفوقين</p>
              <input id="subadmin" type="radio" />
            </div>
            <div className="form-select">
              <p>الابطال المتفوقين</p>
              <input id="subadmin" type="radio" />
            </div>
            <div className="form-select">
              <p>الابطال المتفوقين</p>
              <input id="subadmin" type="radio" />
            </div>
            <div className="form-select">
              <p>الابطال المتفوقين</p>
              <input id="subadmin" type="radio" />
            </div>
            <div className="form-select">
              <p>الابطال المتفوقين</p>
              <input id="subadmin" type="radio" />
            </div>
          </div>
          <div
            className="actions-container"
            onClick={() => setEditModal(false)}
          >
            <Button text="تم" variant="fill" size="small" />
          </div>
        </div>
      </Modal> */}
    </>
  );
};

export default ViewStudent;
