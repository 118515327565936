import "./CreateNotification.scss";
import { useLocation } from "react-router-dom";
import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUploadOutlined";
import { useState } from "react";
import { message } from "antd";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import Loader from "../../../Components/Loader/Loader";
import Button from "../../../Components/Button/Button";
import NotificationSearch from "../../../Components/NotificationSearch/NotificationSearch";

const CreateNotification = () => {
  const location = useLocation();
  const page = location.state;
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [newNotification, setNewNotification] = useState({
    title: {
      ar: "",
      en: "",
    },
    desc: {
      ar: "",
      en: "",
    },
    type: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let requestData = { ...newNotification, image };
      if (newNotification.type === "allStudents") {
        requestData.users = "all_students";
        requestData.type = "student";
      } else if (newNotification.type === "allTrainers") {
        requestData.users = "all_trainers";
        requestData.type = "trainer";
      } else if (newNotification.type === "studentsGroup") {
        requestData.users = selectedUsers;
        requestData.type = "student";
      } else if (newNotification.type === "trainersGroup") {
        requestData.users = selectedUsers;
        requestData.type = "trainer";
      }
      await newRequest.post(`/admin_notifications`, requestData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      message.success("تم اضافة الاشعار بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setImage(null);
      setLoading(false);
      setSelectedUsers([]);
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    const [property, language] = name.split(".");

    setNewNotification((prevState) => ({
      ...prevState,
      [property]: {
        ...prevState[property],
        [language]: value,
      },
    }));
  };

  const handleSelectedOptionsChange = (newSelectedOptions) => {
    setSelectedUsers(
      newSelectedOptions.map((item) => {
        return item.id;
      })
    );
  };

  return (
    <div className="edit-profile-page">
      <div className="container">
        <div className="header">
          <h3 className="title">اضافة اشعار جديد</h3>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            <form id="myForm" action="" onSubmit={handleSubmit}>
              <div className="file-group">
                {(image || page?.image) && (
                  <img
                    src={
                      (image || page?.image) instanceof File
                        ? URL.createObjectURL(image || page?.image)
                        : image || page?.image
                    }
                    alt=""
                  />
                )}

                <div className="form-control">
                  <label htmlFor="file" className="file-label">
                    <FileUploadIcon className="icon" />
                    تحميل الصورة
                  </label>
                  <input
                    type="file"
                    id="file"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="control-group">
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">العنوان بالعربية</label>
                  <TextField
                    placeholder="ادخل العنوان"
                    fullWidth
                    className="form-input"
                    required
                    name="title.ar"
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">العنوان بالانجليزية</label>
                  <TextField
                    placeholder="ادخل العنوان"
                    fullWidth
                    className="form-input"
                    required
                    name="title.en"
                    onChange={handleChange}
                  />
                </FormControl>
              </div>

              <div className="control-group">
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">الوصف بالعربية</label>
                  <TextField
                    placeholder="ادخل الوصف"
                    fullWidth
                    className="form-input"
                    required
                    name="desc.ar"
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">الوصف بالانجليزية</label>
                  <TextField
                    placeholder="ادخل الوصف"
                    fullWidth
                    className="form-input"
                    required
                    name="desc.en"
                    onChange={handleChange}
                  />
                </FormControl>
              </div>

              <div
                className="control-group"
                style={{ minHeight: "200px", alignItems: "flex-start" }}
              >
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">المرسل اليهم</label>
                  <Select
                    placeholder="ادخل الوصف"
                    fullWidth
                    className="form-input"
                    required
                    name="type"
                    value={newNotification?.type}
                    onChange={(e) =>
                      setNewNotification((prev) => {
                        return { ...prev, [e.target.name]: e.target.value };
                      })
                    }
                  >
                    <MenuItem value="allStudents">جميع الطلاب</MenuItem>
                    <MenuItem value="allTrainers">جميع المدربين</MenuItem>
                    <MenuItem value="studentsGroup">طالب او اكثر</MenuItem>
                    <MenuItem value="trainersGroup">مدرب او اكثر</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  {newNotification.type === "studentsGroup" && (
                    <NotificationSearch
                      type="students"
                      onSelectedOptionsChange={handleSelectedOptionsChange}
                    />
                  )}
                  {newNotification.type === "trainersGroup" && (
                    <NotificationSearch
                      type="trainers"
                      onSelectedOptionsChange={handleSelectedOptionsChange}
                    />
                  )}
                </FormControl>
              </div>
            </form>

            <div className="action-control">
              <Button
                text="اضافة"
                variant={"fill"}
                size={"small"}
                form="myForm"
                type="submit"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateNotification;
