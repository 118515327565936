import "./ForgetPassword.scss";
import LoginImg from "./../../Assets/login-img.svg";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";
import newRequest from "../../Utils/newRequest";
import { showErrorMessage } from "../../Utils/showErrorMessage";
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { message } from "antd";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CountriesCodes } from "../../SourceData";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [enterdOtp, setEnteredOtp] = useState("");
  const [success, setSuccess] = useState(false);
  const [validOtp, setValidOtp] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isPasswordMatched, setIsPasswordMatched] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!success) {
      try {
        const res = await newRequest.post("/forget_password", {
          phone: phone,
          country_code: selectedCountryCode,
        });
        if (res?.data?.status === 200) {
          message.success("تم ارسال الكود المتغير الي رقم الهاتف");
          setOtp(res?.data?.data?.otp);
          setSuccess(true);
        } else if (res?.data?.status === 404) {
          message.error("رقم الهاتف غير صحيح");
        } else {
          message.error("لقد حدث خطاء ما برجاء المحاولة في وقت لاحق");
        }
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    } else if (success && !validOtp) {
      try {
        const res = await newRequest.post("/password_phone_otp", {
          phone: phone,
          otp: enterdOtp,
        });
        if (res?.data?.status === 200) {
          setValidOtp(true);
        } else if (res?.data?.status === 404) {
          message.error("الكود المتغير غير صحيح");
        } else {
          message.error("لقد حدث خطاء ما برجاء المحاولة في وقت لاحق");
        }
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    } else {
      if (newPassword !== confirmNewPassword) {
        setIsPasswordMatched(false);
        setLoading(false);
        return;
      }
      setLoading(true);
      try {
        const res = await newRequest.post(`/password_reset`, {
          phone: phone,
          otp: otp,
          new_password: newPassword,
          new_password_confirmation: confirmNewPassword,
        });
        if (res?.data?.status === 200) {
          message.success("تم تغير  كلمة المرور بنجاح");
          navigate(`/login`);
        } else if (res.status === 404) {
          message.error("الكود المتغير غير صحيح");
        } else {
          message.error("لقد حدث خطاء ما برجاء المحاولة في وقت لاحق");
        }
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleCountryChange = (event, newValue) => {
    if (newValue) {
      setSelectedCountryCode(`+${newValue.phone}`);
    } else {
      setSelectedCountryCode("");
    }
  };

  return (
    <div className="forget-password">
      <div className="container">
        <div className="img-container">
          <img src={LoginImg} alt="" />
        </div>
        <div className="form-container">
          <form action="" onSubmit={handleSubmit}>
            <h1 className="title">
              {!success && "نسيت كلمة المرور"}
              {success && !validOtp && "تأكيد الكود المتغير"}
              {success && validOtp && "إنشاء كلمة مرور جديدة"}
            </h1>
            <p className="message">
              {!success && "أدخل رقم هاتفك لاعادة تعين كلمة المرور الخاصة بك"}
              {success &&
                !validOtp &&
                "أدخل الكود المرسل اليك عبر الهاتف لاعادة تعين كلمة المرور الخاصة بك"}
              {success && validOtp && "اعادة تعين كلمة المرور الخاصة بك"}
            </p>
            {!success && (
              <div className="control-group">
                <Autocomplete
                  className="country"
                  id="country-customized-option-demo"
                  options={CountriesCodes}
                  // disableCloseOnSelect
                  onChange={handleCountryChange}
                  getOptionLabel={(option) =>
                    `${option.code}  +${option.phone}`
                  }
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      label="كود البلد"
                      required
                    />
                  )}
                />

                <TextField
                  label="رقم الهاتف"
                  className="phone-number"
                  required
                  type="text"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            )}

            {success && !validOtp && (
              <div className="control-group">
                <TextField
                  label="الكود المتغير"
                  className="phone-number"
                  required
                  type="text"
                  onChange={(e) => setEnteredOtp(e.target.value)}
                />
              </div>
            )}

            {success && validOtp && (
              <div
                className="control-group"
                style={{ flexDirection: "column" }}
              >
                <FormControl sx={{ m: 1, flex: 1 }} fullWidth>
                  <label className="form-label">كلمة المرور الجديدة</label>
                  <TextField
                    required
                    fullWidth
                    id="fullWidth"
                    className="form-input"
                    placeholder="ادخل كلمة المرور"
                    name="new_password"
                    onChange={(e) => setNewPassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    error={!isPasswordMatched}
                    helperText={!isPasswordMatched && "كلمة المرور غير متطابقة"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl sx={{ m: 1, flex: 1 }} fullWidth>
                  <label className="form-label">تاكيد كلمة المرور</label>
                  <TextField
                    required
                    fullWidth
                    id="fullWidth"
                    className="form-input"
                    name="new_password_confirmation"
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    placeholder="تأكيد كلمة المرور"
                    type={showPassword ? "text" : "password"}
                    error={!isPasswordMatched}
                    helperText={!isPasswordMatched && "كلمة المرور غير متطابقة"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </div>
            )}

            <div className="form-action">
              <button className="action-btn" type="submit">
                {loading ? (
                  <CircularProgress className="circular-progress" size={24} />
                ) : !success ? (
                  "أرسال"
                ) : (
                  "تأكيد"
                )}
              </button>
            </div>
            <Link to="/login">تسجيل الدخول</Link>
            <div className="form-control"></div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
