import "./Gallery.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "../../../../Components/Button/Button";
import DeleteIcon from "./../../../../Assets/delete-icon-pink.png";
import { useEffect, useState } from "react";
import newRequest from "../../../../Utils/newRequest";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../../../Components/Loader/Loader";
import FileUploadIcon from "@mui/icons-material/FileUploadOutlined";
import { Modal } from "@mui/material";
import UploadingIcon from "./../../../../Assets/Uploading.gif";
import CloseIcon from "@mui/icons-material/CloseOutlined";

const Gallery = (props) => {
  const id = props?.id;
  const [media, setMedia] = useState("photos");
  const [gallery, setGallery] = useState([]);
  const [file, setFile] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  useEffect(() => {
    if (accordionExpanded) {
      getGallery();
    }
  }, [accordionExpanded]);

  const getGallery = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(`/trainer_galleries?trainer_id=${id}`);
      setGallery(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await newRequest.delete(`/trainer_galleries/${id}`);
      getGallery();
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true);
    const formData = new FormData();
    try {
      const res = await newRequest.post(
        `/trainer_galleries?trainer_id=${id}`,
        {
          file: file,
          type: selectedType,
        },
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      message.success("تم الرفع بنجاح");
      getGallery(); // Refresh the gallery after uploading a file
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setIsUploading(false);
      setFile(null);
      setSelectedType("");
    }
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setAccordionExpanded(isExpanded);
  };

  return (
    <>
      <Accordion onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          الصور والفديوهات الخاصة
        </AccordionSummary>
        <div className="photos-videos container">
          <div className="actions-container">
            <Button
              text="صور"
              variant={media === "photos" ? "fill" : "outlined"}
              size="medium"
              onClick={() => setMedia("photos")}
            />

            <Button
              text="فيديوهات"
              variant={media === "videos" ? "fill" : "outlined"}
              size="medium"
              onClick={() => setMedia("videos")}
            />
            <Button
              text="اضافة ملفات"
              variant="outlined"
              size="medium"
              icon={<FileUploadIcon />}
              onClick={() => setOpen(true)}
            />
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="photos-videos-container">
              {media === "photos"
                ? gallery
                    .filter((item) => item.type === "photo")
                    .map((item) => (
                      <div className="photo-container" key={item?.id}>
                        <img
                          src={DeleteIcon}
                          alt=""
                          className="icon"
                          onClick={() => handleDelete(item?.id)}
                        />
                        <img src={item?.file} alt="" className="photo" />
                      </div>
                    ))
                : gallery
                    .filter((item) => item.type === "video")
                    .map((item) => (
                      <div className="video-container" key={item?.id}>
                        <img
                          src={DeleteIcon}
                          alt=""
                          className="icon"
                          onClick={() => handleDelete(item?.id)}
                        />

                        <video
                          src={item?.file}
                          controls
                          poster="thumbnail.jpg"
                          preload="auto"
                          className="video"
                          width="240"
                          height="220"
                        />
                      </div>
                    ))}
            </div>
          )}
        </div>
      </Accordion>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="gallery-modal"
      >
        <div className="container">
          {isUploading ? (
            <div className="uploading">
              <img src={UploadingIcon} alt="" />
              <p>جاري الرفع برجاء الانتظار وعدم مغادرة الصفحة...</p>
            </div>
          ) : (
            <form action="" onSubmit={handleSubmit}>
              <div className="form-control">
                {file && (
                  <>
                    {selectedType === "photo" ? (
                      <img
                        src={
                          file instanceof File
                            ? URL.createObjectURL(file)
                            : file
                        }
                        alt=""
                      />
                    ) : (
                      <video
                        src={
                          file instanceof File
                            ? URL.createObjectURL(file)
                            : file
                        }
                        alt=""
                        width={"200px"}
                        height={"160px"}
                        controls
                      />
                    )}

                    <CloseIcon className="icon" onClick={() => setFile(null)} />
                  </>
                )}
                <label htmlFor="file">
                  <FileUploadIcon className="icon" /> اختر الملف
                </label>
                <div className="control-group">
                  <div className="form-select">
                    <span htmlFor="admin" className="select-label">
                      فيديو
                    </span>
                    <input
                      id="admin"
                      type="radio"
                      name="type"
                      value="video"
                      checked={selectedType === "video"}
                      onChange={handleTypeChange}
                      required
                    />
                  </div>

                  <div className="form-select">
                    <span htmlFor="subadmin" className="select-label">
                      صورة
                    </span>
                    <input
                      id="subadmin"
                      type="radio"
                      name="type"
                      value="photo"
                      checked={selectedType === "photo"}
                      onChange={handleTypeChange}
                      required
                    />
                  </div>
                </div>
                <input
                  type="file"
                  id="file"
                  accept="image/*,video/*,.mkv"
                  onChange={(e) => setFile(e.target.files[0])}
                  required
                />
              </div>
              {file && (
                <Button text="رفع" size="large" variant="fill" type="submit" />
              )}
            </form>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Gallery;
