import "./Profile.scss";
import { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUploadOutlined";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Loader from "../../Components/Loader/Loader";
import { showErrorMessage } from "../../Utils/showErrorMessage";
import newRequest from "../../Utils/newRequest";
import Button from "../../Components/Button/Button";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../Redux/userSlice";

const Profile = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [image, setImage] = useState(null);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get("/get_profile");
      setProfile(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInfo = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (image) {
      try {
        const res = await newRequest.post(
          "/update_profile?type=admin",
          {
            ...profile,
            logo: image,
          },
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        dispatch(loginSuccess(res?.data?.data));
        message.success("تم التعديل بنجاح");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res = await newRequest.post("/update_profile?type=admin", {
          ...profile,
        });
        dispatch(loginSuccess(res?.data?.data));
        message.success("تم التعديل بنجاح");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handlePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (newPassword !== confirmPassword) {
      setLoading(false);
      return message.error("كلمات المرور الجديدة غير متطابقة");
    }
    try {
      await newRequest.post("/change_password", {
        password: password,
        new_password: newPassword,
        new_password_confirmation: confirmPassword,
      });
      message.success("تم تغير كلمة المرور بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="profile">
      <div className="container">
        <h4 className="title">عرض تفاصيل الصفحة الشخصية</h4>
        {loading ? (
          <Loader />
        ) : (
          <>
            <form action="" onSubmit={handleInfo}>
              <div className="file-group">
                {(image || profile?.logo) && (
                  <img
                    src={
                      (image || profile?.logo) instanceof File
                        ? URL.createObjectURL(image || profile?.logo)
                        : image || profile?.logo
                    }
                    alt=""
                  />
                )}

                <div className="form-control">
                  <label htmlFor="file" className="file-label">
                    <FileUploadIcon className="icon" />
                    تحميل الصورة
                  </label>
                  <input
                    type="file"
                    id="file"
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="control-group">
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">الاسم</label>
                  <TextField
                    fullWidth
                    className="form-input"
                    value={profile?.name}
                    name="name"
                    required
                    onChange={(e) =>
                      setProfile((prev) => {
                        return { ...prev, [e.target.name]: e.target.value };
                      })
                    }
                  />
                </FormControl>
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">الايميل</label>
                  <TextField
                    fullWidth
                    className="form-input"
                    type="email"
                    required
                    name="email"
                    value={profile?.email}
                    onChange={(e) =>
                      setProfile((prev) => {
                        return { ...prev, [e.target.name]: e.target.value };
                      })
                    }
                  />
                </FormControl>
              </div>

              <div className="control-group">
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">رقم الموبايل</label>
                  <TextField
                    fullWidth
                    required
                    className="form-input"
                    value={profile?.phone}
                    name="phone"
                    onChange={(e) =>
                      setProfile((prev) => {
                        return { ...prev, [e.target.name]: e.target.value };
                      })
                    }
                  />
                </FormControl>
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">اخر تسجيل علي النظام</label>
                  <TextField
                    fullWidth
                    className="form-input"
                    value={profile?.last_login_at}
                    disabled
                  />
                </FormControl>
              </div>
              <div className="form-actions">
                <Button text="تعديل" size="large" variant="fill" />
              </div>
            </form>

            <form action="" onSubmit={handlePassword}>
              <div className="control-group">
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">كلمة المرور الحالية</label>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    inputProps={{
                      minLength: 8,
                    }}
                    required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">كلمة المرور الجديدة</label>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    name="new_password"
                    onChange={(e) => setNewPassword(e.target.value)}
                    inputProps={{
                      minLength: 8,
                    }}
                    required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl sx={{ m: 1, flex: 1 }} className="select">
                  <label className="form-label">تاكيد كلمة المرور</label>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    name="new_password_confirmation"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    inputProps={{
                      minLength: 8,
                    }}
                    required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>

              <div className="form-actions">
                <Button text="تغير" size="large" variant="fill" />
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
