import axios from "axios";

const newRequest = axios.create({
  // baseURL: "https://tsquarehost.info/dr-math.tsquarehost.info/public/api",
  baseURL: "https://drmath.co/backend/public/api",
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "ar",
  },
});

newRequest.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user?.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default newRequest;
