import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countries: [],
};

export const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    storeCountries: (state, action) => {
      state.countries = action.payload;
    },
  },
});

export const { storeCountries } = countrySlice.actions;

export default countrySlice.reducer;
