import moment from "moment";

export const getAge = (birthDate) => {
  const today = moment();
  const birth = moment(birthDate, "YYYY/MM/DD");
  let age = today.diff(birth, "years");
  if (today.isBefore(birth.add(1, "years").startOf("day"))) {
    age--;
  }
  return age;
};
