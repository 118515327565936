import { message } from "antd";

export const showErrorMessage = (error) => {
  // message.config({});

  if (error.response && error.response.data && error.response.data.message) {
    message.error(error.response.data.message);
  } else {
    message.error("An error occurred. Please try again later.");
  }
};
