import "./MessageModal.scss";
import AttentionIcon from "./../../../Assets/attention-icon.svg";
import CloseIcon from "./../../../Assets/close-icon.svg";
import { Modal } from "@mui/material";
import { useState } from "react";

const MessageModal = (props) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleDelete = () => {
    setConfirmDelete(true);
    props.onConfirmDelete(true);
  };
  return (
    <Modal
      open={props?.open}
      onClose={props?.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="message-modal"
    >
      <div className="container">
        <div className="header" onClick={props?.onClose}>
          <img src={CloseIcon} alt="" />
        </div>
        <div className="body">
          <img src={AttentionIcon} alt="" />
          <h2>انتبة</h2>

          <h5>{props?.message}</h5>

          <h6>{props?.extraMessage}</h6>
        </div>
        <div className="footer">
          <button className="action-btn" onClick={handleDelete}>
            حذف
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MessageModal;
