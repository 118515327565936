import "./SubDepartments.scss";
import Button from "../../Components/Button/Button";
import EditIcon from "./../../Assets/edit-icon.svg";
import FilterIcon from "./../../Assets/filter-icon.svg";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "./../../Assets/delete-icon.svg";
import CloseIcon from "./../../Assets/close-icon.svg";
import AttentionIcon from "./../../Assets/attention-icon.svg";
import AddIcon from "@mui/icons-material/AddOutlined";
import { SubDepartmentsColumns } from "../../SourceData";
import ViewIcon from "./../../Assets/details-icon.svg";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import { Modal, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import newRequest from "../../Utils/newRequest";
import { showErrorMessage } from "../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../Components/Loader/Loader";

const SubDepartments = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:767px)");
  const [subDepartments, setSubDepartments] = useState([]);
  const [rowId, setRowId] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: false,
    quickFilterValues: [],
  });
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const gridToolbarRef = useRef(null);

  useEffect(() => {
    getSubDepartments();
  }, []);

  const getSubDepartments = async () => {
    setLoading(true);
    try {
      const response = await newRequest.get("/level_categories");
      setSubDepartments(response?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMultiDelete = async () => {
    if (selectedRowsIds.length === 0) {
      return message.warning("من فضك قم بتحديد الصفوف التي تريد حذفها اولاً");
    }
    setLoading(true);
    try {
      for (const rowId of selectedRowsIds) {
        await newRequest.delete(`/level_categories/${rowId}`);
      }
      await getSubDepartments();
      setSelectedRowsIds([]);
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      await newRequest.delete(`/level_categories/${rowId}`);
      await getSubDepartments();
      setRowId("");
      message.success("تم حذف القسم بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  const handleActive = async (id) => {
    setLoading(true);
    try {
      await newRequest.put(`/level_categories/${id}/activateToggle`);
      await getSubDepartments();
      message.success("تم التعديل بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    setFilterModel((prevModel) => ({
      ...prevModel,
      quickFilterValues: [event.target.value],
    }));
  };

  const handleFilterClickd = () => {
    if (gridToolbarRef.current) {
      gridToolbarRef.current.click();
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton ref={gridToolbarRef} />
      </GridToolbarContainer>
    );
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 280,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="cell-action" style={{ display: "flex" }}>
            <div
              className="cell-btn"
              onClick={() => {
                navigate("/sub-departments/edit", { state: params?.row });
              }}
            >
              <img src={EditIcon} alt="" />
            </div>

            <div
              className="cell-btn"
              onClick={() => {
                setOpenMessage(true);
                setRowId(params?.row?.id);
              }}
            >
              <img src={DeleteIcon} alt="" />
            </div>

            <div
              className="cell-btn "
              onClick={() => handleActive(params?.row?.id)}
            >
              <Button
                text={params.row.active === 1 ? "ايقاف" : "تفعيل"}
                size={"small"}
                variant={params.row.active === 1 ? "fill" : "outlined"}
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="departments">
      <div className="container">
        <div className="spcial-header">
          <h4 className="title">الاقسام الفرعية </h4>

          <div className="search">
            <div className="search-container">
              <SearchIcon className="icon" />
              <input
                type="text"
                placeholder="البحث ..."
                onChange={handleSearch}
              />
            </div>
            <div className="filter-container" onClick={handleFilterClickd}>
              <img src={FilterIcon} alt="" />
            </div>
          </div>

          <div className="actions-container">
            <Button
              text={"اضف قسم فرعي"}
              size={isMobile ? "full" : "fit"}
              variant={"fill"}
              icon={<AddIcon />}
              path={"/sub-departments/create"}
              isMobile=""
            />

            <Button
              text={"حذف متعدد"}
              size={isMobile ? "full" : "small"}
              variant={"outlined"}
              onClick={handleMultiDelete}
            />
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="data-grid">
            <DataGrid
              sx={{ minHeight: 600 }}
              columns={SubDepartmentsColumns.concat(actionColumn)}
              rows={subDepartments.map((row, index) => {
                return { ...row, index: index + 1 };
              })}
              params={subDepartments}
              disableDensitySelector
              disableRowSelectionOnClick
              disableVirtualization
              slots={{
                toolbar: CustomToolbar,
              }}
              filterModel={filterModel}
              onFilterModelChange={(newModel) => setFilterModel(newModel)}
              initialState={{
                ...subDepartments.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 50]}
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setSelectedRowsIds(newRowSelectionModel);
              }}
              rowSelectionModel={selectedRowsIds}
            />
          </div>
        )}
      </div>

      {/* Message Modal */}
      <Modal
        open={openMessage}
        onClose={() => {
          setOpenMessage(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div
            className="header"
            onClick={() => {
              setOpenMessage(false);
              setRowId("");
            }}
          >
            <img src={CloseIcon} alt="" />
          </div>
          <div className="body">
            <img src={AttentionIcon} alt="" />
            <h2>انتبة</h2>
            <h5>هل انت متاكد من حذف هذا القسم من</h5>
            <h6>الاقسام الفرعية</h6>
          </div>
          <div className="footer">
            <button
              className="action-btn"
              onClick={() => {
                setOpenMessage(false);
                handleDelete();
              }}
            >
              حذف
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SubDepartments;
