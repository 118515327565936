import "./StudentPoints.scss";
import EditIcon from "./../../../Assets/edit-icon.svg";
import { FormControl, Modal, TextField, useMediaQuery } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import CloseIcon from "./../../../Assets/close-icon.svg";
import AttentionIcon from "./../../../Assets/attention-icon.svg";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../../Components/Loader/Loader";
const StudentPoints = () => {
  const [links, setLinks] = useState({});
  const [points, setPoints] = useState("");
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [editLinks, setEditLinks] = useState(links || {});

  useEffect(() => {
    getLinks();
  }, []);

  const getLinks = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get("/settings");
      setLinks(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpenMessage(false);
    setLoading(true);
    try {
      await newRequest.post("/settings", { ...editLinks });
      getLinks();
    } catch (error) {
      showErrorMessage(error);
    } finally {
      message.success("تم التعديل بنجاح");
    }
  };

  return (
    <Fragment>
      <div className="student-points">
        <div className="container">
          <div className="header">
            <h3 className="title">نقاط الطلاب</h3>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="items-container">
              <div className="item">
                <span>النقاط مقابل عدد الاسئله الصحيحه ( تدريب )</span>
                <span>{links?.training_points}</span>
                <img
                  src={EditIcon}
                  alt=""
                  onClick={() => {
                    setOpenMessage(true);
                    setPoints("training_points");
                  }}
                />
              </div>
              <div className="item">
                <span>النقاط مقابل عدد الاسئله الصحيحه ( مسابقة )</span>
                <span>{links?.competition_points}</span>
                <img
                  src={EditIcon}
                  alt=""
                  onClick={() => {
                    setOpenMessage(true);
                    setPoints("competition_points");
                  }}
                />
              </div>
              <div className="item">
                <span>النقاط مقابل عدد الاسئله الصحيحه ( بطولة )</span>
                <span>{links?.championship_points}</span>
                <img
                  src={EditIcon}
                  alt=""
                  onClick={() => {
                    setOpenMessage(true);
                    setPoints("championship_points");
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Message Modal */}
      <Modal
        open={openMessage}
        onClose={() => {
          setOpenMessage(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div
            className="header"
            onClick={() => {
              setOpenMessage(false);
              setPoints("");
            }}
          >
            <img src={CloseIcon} alt="" />
          </div>
          <form action="" onSubmit={handleSubmit}>
            <div className="body">
              <h3>النقاط مقابل عدد الاسئله الصحية ( تدريب )</h3>
              <FormControl sx={{ m: 1, flex: 1, width: "100%" }}>
                <TextField
                  placeholder="ادخل عدد النقاط"
                  fullWidth
                  required
                  name={points}
                  onChange={(e) =>
                    setEditLinks((prev) => {
                      return { ...prev, [e.target.name]: e.target.value };
                    })
                  }
                />
              </FormControl>
            </div>
            <div className="footer">
              <button className="action-btn">حفظ التعديلات</button>
            </div>
          </form>
        </div>
      </Modal>
    </Fragment>
  );
};

export default StudentPoints;
