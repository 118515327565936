import "./MainInfo.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { getRemainingDays } from "../../../../Utils/getRemainingDays";

const MainInfo = (props) => {
  const user = props?.user;
  const startDate = user?.subscription?.start_date;
  const endDate = user?.subscription?.end_date;
  const remainingDays = getRemainingDays(startDate, endDate);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        الداتا الاساسية
      </AccordionSummary>
      <div className="tabels">
        <table>
          <tbody>
            <tr>
              <th>الاسم</th>
              <td>{user?.name}</td>
            </tr>
            <tr>
              <th>رقم الموبايل الشخصي</th>
              <td>{user?.phone}</td>
            </tr>
            <tr>
              <th>رقم موبايل العمل</th>
              <td>{user?.business_phone}</td>
            </tr>
            <tr>
              <th>الدولة</th>
              <td>{user?.country?.name}</td>
            </tr>
            <tr>
              <th>المحافظة</th>
              <td>{user?.city?.name}</td>
            </tr>
            <tr>
              <th>المنطقة</th>
              <td>{user?.region?.name}</td>
            </tr>
            <tr>
              <th>اسم الباقة</th>
              <td>{user?.subscription?.package?.title}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <th>تاريخ التسجيل</th>
              <td>{moment(user?.created_at).format("YYYY-MM-DD")}</td>
            </tr>
            <tr>
              <th>عدد الايام المتبقة علي اشتراكة</th>
              <td>{remainingDays}</td>
            </tr>
            <tr>
              <th>تاريخ انتهاء الاشتراك</th>
              <td>{moment(user?.end_date).format("YYYY-MM-DD")}</td>
            </tr>
            <tr>
              <th>عدد التدريبات التي قام بها</th>
              <td>{user?.trainings_count}</td>
            </tr>
            <tr>
              <th>عدد المسابقات التي دخل فيها</th>
              <td>{user?.competitions_count}</td>
            </tr>
            <tr>
              <th>حالة الحساب</th>
              <td>{user?.active === 1 ? "مفعل" : "غير مفعل"}</td>
            </tr>
            <tr>
              <th>طريقة التسجيل</th>
              <td>{user?.register_method}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Accordion>
  );
};

export default MainInfo;
