import "./Notifications.scss";
import { Modal, useMediaQuery } from "@mui/material";
import Button from "../../Components/Button/Button";
import AddIcon from "@mui/icons-material/AddOutlined";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "./../../Assets/delete-icon.svg";
import ViewIcon from "./../../Assets/details-icon.svg";
import AttentionIcon from "./../../Assets/attention-icon.svg";
import CloseIcon from "./../../Assets/close-icon.svg";
import { DataGrid } from "@mui/x-data-grid";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationColumns } from "../../SourceData";
import newRequest from "../../Utils/newRequest";
import { showErrorMessage } from "../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../Components/Loader/Loader";
import moment from "moment";

const Notifications = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [singleNotification, setSingleNotification] = useState({});
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [rowId, setRowId] = useState("");
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: false,
    quickFilterValues: [],
  });

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(`/admin_notifications`);
      setNotifications(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleMultiDelete = async () => {
    if (selectedRowsIds.length === 0) {
      return message.warning("من فضك قم بتحديد الصفوف التي تريد حذفها اولاً");
    }
    setLoading(true);
    try {
      for (const rowId of selectedRowsIds) {
        await newRequest.delete(`/admin_notifications/${rowId}`);
      }
      await getNotifications();
      setSelectedRowsIds([]);
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenMessage(false);
    setLoading(true);
    try {
      await newRequest.delete(`/admin_notifications/${rowId}`);
      getNotifications();
      setRowId("");
      message.success("تم حذف الاشعار بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setOpenMessage(false);
    }
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    setFilterModel((prevModel) => ({
      ...prevModel,
      quickFilterValues: [event.target.value],
    }));
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 280,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div
            className="cell-action"
            style={{
              display: "flex",
              gap: "15px",
              justifyContent: "center",
            }}
          >
            {/* View Action */}
            <div
              className="cell-btn"
              onClick={() => {
                setSingleNotification(params?.row);
                setOpenView(true);
              }}
            >
              <img src={ViewIcon} alt="" />
            </div>

            {/* Delete Action */}
            <div
              className="cell-btn"
              onClick={() => {
                setOpenMessage(true);
                setRowId(params?.row?.id);
              }}
            >
              <img src={DeleteIcon} alt="" />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <div className="notification">
        <div className="container">
          <div className="spcial-header">
            <h4 className="title">الاشعارات</h4>
            <div className="search">
              <div className="search-container">
                <SearchIcon className="icon" />
                <input
                  type="text"
                  placeholder="ابحث عن ..."
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="actions-container">
              <Button
                size={isMobile ? "medium" : "medium"}
                variant="fill"
                text="اضافة اشعار"
                icon={<AddIcon />}
                path="/notifications/create"
              />
              <Button
                size={isMobile ? "small" : "medium"}
                variant="outlined"
                text="حذف متعدد"
                onClick={handleMultiDelete}
              />
            </div>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="data-grid">
              <DataGrid
                columns={NotificationColumns.concat(actionColumn)}
                rows={notifications.map((row, index) => {
                  return { ...row, index: index + 1 };
                })}
                params={notifications}
                disableDensitySelector
                disableRowSelectionOnClick
                disableColumnMenu
                disableVirtualization
                filterModel={filterModel}
                onFilterModelChange={(newModel) => setFilterModel(newModel)}
                initialState={{
                  ...notifications.initialState,
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelectedRowsIds(newRowSelectionModel);
                }}
                rowSelectionModel={selectedRowsIds}
              />
            </div>
          )}
        </div>
      </div>

      {/* Delete Message Modal */}
      <Modal
        open={openMessage}
        onClose={() => {
          setOpenMessage(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div
            className="header"
            onClick={() => {
              setOpenMessage(false);
              setRowId("");
            }}
          >
            <img src={CloseIcon} alt="" />
          </div>
          <div className="body">
            <img src={AttentionIcon} alt="" />
            <h2>انتبة</h2>
            <h5>هل انت متاكد من حذف هذا الاشعار من</h5>
            <h6>الاشعارات</h6>
          </div>
          <div className="footer">
            <button className="action-btn" onClick={handleDelete}>
              حذف
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={openView}
        onClose={() => {
          setOpenView(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="view-modal"
      >
        <div className="container">
          <table>
            <tbody>
              <tr>
                <th>العنوان</th>
                <td>{singleNotification?.title}</td>
              </tr>
              <tr>
                <th>الوصف</th>
                <td>{singleNotification?.desc}</td>
              </tr>
              <tr>
                <th>موجه الي</th>
                <td>{singleNotification?.type}</td>
              </tr>
              <tr>
                <th>تاريخ الانشاء</th>
                <td>
                  {moment(singleNotification?.created_at).format("YYYY-MM-DD")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </Fragment>
  );
};

export default Notifications;
