import "./StudentTrainersTermsAndConditions.scss";
import AddIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "./../../../../Assets/delete-icon.svg";
import EditIcon from "./../../../../Assets/edit-icon.svg";
import { useMediaQuery, Modal } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AttentionIcon from "./../../../../Assets/attention-icon.svg";
import CloseIcon from "./../../../../Assets/close-icon.svg";
import { useEffect, useState } from "react";
import Button from "../../../../Components/Button/Button";
import newRequest from "../../../../Utils/newRequest";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../../../Components/Loader/Loader";

const StudentTrainersTermsAndConditions = () => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width:767px)");
  const navigate = useNavigate();
  const [terms, setTerms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [rowId, setRowId] = useState("");
  const isTrainer = location?.pathname?.includes("/trainer");

  useEffect(() => {
    getTerms();
  }, [isTrainer]);

  const getTerms = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(
        `/terms?type=${isTrainer ? "trainer" : "student"}`
      );
      setTerms(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenMessage(false);
    try {
      await newRequest.delete(`/terms/${rowId}`);
      await getTerms();
      setRowId("");
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  return (
    <>
      <div className="terms-and-conditions-students">
        <div className="container">
          <div className="spcial-header">
            <h4 className="title">
              اعدادت الشروط والاحكام ( {isTrainer ? "المدرب" : "الطالب"} )
            </h4>
            <div className="actions-container">
              <Button
                text={"اضافة شرط"}
                size={isMobile ? "full" : "medium"}
                variant={"fill"}
                icon={<AddIcon />}
                path={`/terms-and-conditions/${
                  isTrainer ? "trainers" : "students"
                }/create`}
              />
            </div>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <div className="conditions-container">
              {terms.map((term, index) => (
                <div className="condition container" key={term?.id}>
                  <div className="header">
                    <h6 className="title">{term?.title}</h6>
                    <div className="actions-container">
                      <div
                        className="action-link"
                        // to="/terms-and-conditions/trainers/edit"
                        onClick={() => {
                          navigate(
                            isTrainer
                              ? "/terms-and-conditions/trainers/edit"
                              : "/terms-and-conditions/students/edit",
                            { state: term }
                          );
                        }}
                      >
                        <img src={EditIcon} alt="" />
                      </div>
                      <Link
                        className="action-link"
                        onClick={() => {
                          setOpenMessage(true);
                          setRowId(term?.id);
                        }}
                      >
                        <img src={DeleteIcon} alt="" />
                      </Link>
                    </div>
                  </div>
                  <div className="body">
                    <p>{index + 1}-</p>
                    <p>{term?.content}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {/* Message Modal */}
      <Modal
        open={openMessage}
        onClose={() => {
          setOpenMessage(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div
            className="header"
            onClick={() => {
              setOpenMessage(false);
            }}
          >
            <img src={CloseIcon} alt="" />
          </div>
          <div className="body">
            <img src={AttentionIcon} alt="" />
            <h2>انتبة</h2>
            <h5>هل انت متاكد من حذف هذا الشرط من</h5>
            <h6>الشروط والاحكام</h6>
          </div>
          <div className="footer">
            <button className="action-btn" onClick={handleDelete}>
              حذف
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StudentTrainersTermsAndConditions;
