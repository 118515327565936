import "./Button.scss";
import { useNavigate } from "react-router-dom";

const Button = (props) => {
  const getSizeStyle = () => {
    switch (props?.size) {
      case "small":
        return { width: "100px" };
      case "medium":
        return { width: "150px" };
      case "large":
        return { width: "200px" };
      case "full":
        return { width: "100%" };
      case "fit":
        return { width: "fit-content" };
      default:
        return {};
    }
  };

  const sizeStyle = getSizeStyle();
  const navigate = useNavigate();

  const handleClick = () => {
    if (props?.path) {
      navigate(props.path);
    }
    if (props?.onClick) {
      props.onClick();
    }
  };

  return (
    <button
      type={props?.type}
      className={props?.variant === "fill" ? "fill outlined" : "outlined"}
      style={sizeStyle}
      onClick={handleClick}
      form={props.form} // Add the form attribute
    >
      {props?.text}
      {props?.icon && props?.icon}
    </button>
  );
};

export default Button;
