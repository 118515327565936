import "./CreateTrainer.scss";
import Button from "../../../Components/Button/Button";
import AddIcon from "@mui/icons-material/AddOutlined";
import {
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { message } from "antd";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Loader from "../../../Components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";

const CreateTrainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state;
  const isCreate = location.pathname.includes("/create");
  const isMobile = useMediaQuery("(max-width:767px)");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false || user?.special_trainer);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);
  const [packages, setPackages] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [changePasswordClicked, setChangePasswordClicked] = useState(false);
  const [isPasswordMatched, setIsPasswordMatched] = useState(true);
  const [editPassword, setEditPassword] = useState({
    new_password: "",
    new_password_confirmation: "",
  });
  const [trainer, setTrainer] = useState({
    name: user?.name || "",
    business_phone: user?.business_phone || "",
    phone: user?.phone || "",
    email: user?.email || "",
    gender: user?.gender || "",
    country_id: user?.country?.id || "",
    city_id: user?.city?.id || "",
    region_id: user?.region?.id || "",
    password: "",
    password_confirmation: "",
    role: "trainer",
    special_trainer: checked ? 1 : 0,
    provide_name: "admin",
  });

  useEffect(() => {
    getCountries();
    getPackages();
  }, []);

  useEffect(() => {
    getCities();
  }, [trainer?.country_id]);

  useEffect(() => {
    getRegions();
  }, [trainer?.city_id]);

  const getPackages = async () => {
    setLoading(true);
    try {
      const countriesResponce = await newRequest.get(`/packages?type=trainer`);
      setPackages(countriesResponce?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const getCountries = async () => {
    setLoading(true);
    try {
      const countriesResponce = await newRequest.get(`/countries`, {
        headers: {
          "Accept-Language": "ar",
        },
      });
      setCountries(countriesResponce?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const getCities = async () => {
    try {
      const citiesResponce = await newRequest.get(
        `/cities?country_id=${trainer?.country_id}`,
        {
          headers: {
            "Accept-Language": "ar",
          },
        }
      );
      setCities(citiesResponce?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  const getRegions = async () => {
    try {
      const regionsResponce = await newRequest.get(
        `/regions?city_id=${trainer?.city_id || user?.region?.id}`,
        {
          headers: {
            "Accept-Language": "ar",
          },
        }
      );
      setRegions(regionsResponce?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  const handleChange = (e) => {
    if (changePasswordClicked) {
      setEditPassword((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else {
      setTrainer((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSwitch = (event) => {
    setChecked(event.target.checked);
  };

  const handeSpecialtrainer = async () => {
    setLoading(true);
    try {
      const res = await newRequest.put(`trainers/${user.id}/specialToggle`);

      setChecked(res?.data?.data?.special_trainer);
      message.success("تم تعديل المدرب بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (isCreate) {
      if (trainer?.password !== trainer?.password_confirmation) {
        setIsPasswordMatched(false);
        return;
      }
      try {
        await newRequest.post("/users", {
          ...trainer,
          special_trainer: checked,
        });
        setTrainer({});
        setChecked(false);
        message.success("تم اضافة المدرب بنجاح");
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setIsPasswordMatched(true);
        setLoading(false);
      }
    } else {
      const { password, password_confirmation, ...trainerWithoutPasswords } =
        trainer;
      try {
        await newRequest.put(`/users/${user?.id}`, {
          ...trainerWithoutPasswords,
        });
        message.success("تم تعديل المدرب بنجاح");
        navigate(`/trainers`);
      } catch (error) {
        showErrorMessage(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handeChangePassword = async () => {
    if (editPassword.new_password !== editPassword.new_password_confirmation) {
      setIsPasswordMatched(false);
      return;
    }
    setLoading(true);
    try {
      await newRequest.post(`/change_user_password/${user.id}`, {
        ...editPassword,
      });
      message.success("تم تعديل  كلمة المرور بنجاح");
      navigate(`/trainers`);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="create">
      <div className="container">
        <div className="header">
          <h3>{isCreate ? "اضافه مدرب جديد" : "تعديل مدرب "}</h3>

          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              text={checked ? "حذف كمدرب مميز" : "اضافتة كمدرب مميز"}
              variant={checked ? "outlined" : "fill"}
              size={isMobile ? "full" : "large"}
              icon={<AddIcon />}
              onClick={!isCreate ? handeSpecialtrainer : () => {}}
            />
            {!isCreate && (
              <Button
                text={
                  changePasswordClicked
                    ? "تعديل المعلومات"
                    : "تعديل كلمة المرور"
                }
                variant="fill"
                size={isMobile ? "full" : "medium"}
                onClick={() => setChangePasswordClicked(!changePasswordClicked)}
              />
            )}
            {isCreate && (
              <div className="switch-btn">
                <Switch checked={checked} onChange={handleSwitch} />
              </div>
            )}
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            {!changePasswordClicked && (
              <form action="" onSubmit={handleSubmit}>
                <div className="control-group">
                  <FormControl sx={{ m: 1, flex: 1 }} className="select">
                    <label className="form-label">الاسم</label>
                    <TextField
                      required
                      fullWidth
                      id="fullWidth"
                      className="form-input"
                      placeholder="ادخل الاسم كامل"
                      name="name"
                      onChange={handleChange}
                      value={trainer?.name}
                    />
                  </FormControl>

                  <FormControl sx={{ m: 1, flex: 1 }} className="select">
                    <label className="form-label">رقم الموبايل</label>
                    <TextField
                      required
                      fullWidth
                      id="fullWidth"
                      className="form-input"
                      placeholder="ادخل الاسم كامل"
                      name="business_phone"
                      onChange={handleChange}
                      value={trainer?.business_phone}
                    />
                  </FormControl>

                  <FormControl sx={{ m: 1, flex: 1 }} className="select">
                    <label className="form-label">رقم الموبايل الشخصي</label>
                    <TextField
                      required
                      fullWidth
                      id="fullWidth"
                      className="form-input"
                      placeholder="ادخل رقم الموبايل الشخصي"
                      name="phone"
                      onChange={handleChange}
                      value={trainer?.phone}
                    />
                  </FormControl>
                </div>

                <div className="control-group">
                  <FormControl sx={{ m: 1, flex: 1 }} className="select">
                    <label htmlFor="aria-label">الدولة</label>
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      placeholder="ادخل الدولة الحالية"
                      required
                      className="select"
                      name="country_id"
                      value={trainer?.country_id}
                      onChange={handleChange}
                    >
                      {countries?.map((item) => (
                        <MenuItem value={item?.id} key={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, flex: 1 }} className="select">
                    <label htmlFor="aria-label">المدينة</label>
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      placeholder="ادخل المدينة الحالية"
                      required
                      className="select"
                      value={trainer?.city_id}
                      name="city_id"
                      onChange={handleChange}
                    >
                      {cities?.map((item) => (
                        <MenuItem value={item?.id} key={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, flex: 1 }} className="select">
                    <label htmlFor="aria-label">المنطقة</label>
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      placeholder="ادخل المنطقة الحالية"
                      required
                      className="select"
                      value={trainer?.region_id}
                      name="region_id"
                      onChange={handleChange}
                    >
                      {regions?.map((item) => (
                        <MenuItem value={item?.id} key={item?.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="control-group">
                  <FormControl sx={{ m: 1, flex: 1 }} className="select">
                    <label className="form-label">البريد الالكتروني</label>
                    <TextField
                      required
                      fullWidth
                      id="fullWidth"
                      className="form-input"
                      placeholder="ادخل البريد الالكتروني"
                      name="email"
                      onChange={handleChange}
                      value={trainer?.email}
                    />
                  </FormControl>

                  <FormControl sx={{ m: 1, flex: 1 }} className="select">
                    <label htmlFor="aria-label">الجنس</label>
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      required
                      className="select"
                      placeholder="ادخل الجنس"
                      name="gender"
                      value={trainer?.gender}
                      onChange={handleChange}
                    >
                      <MenuItem value="male">ذكر</MenuItem>
                      <MenuItem value="female">أنثي</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, flex: 1 }} className="select">
                    <label htmlFor="aria-label">اسم الباقة</label>
                    <Select
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      required
                      className="select"
                      placeholder="ادخل الجنس"
                      name="package_name"
                      value={trainer?.package_name}
                      onChange={handleChange}
                    >
                      {packages?.map((item) => (
                        <MenuItem value={item?.id} key={item?.id}>
                          {item?.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                {isCreate && (
                  <div className="control-group">
                    <FormControl sx={{ m: 1, flex: 1 }} className="select">
                      <label className="form-label">كلمة المرور</label>
                      <TextField
                        required
                        fullWidth
                        id="fullWidth"
                        className="form-input"
                        placeholder="ادخل كلمة المرور"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        onChange={handleChange}
                        error={!isPasswordMatched}
                        helperText={
                          !isPasswordMatched && "كلمة المرور غير متطابقة"
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <FormControl sx={{ m: 1, flex: 1 }} className="select">
                      <label className="form-label">تاكيد كلمة المرور</label>
                      <TextField
                        required
                        fullWidth
                        id="fullWidth"
                        className="form-input"
                        placeholder="تأكيد كلمة المرور"
                        name="password_confirmation"
                        type={showPassword ? "text" : "password"}
                        onChange={handleChange}
                        error={!isPasswordMatched}
                        helperText={
                          !isPasswordMatched && "كلمة المرور غير متطابقة"
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </div>
                )}

                <div className="form-actions">
                  <Button
                    text={isCreate ? "اضافة" : "تعديل"}
                    size="small"
                    variant="fill"
                  />
                </div>
              </form>
            )}

            {/* For Change Password only ↓ */}
            {changePasswordClicked && (
              <form action="" onSubmit={handeChangePassword}>
                <div className="control-group">
                  <FormControl sx={{ m: 1, flex: 1 }} className="select">
                    <label className="form-label">كلمة المرور</label>
                    <TextField
                      required
                      fullWidth
                      id="fullWidth"
                      className="form-input"
                      placeholder="ادخل كلمة المرور"
                      name="new_password"
                      onChange={handleChange}
                      value={editPassword.new_password}
                      type={showPassword ? "text" : "password"}
                      error={!isPasswordMatched}
                      helperText={
                        !isPasswordMatched && "كلمة المرور غير متطابقة"
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <FormControl sx={{ m: 1, flex: 1 }} className="select">
                    <label className="form-label">تاكيد كلمة المرور</label>
                    <TextField
                      required
                      fullWidth
                      id="fullWidth"
                      className="form-input"
                      value={editPassword.new_password_confirmation}
                      name="new_password_confirmation"
                      onChange={handleChange}
                      placeholder="تأكيد كلمة المرور"
                      type={showPassword ? "text" : "password"}
                      error={!isPasswordMatched}
                      helperText={
                        !isPasswordMatched && "كلمة المرور غير متطابقة"
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </div>

                <div className="form-actions">
                  <Button text="تغير" size="small" variant="fill" />
                </div>
              </form>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CreateTrainer;
