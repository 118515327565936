import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  trainers: [],
};

export const trainerSlice = createSlice({
  name: "trainer",
  initialState,
  reducers: {
    questionFetched: (state, action) => {
      state.questions = action.payload;
    },
    questionUpdated: (state, action) => {
      state.questions = action.payload;
    },
  },
});

export const { trainerFetched, trainerUpdated } = trainerSlice.actions;

export default trainerSlice.reducer;
