import "./SpecialStudents.scss";
import {
  Autocomplete,
  Checkbox,
  Modal,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "../../../../Components/Button/Button";
import AddIcon from "@mui/icons-material/AddOutlined";
import Person from "./../../../../Assets/person-icon.png";
import FilterIcon from "./../../../../Assets/filter-icon.svg";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect, useState } from "react";
import { getAge } from "../../../../Utils/getAge";
import { message } from "antd";
import newRequest from "../../../../Utils/newRequest";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import Loader from "../../../../Components/Loader/Loader";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SpecialStudents = ({ trainer }) => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [updatedTrainer, setUpdatedTrainer] = useState(trainer);
  // const students = useSelector((state) => state?.students?.students);
  const [students, setStudents] = useState([]);
  const [selectedStudentIds, setSelectedStudentIds] = useState(
    trainer?.special_students?.map((item) => item?.id)
  );

  useEffect(() => {
    getStudents();
  }, []);

  const getStudents = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(`/students`);
      setStudents(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const getTrainer = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(`/trainers/${trainer?.id}`);
      setUpdatedTrainer(res?.data?.data);
      const specialStudents = res?.data?.data?.special_students?.map(
        (item) => item?.id
      );
      setSelectedStudentIds(specialStudents);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setOpen(false);
    try {
      await newRequest.post(
        `multiple_special_students?trainer_id=${trainer.id}`,
        { students: selectedStudentIds }
      );
      getTrainer();
      message.success("تم التعديل بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    setOpen(false);
    try {
      await newRequest.post(`/friend/${id}?trainer_id=${trainer.id}`, {
        students: selectedStudentIds,
      });
      getTrainer();
      message.success("تم التعديل بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
    }
  };

  const handleChange = (event, value) => {
    setSelectedStudentIds(value.map((option) => option.id));
  };

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          الطلاب المميزين
        </AccordionSummary>

        <div className="close-student container">
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className="actions-container">
                <div>
                  <Button
                    text="اضافة طالب مميز"
                    variant="outlined"
                    size="fit"
                    icon={<AddIcon />}
                    onClick={() => setOpen(true)}
                  />
                </div>
              </div>
              {updatedTrainer?.special_students?.map((item, index) => (
                <div className="student" key={index}>
                  <div className="student-header">
                    <div className="text-container">
                      <img
                        src={item?.logo || Person}
                        alt=""
                        className="student-img"
                      />
                      <div className="wrapper">
                        <span>طالب/ {item?.name}</span>
                        <span dir="ltr">{item?.phone}</span>
                        <span>العمر : {getAge(item?.birth_date)}</span>
                      </div>
                    </div>
                    <div className="actions-container">
                      <div>
                        <Button
                          text="الغاء الصداقة"
                          variant="outlined"
                          size={isMobile ? "full" : "fit"}
                          onClick={() => handleDelete(item.id)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="student-body">
                    {item?.levels?.map((level) => (
                      <span key={level?.id}>{level?.title}</span>
                    ))}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </Accordion>

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="search-modal"
      >
        <div className="container">
          <div className="header">
            <img src={FilterIcon} alt="" />
            <h3>اختيار طالب مميز</h3>
          </div>

          <div className="body">
            <Autocomplete
              disablePortal
              multiple
              id="checkboxes-tags-demo"
              options={students}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              getOptionKey={(option) => option.id}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField
                  // sx={{ height: "40px" }}
                  {...params}
                  label="البحث بالاسم"
                  placeholder="الاسم"
                  multiline
                />
              )}
              value={students?.filter((student) =>
                selectedStudentIds?.includes(student?.id)
              )}
              onChange={handleChange}
            />
          </div>

          <div className="footer">
            <button className="action-btn" onClick={handleSubmit}>
              تاكيد
            </button>
            {/* <button className="action-btn">الغاء</button> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SpecialStudents;
