// LevelExam.js
import React from "react";
import { TextField } from "@mui/material";

const LevelExam = ({ item, examValues, onChange, questionType }) => {
  const renderTextField = (label, name) => (
    <TextField
      sx={{ flex: 1 }}
      name={name}
      id={`${name}-${item.id}`}
      className="form-input"
      pattern="[0-9]*"
      label={label}
      value={examValues[name] || ""}
      onChange={(e) => onChange(name, e.target.value)}
    />
  );

  return (
    <div className="exam-container container">
      <h4>{item?.title}</h4>
      <div className="control-group">
        <div className="form-control">
          <label className="form-label" htmlFor={`questions_count-${item.id}`}>
            عدد الاسئلة
          </label>
          <TextField
            required
            fullWidth
            id={`questions_count-${item.id}`}
            className="form-input"
            pattern="[0-9]*"
            placeholder="أدخل عدد الاسئلة"
            name="questions_count"
            value={examValues.questions_count || ""}
            onChange={(e) => onChange("questions_count", e.target.value)}
          />
        </div>
        {questionType === 1 && (
          <div className="form-control">
            <label className="form-label" htmlFor={`question_speed-${item.id}`}>
              سرعة السؤال
            </label>
            <TextField
              required
              fullWidth
              id={`question_speed-${item.id}`}
              className="form-input"
              pattern="[0-9]*"
              placeholder="ادخل سرعة السؤال بالثانية"
              name="question_speed"
              value={examValues.question_speed || ""}
              onChange={(e) => onChange("question_speed", e.target.value)}
            />
          </div>
        )}
      </div>

      <div className="control-group">
        <div className="form-control">
          <label className="form-label" htmlFor="name">
            عدد ارقام الاسئلة
          </label>
          <div className="text-field-container">
            {renderTextField("رقمين", "questions_count_2num")}
            {renderTextField("ثلاثة ارقام", "questions_count_3num")}
            {renderTextField("اربعة ارقام", "questions_count_4num")}
            {renderTextField("خمس ارقام", "questions_count_5num")}
            {renderTextField("ستة ارقام", "questions_count_6num")}
            {renderTextField("سبعة ارقام", "questions_count_7num")}
            {renderTextField("ثمانية ارقام", "questions_count_8num")}
            {renderTextField("تسعة ارقام", "questions_count_9num")}
            {renderTextField("عشرة ارقام", "questions_count_10num")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LevelExam;
