import "./TrainingsAndCompetitions.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "../../../../Components/Button/Button";
import DeleteIcon from "./../../../../Assets/delete-icon-pink.png";
import { useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  TrainerTrainingsColumns,
  TrainerCompetitionsColumns,
} from "../../../../SourceData";
import { useEffect, useState } from "react";
import newRequest from "../../../../Utils/newRequest";
import { showErrorMessage } from "../../../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../../../Components/Loader/Loader";

const TrainingsAndCompetitions = (props) => {
  const id = props?.id;
  const isMobile = useMediaQuery("(max-width:767px)");
  const [isTrainings, setIsTrainings] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rowId, setRowId] = useState("");
  const [trainingsAndCompetitions, setTrainingsAndCompetitions] = useState([]);
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  useEffect(() => {
    if (accordionExpanded) {
      getTrainingsAndCompetitions();
    }
  }, [accordionExpanded, isTrainings]);

  const getTrainingsAndCompetitions = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(
        `/${isTrainings ? "trainings" : "competitions"}?trainer_id=${id}`
      );
      setTrainingsAndCompetitions(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await newRequest.delete(
        `/${isTrainings ? "trainings" : "competitions"}/${id}`
      );
      getTrainingsAndCompetitions();
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleActive = async (id) => {
    setLoading(true);
    try {
      await newRequest.put(
        `/${isTrainings ? "trainings" : "competitions"}/${id}/activateToggle`
      );
      getTrainingsAndCompetitions();
      message.success("تم التعديل بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
      setRowId("");
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 220,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="cell-action" style={{ display: "flex" }}>
            <div
              className="cell-btn"
              onClick={() => {
                handleDelete(params?.row?.id);
              }}
            >
              <img src={DeleteIcon} alt="" />
            </div>

            <div
              className="cell-btn "
              onClick={() => {
                handleActive(params?.row?.id);
              }}
            >
              <Button
                text={params.row.active === true ? "ايقاف" : "تفعيل"}
                size={"small"}
                variant={params.row.active === true ? "fill" : "outlined"}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const handleAccordionChange = (event, isExpanded) => {
    setAccordionExpanded(isExpanded);
  };

  return (
    <Accordion onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        تفاصيل التدريبات و المسابقات
      </AccordionSummary>
      {loading ? (
        <Loader />
      ) : (
        <div className="training-details container">
          <div className="header">
            <div className="actions-container">
              <Button
                text="ألتدريبات"
                variant={isTrainings ? "fill" : "outlined"}
                size={isMobile ? "full" : "small"}
                onClick={() => setIsTrainings(true)}
              />
              <Button
                text="مسابقات"
                variant={isTrainings ? "outlined" : "fill"}
                size={isMobile ? "full" : "small"}
                onClick={() => setIsTrainings(false)}
              />
            </div>
            <Button
              text="حذف متعدد"
              variant="outlined"
              size={isMobile ? "full" : "small"}
            />
          </div>

          <div className="data-grid">
            <DataGrid
              sx={{ minHeight: 600 }}
              columns={
                isTrainings
                  ? TrainerTrainingsColumns.concat(actionColumn)
                  : TrainerCompetitionsColumns.concat(actionColumn)
              }
              rows={trainingsAndCompetitions.map((item, index) => {
                return { index: index + 1, ...item };
              })}
              paramas={trainingsAndCompetitions}
              disableDensitySelector
              loading={loading}
              checkboxSelection
              disableRowSelectionOnClick
              disableColumnMenu
              disableVirtualization
              initialState={{
                ...trainingsAndCompetitions.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25, 50]}
            />
          </div>
        </div>
      )}
    </Accordion>
  );
};

export default TrainingsAndCompetitions;
