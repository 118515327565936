import "./Championships.scss";
import { Fragment, useEffect, useRef, useState } from "react";
import Button from "../../Components/Button/Button";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "./../../Assets/close-icon.svg";
import AttentionIcon from "./../../Assets/attention-icon.svg";
import DeleteIcon from "./../../Assets/delete-icon.svg";
import ViewIcon from "./../../Assets/details-icon.svg";
import NotificationIcon from "./../../Assets/notifiction-icon.svg";
import AddIcon from "@mui/icons-material/AddOutlined";
import { Modal, useMediaQuery } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import FilterIcon from "./../../Assets/filter-icon.svg";
import { useNavigate } from "react-router-dom";
import { ChampionshipsColumns } from "../../SourceData";
import { message } from "antd";
import newRequest from "../../Utils/newRequest";
import { showErrorMessage } from "../../Utils/showErrorMessage";
import Loader from "../../Components/Loader/Loader";

const Championships = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowId, setRowId] = useState("");
  const [notifictionContent, setNotifictionContent] = useState("");
  const [champions, setChampions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterExcludeHiddenColumns: false,
    quickFilterValues: [],
  });
  const gridToolbarRef = useRef(null);
  const [selectedRowsIds, setSelectedRowsIds] = useState([]);

  useEffect(() => {
    getChampions();
  }, []);

  const getChampions = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get("/championships");
      setChampions(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendNotifiction = async () => {
    setOpenNotification(false);
    setLoading(true);
    try {
      await newRequest.post(`/notify_championship_subscribers`, {
        championship_id: rowId,
        content: notifictionContent,
      });
      message.success("تم ارسال الاشعار بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
      setRowId("");
      setNotifictionContent("");
    }
  };

  const handleMultiDelete = async () => {
    if (selectedRowsIds.length === 0) {
      return message.warning("من فضك قم بتحديد الصفوف التي تريد حذفها اولاً");
    }
    setLoading(true);
    try {
      for (const rowId of selectedRowsIds) {
        await newRequest.delete(`/championships/${rowId}`);
      }
      await getChampions();
      setSelectedRowsIds([]);
      message.success("تم الحذف بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setOpenMessage(false);
    setLoading(true);
    try {
      await newRequest.delete(`/championships/${rowId}`);
      await getChampions();
      message.success("تم حذف البطولة بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
      setRowId("");
    }
  };

  const handleActive = async (id) => {
    setLoading(true);
    try {
      await newRequest.put(`/championships/${id}/activateToggle`);
      await getChampions();
      message.success("تم التعديل بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    setFilterModel((prevModel) => ({
      ...prevModel,
      quickFilterValues: [event.target.value],
    }));
  };

  const handleFilterClickd = () => {
    if (gridToolbarRef.current) {
      gridToolbarRef.current.click();
    }
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton ref={gridToolbarRef} />
      </GridToolbarContainer>
    );
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "الإجراءات",
      width: 400,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="cell-action" style={{ display: "flex", gap: "16px" }}>
            {/* View Action */}
            <div
              className="cell-btn"
              onClick={() => {
                navigate("/championships/view", { state: params?.row });
              }}
            >
              <img src={ViewIcon} alt="" />
            </div>

            {/* Delete Action */}

            <div
              className="cell-btn"
              onClick={() => {
                setOpenMessage(true);
                setRowId(params.row.id);
              }}
            >
              <img src={DeleteIcon} alt="" />
            </div>
            {/* Notifiction Action */}
            <div
              className="cell-btn"
              onClick={() => {
                setOpenNotification(true);
                setRowId(params.row.id);
              }}
            >
              <img src={NotificationIcon} alt="" />
            </div>

            <Button
              text={params?.row?.active === true ? "ايقاف" : "تفعيل"}
              size={"small"}
              variant={params.row.active === true ? "fill" : "outlined"}
              onClick={() => handleActive(params.row.id)}
            />

            <Button
              text="اضافة امتحان"
              size={"small"}
              variant="fill"
              onClick={() =>
                navigate("/championships/create-exam", { state: params?.row })
              }
            />
          </div>
        );
      },
    },
  ];

  return (
    <Fragment>
      <div className="championships">
        <div className="container">
          <div className="spcial-header">
            <h4 className="title">عرض البطولات</h4>
            <div className="search">
              <div className="search-container">
                <SearchIcon className="icon" />
                <input
                  type="text"
                  placeholder="ابحث عن ..."
                  onChange={handleSearch}
                />
              </div>

              <div className="filter-container" onClick={handleFilterClickd}>
                <img src={FilterIcon} alt="" />
              </div>
            </div>

            <Button
              size={isMobile ? "full" : "medium"}
              variant="fill"
              text="اضافة بطولة"
              icon={<AddIcon />}
              path="/championships/create"
            />
          </div>
          {loading ? (
            <Loader />
          ) : (
            <div className="data-grid">
              <DataGrid
                sx={{ minHeight: 600 }}
                columns={ChampionshipsColumns.concat(actionColumn)}
                rows={champions.map((row, index) => {
                  return { ...row, index: index + 1 };
                })}
                disableDensitySelector
                disableRowSelectionOnClick
                disableColumnMenu
                disableVirtualization
                slots={{
                  toolbar: CustomToolbar,
                }}
                filterModel={filterModel}
                onFilterModelChange={(newModel) => setFilterModel(newModel)}
                initialState={{
                  ...champions.initialState,
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50]}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelectedRowsIds(newRowSelectionModel);
                }}
                rowSelectionModel={selectedRowsIds}
              />
            </div>
          )}
        </div>
      </div>

      {/* Delete Message Modal */}
      <Modal
        open={openMessage}
        onClose={() => {
          setOpenMessage(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div
            className="header"
            onClick={() => {
              setOpenMessage(false);
              setRowId("");
            }}
          >
            <img src={CloseIcon} alt="" />
          </div>
          <div className="body">
            <img src={AttentionIcon} alt="" />
            <h2>انتبة</h2>

            <h5>هل انت متاكد من حذف هذه البطولة من</h5>

            <h6>البطولات</h6>
          </div>
          <div className="footer">
            <button className="action-btn" onClick={handleDelete}>
              حذف
            </button>
          </div>
        </div>
      </Modal>

      {/*Notification Message Modal */}
      <Modal
        open={openNotification}
        onClose={() => {
          setOpenNotification(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="message-modal"
      >
        <div className="container">
          <div className="header">
            <div className="wrapper">
              <img src={NotificationIcon} alt="" />
              <p>اشعار كل المشتركين في البطولة</p>
            </div>
            <img
              src={CloseIcon}
              alt=""
              onClick={() => {
                setOpenNotification(false);
                setRowId("");
              }}
            />
          </div>
          <div className="body">
            <textarea
              name=""
              id=""
              placeholder="ادخل محتوي الاشعار"
              rows={5}
              onChange={(e) => setNotifictionContent(e.target.value)}
            ></textarea>
          </div>
          <div className="footer">
            <button className="action-btn" onClick={handleSendNotifiction}>
              ارسال
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default Championships;
