import "./ViewContactMessage.scss";
import { useLocation } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import { TextField } from "@mui/material";
import { useState } from "react";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../../Components/Loader/Loader";

const ViewContactMessage = () => {
  const location = useLocation();
  const studentMessage = location.state;
  const [loading, setLoading] = useState(false);
  const [replay, setReplay] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await newRequest.put(`/contact_us/${studentMessage?.id}`, {
        reply: replay,
      });
      message.success("تم الرد بنجاح");
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setReplay("");
      setLoading(false);
    }
  };

  return (
    <div className="create">
      <div className="container">
        <h4 className="title">عرض الرسالة</h4>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              <div className="form-control">
                <label htmlFor="name">محتوي الرسالة</label>
                <textarea
                  name=""
                  id=""
                  value={studentMessage?.message}
                  disabled
                />
              </div>
            </div>
            <div className="control-group">
              <div className="form-control">
                <label className="form-label" htmlFor="name">
                  اسم المستخدم
                </label>
                <TextField
                  fullWidth
                  id="fullWidth"
                  className="form-input"
                  value={studentMessage?.full_name}
                />
              </div>

              <div className="form-control">
                <label className="form-label" htmlFor="name">
                  نوع المستخدم
                </label>
                <TextField
                  fullWidth
                  id="fullWidth"
                  className="form-input"
                  value={studentMessage?.type}
                />
              </div>
            </div>

            <div className="control-group">
              <div className="form-control">
                <label className="form-label" htmlFor="name">
                  رقم الموبايل
                </label>
                <TextField
                  fullWidth
                  id="fullWidth"
                  className="form-input"
                  value={studentMessage?.phone}
                />
              </div>

              <div className="form-control">
                <label className="form-label" htmlFor="name">
                  البريد الالكتروني
                </label>
                <TextField
                  fullWidth
                  id="fullWidth"
                  className="form-input"
                  value={studentMessage?.email}
                />
              </div>
            </div>

            <div className="control-group">
              <div className="form-control">
                <label htmlFor="name">الرد علي الرسالة</label>
                <textarea
                  placeholder="ألرد"
                  name=""
                  id=""
                  onChange={(e) => setReplay(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="form-actions">
              <Button text="رد" size="large" variant="fill" />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ViewContactMessage;
