import "./DownloadLink.scss";
import { FormControl, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import Button from "../../../Components/Button/Button";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../../Components/Loader/Loader";

const DownloadLink = () => {
  const [links, setLinks] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getLinks();
  }, []);

  const getLinks = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get("/settings");
      setLinks(res?.data?.data);
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await newRequest.post("/settings", { ...links });
      getLinks();
    } catch (error) {
      showErrorMessage(error);
    } finally {
      setLoading(false);
      message.success("تم التعديل بنجاح");
    }
  };

  const handleChange = (e) => {
    setLinks((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  return (
    <div className="create-profile-page">
      <div className="container">
        <div className="header">
          <h3 className="title">تعديل لينك تحميل التطبيق</h3>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">
                  لينك تحميل التطبيق (ايفون )
                </label>
                <TextField
                  fullWidth
                  className="form-input"
                  value={links?.app_store}
                  name="app_store"
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">
                  لينك تحميل التطبيق (اندرويد )
                </label>
                <TextField
                  fullWidth
                  className="form-input"
                  value={links?.play_store}
                  name="play_store"
                  onChange={handleChange}
                />
              </FormControl>
            </div>

            <div className="action-control">
              <Button text={"حفظ"} variant={"fill"} size={"small"} />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default DownloadLink;
