import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import "./EditQuestion.scss";
import Button from "../../../Components/Button/Button";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import newRequest from "../../../Utils/newRequest";
import { showErrorMessage } from "../../../Utils/showErrorMessage";
import { message } from "antd";
import Loader from "../../../Components/Loader/Loader";

const EditQuestion = () => {
  const location = useLocation();
  const question = location.state;
  const [loading, setLoading] = useState(false);
  const [editQuestion, setEditQuestion] = useState({
    training_type_id: question.training_type.id,
    type_level_id: question.type_level.id,
    level_category_id: question?.level_category?.id,
    numbers_count: question.numbers_count,
    answer: question.answer,
    choices: question.choices,
    numbers: [
      ...question.numbers,
      ...Array(10 - question.numbers.length).fill(""),
    ],
    active: question.active,
  });
  const [numbers, setNumbers] = useState(Array(10).fill(""));
  const [mainCategories, setMainCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  //  القسم الرئيسي/المستويات
  useEffect(() => {
    const getMainCategories = async () => {
      try {
        const mainCategoriesResponce = await newRequest.get(
          `type_levels?training_type_id=${editQuestion?.training_type_id}`
        );
        setMainCategories(mainCategoriesResponce?.data?.data);
      } catch (error) {
        showErrorMessage(error);
      }
    };
    getMainCategories();
  }, [editQuestion?.training_type_id]);

  // الاقسام الفرعية
  useEffect(() => {
    const getSubCategories = async () => {
      try {
        const subCategoriesResponce = await newRequest.get(
          `/level_categories?type_level_id=${editQuestion?.type_level_id}`
        );
        setSubCategories(subCategoriesResponce?.data?.data);
      } catch (error) {
        showErrorMessage(error);
      }
    };
    getSubCategories();
  }, [editQuestion?.type_level_id]);

  const handleNumberChange = (index, newValue) => {
    const newNumbers = [...editQuestion.numbers];
    newNumbers[index] = newValue;
    setEditQuestion({ ...editQuestion, numbers: newNumbers });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const filteredArray = editQuestion.numbers.filter((value) => value !== "");
    try {
      await newRequest.put(`/questions/${question.id}`, {
        ...editQuestion,
        numbers: filteredArray,
      });
      setLoading(false);
      message.success("تم التعديل بنجاح");
    } catch (error) {
      setLoading(false);
      showErrorMessage(error);
    }
  };

  const handleChoiceChange = (index, newValue) => {
    const newChoices = [...editQuestion.choices];
    newChoices[index] = newValue;
    setEditQuestion({ ...editQuestion, choices: newChoices });
  };

  return (
    <div className="add-question">
      <div className="container">
        <h4 className="title">تعديل السؤال</h4>
        {!question || loading ? (
          <Loader />
        ) : (
          <form action="" onSubmit={handleSubmit}>
            <div className="control-group">
              {numbers.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  className="add-input"
                  value={editQuestion?.numbers[index] || ""}
                  onChange={(e) => handleNumberChange(index, e.target.value)}
                />
              ))}
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="aria-label">نوع السؤال</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className="select"
                  name="training_type"
                  value={editQuestion?.training_type_id}
                  onChange={(e) =>
                    setEditQuestion({
                      ...editQuestion,
                      training_type_id: e.target.value,
                    })
                  }
                >
                  <MenuItem value="1">عام</MenuItem>
                  <MenuItem value="2">تفصيلي</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="aria-label">القسم الرئيسي</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={editQuestion?.type_level_id}
                  className="select"
                  onChange={(e) =>
                    setEditQuestion({
                      ...editQuestion,
                      type_level_id: e.target.value,
                    })
                  }
                >
                  {mainCategories.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label htmlFor="aria-label">القسم الفرعي</label>
                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={editQuestion?.level_category_id}
                  onChange={(e) =>
                    setEditQuestion({
                      ...editQuestion,
                      level_category_id: e.target.value,
                    })
                  }
                >
                  {subCategories.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">عدد الارقام في السؤال</label>
                <TextField
                  fullWidth
                  id="fullWidth"
                  className="form-input"
                  pattern="[0-9]*"
                  value={editQuestion?.numbers_count}
                  onChange={(e) =>
                    setEditQuestion({
                      ...editQuestion,
                      numbers_count: e.target.value,
                    })
                  }
                />
              </FormControl> */}
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الاجابة الصحيحة</label>
                <TextField
                  fullWidth
                  className="form-input"
                  value={editQuestion?.answer}
                  onChange={(e) =>
                    setEditQuestion({
                      ...editQuestion,
                      answer: e.target.value,
                    })
                  }
                />
              </FormControl>
            </div>

            <div className="control-group">
              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الاختيار الاول</label>
                <TextField
                  fullWidth
                  className="form-input"
                  value={editQuestion.choices[0]}
                  onChange={(e) => handleChoiceChange(0, e.target.value)}
                />
              </FormControl>

              <FormControl sx={{ m: 1, flex: 1 }} className="select">
                <label className="form-label">الاختيار الثاني</label>
                <TextField
                  fullWidth
                  className="form-input"
                  value={editQuestion.choices[1]}
                  onChange={(e) => handleChoiceChange(1, e.target.value)}
                />
              </FormControl>
            </div>

            <div className="action-control">
              <Button text={"تعديل"} variant={"fill"} size={"small"} />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditQuestion;
